import React, { useState, useRef, useEffect } from 'react';
import * as moment from 'moment';
import {
  Breadcrumb,
  Table,
  Button,
  Modal,
  Form,
  Input,
  Avatar,
  Spin,
  Select,
} from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
  UserOutlined,
} from '@ant-design/icons';

import {
  TEACHERS,
  CREATE_TEACHER,
  UPDATE_TEACHER,
  REMOVE_TEACHER,
} from '../graphql/teacher';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_RESOURCE } from '../graphql/resourceMutations';
import { LIST_AGENCY } from '../graphql/agency';

const Teacher = () => {
  const [isShowModalAddNew, setIsShowModalAddNew] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [initForm, setInitForm] = useState({});
  const [form] = Form.useForm();
  const [fileAvatar, setFileAvatar] = useState();
  const avatarRef = useRef();
  const [dataSource, setDataSource] = useState([]);
  const {
    loading,
    error,
    data,
    refetch: refetchTeachers,
  } = useQuery(TEACHERS, {
    variables: {
      take: 10000,
    },
  });
  const { data: agencies } = useQuery(LIST_AGENCY, {
    variables: { filter: {} },
  });

  const [createResource] = useMutation(CREATE_RESOURCE);
  const [createTeacher] = useMutation(CREATE_TEACHER, {
    onCompleted: (response) => {
      refetchTeachers();
      if (response && response.createTeacher) {
        setPageLoading(false);
        Modal.success({ title: 'Create success! ' });
        setInitForm({});
      }
    },
    onError: (_createError) => {
      setPageLoading(false);
      Modal.error({ message: 'Create teacher fail' });
    },
  });

  const [updateTeacher] = useMutation(UPDATE_TEACHER, {
    onCompleted: (response) => {
      refetchTeachers();
      if (response) {
        setPageLoading(false);
        Modal.success({ title: 'Update teacher success! ' });
        setInitForm({});
      }
    },
    onError: () => {
      setPageLoading(false);
      Modal.error({ message: 'Update teacher fail' });
    },
  });

  const [removeTeacher] = useMutation(REMOVE_TEACHER, {
    onCompleted: (response) => {
      refetchTeachers();
      if (response) {
        setPageLoading(false);
        Modal.success({ title: 'Remove teacher success! ' });
        setInitForm({});
      }
    },
    onError: () => {
      setPageLoading(false);
      Modal.error({ message: 'Remove teacher fail' });
    },
  });
  useEffect(() => {
    if (data?.teachers) {
      setDataSource(data?.teachers);
    }
  }, [data]);
  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      fullname: initForm.fullname,
      email: initForm.email,
      phone: initForm.phone,
      agencyIds: initForm.agencies?.map((agency) => agency.agencyId),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initForm]);

  if (error) {
    Modal.error({ message: error.message });
  }

  const handleAddNew = () => {
    setInitForm({});
    setIsShowModalAddNew(true);
  };

  const closeModal = () => {
    setIsShowModalAddNew(false);
  };

  const handleSaveUser = () => {
    setIsShowModalAddNew(false);
  };

  const hanleDeleteUser = (userId) => {
    Modal.confirm({
      title: 'Delete teacher?',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure delete this teacher?',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: () => {
        removeTeacher({
          variables: {
            userId,
          },
        });
      },
    });
  };

  const handleUpdateUser = (user) => {
    setInitForm(user);
    setFileAvatar('');
    setIsShowModalAddNew(true);
  };

  const columns = [
    {
      title: 'No',
      key: 'index',
      render: (_value, _item, index) => index + 1,
    },
    {
      title: 'Full name',
      dataIndex: 'fullname',
      key: 'fullname',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at) => moment(created_at).format('DD/MM/YYYY hh:mm:ss'),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_value, item) => {
        return (
          <>
            <EditOutlined
              onClick={() => handleUpdateUser(item)}
              className="mr10"
            />
            <DeleteOutlined onClick={() => hanleDeleteUser(item.userId)} />
          </>
        );
      },
    },
  ];

  const onFinish = async (values) => {
    // Upload file
    let userAvatarUrl = initForm.avatar;
    if (fileAvatar) {
      console.log('fileAvatar ', fileAvatar);

      const createResourceDt = await createResource({
        variables: {
          file: fileAvatar,
        },
      });
      userAvatarUrl = createResourceDt?.data?.createResource?.url;
    }
    userAvatarUrl = userAvatarUrl ?? '';
    setPageLoading(true);
    setIsShowModalAddNew(false);
    if (initForm.userId) {
      updateTeacher({
        variables: {
          updateTeacherInput: {
            ...values,
            userId: initForm.userId,
            avatar: userAvatarUrl,
          },
        },
      });
    } else {
      createTeacher({
        variables: {
          createTeacherInput: {
            ...values,
            avatar: userAvatarUrl,
          },
        },
      });
    }
  };

  const onFinishFailed = () => {
    return;
  };
  const onSearch = (value) => {
    const searchData = data?.teachers?.filter((o) => {
      return (
        o?.fullname?.toLowerCase().includes(value.toLowerCase()) ||
        o?.email?.toLowerCase().includes(value.toLowerCase())
      );
    });

    setDataSource(searchData);
  };
  const changeAvatar = () => {
    avatarRef.current.click();
  };
  const handleChangeImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      setFileAvatar(event.target.files[0]);
    }
  };

  const renderAvatar = () => {
    if (fileAvatar) return URL.createObjectURL(fileAvatar);
    if (initForm.avatar) return initForm.avatar;
    return '';
  };

  return (
    <Spin tip="Loading..." spinning={pageLoading || loading}>
      <div className="post">
        <div className="breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>Teacher</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="body">
          <div className="add-new">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={handleAddNew}
            >
              Add New
            </Button>
          </div>
          <div className="add-new">
            <Input.Search
              placeholder="Search text"
              onSearch={onSearch}
              style={{ width: 200 }}
            />
          </div>
          <div className="table">
            <Table dataSource={dataSource} columns={columns} />
          </div>
        </div>
        <Modal
          forceRender
          title={initForm.userId ? 'Update' : 'Add new'}
          visible={isShowModalAddNew}
          onOk={handleSaveUser}
          onCancel={closeModal}
          bodyStyle={{
            height: 500,
            overflow: 'auto',
          }}
          footer={null}
        >
          <div className="avatar_form">
            <div className="avatar_form__item">
              <Avatar src={renderAvatar()} icon={<UserOutlined />} size={100} />
              <div className="avatar_form__button">
                <Button onClick={changeAvatar}>Change Avatar</Button>
                <input
                  type="file"
                  ref={avatarRef}
                  id="cover"
                  name="cover"
                  accept="image/png, image/jpeg"
                  style={{ display: 'none' }}
                  onChange={handleChangeImage}
                ></input>
              </div>
            </div>
          </div>
          <Form
            name="basic"
            form={form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 24 }}
            initialValues={initForm}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            autoComplete="off"
          >
            <Form.Item
              label="Full name"
              name="fullname"
              rules={[{ required: true, message: 'Please input full name!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: 'Please input email!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Phone"
              name="phone"
              rules={[{ required: true, message: 'Please input phone!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Agencies"
              name="agencyIds"
              rules={[{ required: true, message: 'Please input agencies!' }]}
            >
              <Select mode="multiple">
                {agencies?.agencies.map((o) => (
                  <Select.Option value={o.agencyId}>{o.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 20, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </Spin>
  );
};

Teacher.propTypes = {};

export default Teacher;

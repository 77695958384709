import { Button, Divider, Form, Input, InputNumber, Modal, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import React from 'react';

export const UpdateDailyReportStudentModal = ({
  updateDailyReport,
  isShowAddDailyReport,
  form,
  setIsShowAddDailyReport,
  onFinishAddDailyReport,
  classes,
}) => {
  console.log('updateDailyReport', updateDailyReport);
  return (
    <>
      <Modal
        key={Date.now()}
        title={updateDailyReport ? 'Update' : 'Add new'}
        visible={isShowAddDailyReport}
        width={800}
        onOk={() => {
          form.submit();
        }}
        onCancel={() => {
          setIsShowAddDailyReport(false);
        }}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          layout={'vertical'}
          onFinish={onFinishAddDailyReport}
          autoComplete="off"
        >
          <Form.Item
            name="classId"
            label="Class:"
            rules={[{ required: true, message: 'Missing Lesson class' }]}
          >
            <Select>
              {classes?.map((classInfo) => (
                <Select.Option
                  value={classInfo.classId}
                  key={classInfo.classId}
                >
                  {classInfo.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="lessonContent"
            label="Lesson content:"
            rules={[{ required: true, message: 'Missing Lesson content' }]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.List name="reports">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div key={key} style={{ marginBottom: 8 }} align="center">
                    <Divider />
                    <Form.Item
                      {...restField}
                      name={[name, 'subject']}
                      rules={[{ required: true, message: 'Missing Subject' }]}
                    >
                      <Input.TextArea placeholder="Subject" />
                    </Form.Item>
                    <Form.Item {...restField} name={[name, 'description']}>
                      <Input.TextArea placeholder="Description" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'score']}
                      rules={[{ required: true, message: 'Missing Rating' }]}
                    >
                      <InputNumber
                        min={0}
                        max={4}
                        placeholder="Rating"
                        className="w-full"
                      />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </div>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add field
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </>
  );
};

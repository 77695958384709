import { useContext } from 'react';
import {
  Navigate,
} from 'react-router-dom';
import { UserRoleConstant } from '../constants';
import { AuthContext } from '../contexts/auth';
import Layout from './LayoutPage';

const ProtectedAgencyAdminRoute = ({ children }) => {
  const auth = useContext(AuthContext);

  const { token, user } = auth;
  if (!token) {
    return <Navigate to="/login" replace />;
  }

  if (user?.role !== UserRoleConstant.ADMIN && user?.role !== UserRoleConstant.AGENCY_ADMIN) {
    return <Navigate to="/Forbidden" replace />;
  }

  return <Layout>
    {children}
  </Layout>
};

export default ProtectedAgencyAdminRoute;
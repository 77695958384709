import { gql } from "@apollo/client";

export const DAILY_REPORT_STUDENT_REPORT = gql`
	query dailyReport_getStudentReport($filters: FilterReportStudentInput) {
	    studentReports: dailyReport_getStudentReport(filters: $filters) {
            data {
                id
                className
                studentId
                studentName
                status
            }
            total
		}
	}
`;

export const COMMENT_OF_TEACHER_STUDENT_REPORT = gql`
	query commentOfTeacher_getStudentReport($filters: FilterReportStudentInput) {
	    studentReports: commentOfTeacher_getStudentReport(filters: $filters) {
            data {
                id
                className
                studentId
                studentName
                status
            }
            total
		}
	}
`;
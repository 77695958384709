import gql from 'graphql-tag';

export const childExperiences_findAll = gql`
  query childExperiences_findAll {
    childExperiences_findAll {
      childExperienceId
      classId
      description
      resourceUrls
    }
  }
`;

export const GET_EXPERIENCES = gql`
  query childExperiences_findByClassId($classId: String!) {
    experiences: childExperiences_findByClassId(classId: $classId) {
      childExperienceId
      description
      resourceUrls
      status
      created_at
      createdBy {
        fullname
      }
    }
  }
`;

export const childExperiences_createExperiences = gql`
  mutation childExperiences_createExperiences(
    $createExperiencesInput: CreateExperiencesInput!
  ) {
    experience: childExperiences_createExperiences(
      createExperiencesInput: $createExperiencesInput
    ) {
      childExperienceId
      classId
      description
      resourceUrls
    }
  }
`;

export const REMOVE_EXPERIENCE = gql`
  mutation childExperiences_remove($childExperienceId: String!) {
    experience: childExperiences_remove(childExperienceId: $childExperienceId) {
      childExperienceId
      description
      resourceUrls
      status
      created_at
    }
  }
`;

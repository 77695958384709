import gql from 'graphql-tag';

export const CREATE_RESOURCE = gql`
  mutation createResource($file: Upload!) {
    createResource: resources_createResource(file: $file) {
      fileName
      url
      mimetype
      encoding
    }
  }
`;

export const CREATE_RESOURCE_EXPERIENCE = gql`
  mutation uploadChildExperiences($file: Upload!) {
    experience: resources_uploadChildExperiences(file: $file) {
      resourceId
      fileName
      url
      mimetype
      encoding
    }
  }
`;

import { Paging, PagingType } from '../constants/paging';

export const StorageHelper = {
  getPaging: (type = PagingType.class) => {
    const take = localStorage.getItem(`${type}-${Paging.take}`);
    const skip = localStorage.getItem(`${type}-${Paging.skip}`);
    const search = localStorage.getItem(`${type}-${Paging.search}`);
    return {
      take: take && !isNaN(Number(take)) ? Number(take) : null,
      skip: skip && !isNaN(Number(skip)) ? Number(skip) : null,
      search: search && search !== 'null' ? search : '',
    };
  },
  setPaging: (type = PagingType.class, { take, skip, search }) => {
    localStorage.setItem(`${type}-${Paging.take}`, take);
    localStorage.setItem(`${type}-${Paging.skip}`, skip);
    localStorage.setItem(`${type}-${Paging.search}`, search ?? '');
  },
  removePaging: (type = PagingType.class) => {
    localStorage.removeItem(`${type}-${Paging.take}`);
    localStorage.removeItem(`${type}-${Paging.skip}`);
    localStorage.removeItem(`${type}-${Paging.search}`);
  },
  setRecentSearch: (type = PagingType.class, value) => {
    localStorage.setItem(`recent-${type}`, value);
  },
  getRecentSearch: (type = PagingType.class) => {
    const search = localStorage.getItem(`recent-${type}`);
    return search && search !== 'null' ? search : '';
  },
};

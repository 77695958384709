export const META = {
  achau: {
    title: 'Admin || Á Châu English',
    description:
      'Hệ thống Anh Ngữ Á Châu – một thương hiệu đào tạo anh ngữ đặt chữ Tâm làm trọng, tất cả tạo dựng vì giá trị mang đến cho cộng đồng. Được thành lập từ năm 2005 với hơn 15 năm phát triển, Hệ thống Anh ngữ Á Châu đã gặt hái được nhiều thành công, ngày càng tạo dựng được sự tín nhiệm của quý phụ huynh và học viên vì chất lượng đào tạo vượt trội và dịch vụ chăm sóc tuyệt vời, cũng như ngày càng khẳng định được vị thế của mình trong lĩnh vực đào tạo Anh ngữ',
    url: 'https://admin.achauenglish.edu.vn/',
    image: 'http://achauenglish.edu.vn/upload/hinhanh/logo-moi-nhat-8133.jpg',
    domain: 'admin.achauenglish.edu.vn',
  },
};

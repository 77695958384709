import { useMutation, useQuery } from '@apollo/client';

import * as moment from 'moment';
import { Button, Carousel, Image, Modal, Spin, Table } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import React, { useState } from 'react';
import { ModalExperienceComponent } from './ModalExperienceComponent';
import {
  GET_EXPERIENCES,
  REMOVE_EXPERIENCE,
} from '../../../../graphql/experience';
import { useSearchParams } from 'react-router-dom';
import BreadCrumbComponent from '../../../../components/breadcrumb/BreadCrumbComponent';

const ClassDetailExperiencePage = () => {
  const [showModalExperience, setIsShowModalExperience] = useState(false); // TODO: Set false
  const [selectedExperience, setSelectedExperience] = useState({});
  const [isShowImage, setIsShowImage] = useState(false);
  const [searchParams] = useSearchParams();
  const classId = searchParams.get('classId');
  const { data, refetch, loading } = useQuery(GET_EXPERIENCES, {
    variables: {
      classId,
    },
  });

  const [removeExperience] = useMutation(REMOVE_EXPERIENCE, {
    onCompleted: (response) => {
      if (response) {
        Modal.success({ title: 'Delete Success' });
      }
    },
    onError: (_createError) => {
      Modal.error({ message: 'Delete Fail' });
    },
  });

  const defaultRoutes = [
    {
      id: -1,
      path: '/',
      breadcrumbName: 'Home',
    },
    {
      id: 0,
      path: '/class',
      breadcrumbName: 'Class',
    },

    {
      id: 0,
      path: '/class-experience',
      breadcrumbName: 'Experience',
    },
  ];
  let routes = defaultRoutes;
  const route = {
    id: searchParams.get('classId'),
    path: `/class-experience-detail?classId=${searchParams.get(
      'classId',
    )}&name=${searchParams.get('name')}`,
    breadcrumbName: searchParams.get('name'),
  };
  const experiences = data?.experiences?.map((item) => ({
    ...item,
    key: item.childExperienceId,
  }));

  const handleAddExperience = () => {
    console.log('handleAddExperience');
    setIsShowModalExperience(true);
  };

  const handleUpdateExperience = (item) => {
    setSelectedExperience(item);
    setIsShowModalExperience(true);
  };

  const handleViewExperience = (item) => {
    console.log('item ', item);
    setSelectedExperience(item);
    setIsShowImage(true);
  };

  const handleCloseModel = () => {
    refetch().then(() => {
      setSelectedExperience({});
      setIsShowModalExperience(false);
    });
  };

  const handleDeleteExperience = (childExperienceId) => {
    Modal.confirm({
      title: 'Delete comment?',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure delete this item?',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: () => {
        removeExperience({
          variables: {
            childExperienceId,
          },
        }).then(() => {
          refetch();
        });
      },
    });
  };

  const columns = [
    {
      title: 'No',
      key: 'index',
      render: (_value, _item, index) => index + 1,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at) => moment(created_at).format('DD/MM/YYYY hh:mm:ss'),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_value, item) => {
        return (
          <>
            <EyeOutlined
              onClick={() => handleViewExperience(item)}
              className="mr10"
            />
            <EditOutlined
              onClick={() => handleUpdateExperience(item)}
              className="mr10"
            />
            <DeleteOutlined
              onClick={() => handleDeleteExperience(item.childExperienceId)}
            />
          </>
        );
      },
    },
  ];
  return (
    <Spin tip="Loading..." spinning={false || loading}>
      <div className="post">
        <div className="breadcrumb">
          <BreadCrumbComponent
            routes={routes}
            defaultRoutes={defaultRoutes}
            route={route}
          />
        </div>
        <div className="body">
          <div className="add-new">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={handleAddExperience}
            >
              Add Experience
            </Button>
          </div>
          <Table dataSource={experiences || []} columns={columns} />
        </div>
        <ModalExperienceComponent
          handleCloseModel={handleCloseModel}
          classId={classId}
          selectedExperience={selectedExperience}
          showModalExperience={showModalExperience}
        />
        <Modal
          visible={isShowImage}
          footer={null}
          onCancel={() => {
            setIsShowImage(false);
            setSelectedExperience({});
          }}
        >
          <Carousel autoplay={true}>
            {selectedExperience?.resourceUrls?.map((url) => {
              return (
                <div className="flex justify-center w-full h-100">
                  <div className="flex justify-center items-center">
                    {url.toLowerCase().includes('.jpg') ||
                    url.toLowerCase().includes('png') ? (
                      <div className="flex justify-center items-center">
                        <Image preview={false} width={400} src={url} />
                      </div>
                    ) : (
                      <video controls key={url}>
                        <source
                          src={url + `?t=${new Date()}`}
                          type="video/mp4"
                        />
                      </video>
                    )}
                  </div>
                </div>
              );
            })}
          </Carousel>
        </Modal>
      </div>
    </Spin>
  );
};
export default ClassDetailExperiencePage;

import React, { useState } from 'react';

import { Button, Form, Input, message, Modal, Select, Upload } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useMutation } from '@apollo/client';
import { UploadOutlined } from '@ant-design/icons';
import { LIST_MATERIALS, UPLOAD_MATERIALS } from '../../../graphql/class';

const AddMaterial = ({
  isShowAddMaterial,
  setIsShowAddMaterial,
  classId
}) => {
  const [form] = useForm();
  const [fileList, setFileList] = useState();
  const [file, setFile] = useState();
  const [createMaterial] = useMutation(UPLOAD_MATERIALS, {
    refetchQueries: [{ query: LIST_MATERIALS, variables: { classId } }],
    onCompleted: () => {
      message.success('Success');
      form.setFieldsValue({
        category: '',
        fileName: ''
      });
    }
  });

  const onFinishAddScores = (values) => {
    setIsShowAddMaterial(false);
    createMaterial({
      variables: {
        category: values.category,
        classId,
        file: file,
        fileName: values.fileName
      }
    });
  }

  const uploadFile = async (file) => {
    setFile(file);
    console.log('file ', file);
    setFileList([{
      uid: file.name,
      name: file.name,
      status: 'done',
      url: file.name,
    }]);
  }

  return <>
    <div>
      <Modal
        title="Add material / Edit material"
        visible={isShowAddMaterial}
        onOk={() => {
          form.submit();
        }}
        onCancel={() => {
          setIsShowAddMaterial(false);
        }}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 24 }}
          layout={'vertical'}
          onFinish={onFinishAddScores}
          autoComplete="off"
        >
          <Form.Item
            label="File name"
            name="fileName"
            rules={[{ required: true, message: 'Missing File name' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Category"
            name="category"
            rules={[{ required: true, message: 'Missing category' }]}
          >
            <Select >
              <Select.Option value='reading'>Reading</Select.Option>
              <Select.Option value='speaking'>Speaking</Select.Option>
              <Select.Option value='listening'>Listening</Select.Option>
              <Select.Option value='writing'>Writing</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="File"
            name="file"
            rules={[{ required: true, message: 'Missing category' }]}
          >
            <Upload
              fileList={fileList}
              action={uploadFile} 
            >
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  </>
};

AddMaterial.propTypes = {};

export default AddMaterial;

import { useState } from 'react';
import { Form, Modal, Input, Button, message } from 'antd';
import { useMutation } from '@apollo/client';
import { UPDATE_COMMENT_TEACHER } from '../../graphql/class';
import { LIST_COMMENT } from '../../graphql/student';

export const EditCommentForStudentModal = ({
  showModalEditComment,
  setShowModalEditComment,
  comment,
}) => {
  const [form] = Form.useForm();
  const [initForm, setInitForm] = useState(comment);
  const [updateCommentTeacher] = useMutation(UPDATE_COMMENT_TEACHER, {
    refetchQueries: [
      {
        query: LIST_COMMENT,
        variables: {
          studentId: comment.studentId,
        },
      },
    ],
    onCompleted: (response) => {
      message.success('Success');
      setShowModalEditComment(false);
      // refetch();
    },
  });
  const onFinish = (values) => {
    updateCommentTeacher({
      variables: {
        updateCommentOfTeacherInput: {
          commentId: comment.commentId,
          subject: values.subject,
          targetSubject: values.targetSubject,
          targetContent: values.targetContent,
          content: values.content,
        },
      },
    });
  };
  const onFinishFailed = (values) => {};

  return (
    <Modal
      forceRender
      title="Edit comment"
      visible={showModalEditComment}
      onOk={() => setShowModalEditComment(false)}
      onCancel={() => setShowModalEditComment(false)}
      bodyStyle={{
        height: 'auto',
        overflow: 'auto',
      }}
      footer={null}
    >
      <Form
        name="basic"
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 24 }}
        initialValues={initForm}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item label="Target Subject" name="targetSubject">
          <Input.TextArea />
        </Form.Item>
        <Form.Item label="Target Content" name="targetContent">
          <Input.TextArea />
        </Form.Item>
        <Form.Item label="Comment Subject" name="subject">
          <Input.TextArea />
        </Form.Item>
        <Form.Item label="Comment Content" name="content">
          <Input.TextArea />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 20, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Update
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

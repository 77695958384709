import React, { useState } from 'react';
import { Spin } from 'antd';

import ClassDetailComponent from '../ClassDetailComponent';
import BreadCrumbComponent from '../../../components/breadcrumb/BreadCrumbComponent';
import { useSearchParams } from 'react-router-dom';
import { StorageHelper } from '../../../utils/storage.helper';

const ClassDetailPage = () => {
  console.log('ClassDetailPage');
  // eslint-disable-next-line no-unused-vars
  const [pageLoading, setPageLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const defaultRoutes = [
    {
      id: -1,
      path: '/',
      breadcrumbName: 'Home',
    },
    {
      id: 0,
      path: '/class',
      breadcrumbName: 'Class',
    },
  ];
  const route = {
    id: searchParams.get('classId'),
    path: `/class-detail?classId=${searchParams.get(
      'classId',
    )}&name=${searchParams.get('name')}`,
    breadcrumbName: searchParams.get('name'),
  };
  // const [routes, setRoutes] = useState(defaultRoutes)
  let routes = defaultRoutes;

  const BodyClassComponent = () => {
    return <ClassDetailComponent classId={searchParams.get('classId')} />;
  };
  const BackComponent = () => {
    const { take, skip, search } = StorageHelper.getPaging();

    const handleBack = () => {
      if (take === null && skip === null && !search) {
        window.location.href = `/class`;
        return;
      }
      window.location.href = `/class?take=${take ?? 10}&skip=${
        skip ?? 0
      }&search=${search}`;
    };
    return (
      <div className="ant-btn ml-10" onClick={handleBack}>
        BACK
      </div>
    );
  };
  return (
    <Spin tip="Loading..." spinning={pageLoading}>
      <BackComponent />
      <div className="post">
        <div className="breadcrumb">
          <BreadCrumbComponent
            routes={routes}
            defaultRoutes={defaultRoutes}
            route={route}
          />
        </div>
        {}
        <div className="body">
          <BodyClassComponent />
        </div>
      </div>
    </Spin>
  );
};

ClassDetailPage.propTypes = {};

export default ClassDetailPage;

import gql from 'graphql-tag';


export const SMART_QUESTIONS = gql`
	query smartQuestions_findAll {
		smartQuestions: smartQuestions_findAll {
         smartQuestionId
         answerEn
         answerVi
         audioAnswerUrl
         audioContentUrl
         contentEn
         contentVi
         createdById
         created_at
         createdBy {
            userId,
            email
            fullname
         }
		}
	}
`;

export const ADD_SMART_QUESTION = gql`
	mutation createSmartQuestions($createSmartQuestionsInput: CreateSmartQuestionsInput!) {
		smartQuestion: createSmartQuestions(createSmartQuestionsInput: $createSmartQuestionsInput) {
         smartQuestionId
         answerEn
         answerVi
         audioAnswerUrl
         audioContentUrl
         contentEn
         contentVi
         createdById
         created_at
         createdBy {
            userId,
            email
            fullname
         }
		}
	}
`;


export const UPDATE_SMART_QUESTION = gql`
	mutation updateSmartQuestion($updateSmartQuestionsInput: UpdateSmartQuestionsInput!) {
		smartQuestion: updateSmartQuestion(updateSmartQuestionsInput: $updateSmartQuestionsInput) {
         smartQuestionId
         answerEn
         answerVi
         audioAnswerUrl
         audioContentUrl
         contentEn
         contentVi
         createdById
         created_at
         createdBy {
            userId,
            email
            fullname
         }
		}
	}
`;


export const REMOVE_SMART_QUESTION = gql`
	mutation removeSmartQuestions($smartQuestionsId: String!) {
		smartQuestion: removeSmartQuestions(smartQuestionsId: $smartQuestionsId) {
         smartQuestionId
      }
	}
`;
import React from 'react';


const page404 = () => {
  return <div>404</div>;
};

page404.propTypes = {};

export default page404;

import React, { useContext, useEffect, useState } from 'react';
import { Breadcrumb, Row, Col, Input, Button, Modal, Spin, Select } from 'antd';
import * as moment from 'moment';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';

import Editor from '../../components/editor';
import { AuthContext } from '../../contexts/auth';
import { CREATE_RESOURCE } from '../../graphql/resourceMutations';
import { NEWS, NEWS_BY_ID, UPDATE_NEW } from '../../graphql/news';

const UpdateNews = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const { id } = useParams();

  const [article, setArticle] = useState({});
  const [fileCover, setFileCover] = useState();
  const [content, setContent] = useState('');
  const [isPreview, setIsPreview] = useState(false);

  const { loading, data, error} = useQuery(NEWS_BY_ID, { variables: { newsId: id}});

  const [createResource] = useMutation(CREATE_RESOURCE);
  const [updateNews] = useMutation(UPDATE_NEW, {
    refetchQueries: [{query: NEWS, variables: { filter: {}}}, {query: NEWS_BY_ID, variables: { newsId: id}}],
    onCompleted: (response) => {
      if (response && response.updateNews) {
        Modal.success({ title: 'Update success! ' });
        navigate("/news", { replace: true });
      }
    },
    onError: (_createError) => {
      Modal.error({ message: 'Update fail!' });
    }
  });

  if (error) {
    Modal.error({ message: 'Get news fail' });
  }

  useEffect(() => {
    if (data) {
      setArticle({...data.newsById});
      setContent(data.newsById.description);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleSave = async () => {
    // Save image
    let coverUrl = article.image;
    if (fileCover) {
      const createResourceDt = await createResource({
        variables: {
          file: fileCover
        }
      });
      coverUrl = createResourceDt?.data?.createResource?.url;
    }
    updateNews({
      variables: {
        updateNewsInput: {
          title: article.title,
          shortDescription: article.shortDescription,
          image: coverUrl,
          description: content,
          category: article.category,
          newsId: id,
        }
      }
    });
  };

  const handleChangeImage = event => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      setFileCover(img);
    }
  };

  const renderImage = () => {
    if (fileCover) {
      return URL.createObjectURL(fileCover);
    }
    return article.image;
  }
  
  return <Spin tip="Loading..." spinning={loading}>
    <div className='post'>
      <div className='breadcrumb'>
        <Breadcrumb>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/news">News</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            Update
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      {
        !isPreview
          ?
          <div className='body'>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <span style={{ color: 'red' }}>*</span>Name:
                <Input value={article.title} onChange={(e) => { setArticle({...article, title: e.target.value }); }}/>
              </Col>
              <Col span={12}>
                <span style={{ color: 'red' }}>*</span>Category:
                <br></br>
                <Select defaultValue={article.category} value={article.category} style={{ width: '50%' }} onChange={(value) => { setArticle({...article, category: value }); }}>
                  <Select.Option value="dashboard">Dashboard</Select.Option>
                  <Select.Option value="learn">Learn</Select.Option>
                  <Select.Option value="news">News</Select.Option>
                </Select>
              </Col>
              <Col span={24}>
                <label htmlFor="cover"><span style={{ color: 'red' }}>*</span>Choose a cover picture (260 x 146):</label>
                <br />
                <input type="file"
                  id="cover" name="cover"
                  accept="image/png, image/jpeg"
                  onChange={handleChangeImage}></input>
              </Col>
              <Col span={24}>
                <img src={renderImage()} width={260} height={146} alt="ok"/>
              </Col>
              <Col span={24}>
                <label htmlFor="shortDescription">Sort description*:</label>
                <Input value={article.shortDescription} onChange={(e) => { setArticle({...article, shortDescription: e.target.value }); }}/>
              </Col>
              <Col span={24}>
                <label>Description:</label>
                <Editor
                  value={article.description || ''}
                  onChange={(newContent) => { setContent(newContent); }}
                />
              </Col>
            </Row>
            <div className='action_button'>
              <Button type="primary" onClick={() => { setIsPreview(true); }}>
                Preview
              </Button>
            </div>
          </div>
          :
          <div className='preview-container'>
            <div className='preview'>
              <img src={renderImage()} width={600} height={400} alt="ok"/>
              <div className='content'>
                <h3>{article.title || 'Lorem ipsum dolor sit amet'}</h3>
                <h4>{`Written by ${user.fullname}`} | {moment().format('DD/MM/YYYY')}</h4>
                <h5>{article.shortDescription}</h5>
                <div dangerouslySetInnerHTML={{ __html: content }} />
              </div>
            </div>
            <div className='action_button'>
              <Button className='button_dds' type="primary" onClick={() => { setIsPreview(false);} }>
                Back
              </Button>
              <Button className='button_dds' type="primary" onClick={() => handleSave() }>
                Publish
              </Button>
            </div>
          </div>
      }
    </div>
  </Spin>
};

UpdateNews.propTypes = {};

export default UpdateNews;

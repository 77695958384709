import React, { useState, useContext, useMemo, useRef } from 'react';
import {
  Table,
  Button,
  Form,
  Input,
  message,
  Modal,
  Select,
  DatePicker,
  Space,
  Tooltip,
} from 'antd';
import {
  PlusOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { AuthContext } from '../contexts/auth';
import { ADD_COMMENT_TEACHER } from '../graphql/class';
import { useMutation, useQuery } from '@apollo/client';
import {
  LIST_COMMENT,
  REMOVE_COMMENT,
  UPDATE_SHOW_HIDE_COMMENT,
} from '../graphql/student';
import * as moment from 'moment';
import { UserRoleConstant } from '../constants';
import { EditCommentForStudentModal } from './modals/EditCommentForStudentModal';
import { cloneDeep } from '@apollo/client/utilities';

const Comment = ({ classes, studentId }) => {
  const auth = useContext(AuthContext);
  const [form] = Form.useForm();
  // eslint-disable-next-line no-unused-vars
  const [initForm, setInitForm] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [showModalAddComment, setShowModalAddComment] = useState(false);
  const [showModalEditComment, setShowModalEditComment] = useState(false);
  const [comment, setComment] = useState({});

  const { data } = useQuery(LIST_COMMENT, {
    variables: {
      studentId: studentId,
    },
  });
  const [addCommentTeacher] = useMutation(ADD_COMMENT_TEACHER, {
    refetchQueries: [
      {
        query: LIST_COMMENT,
        variables: {
          studentId: studentId,
        },
      },
    ],
    onCompleted: (response) => {
      message.success('Success');
      setShowModalAddComment(false);
      form.setFieldsValue({
        classId: '',
        subject: '',
        targetSubject: '',
        targetContent: '',
        content: '',
      });
    },
  });
  const [removeComment] = useMutation(REMOVE_COMMENT, {
    refetchQueries: [
      {
        query: LIST_COMMENT,
        variables: {
          studentId: studentId,
        },
      },
    ],
    onCompleted: (response) => {
      if (response && response.removeComment) {
        Modal.success({ title: 'Remove success! ' });
      }
    },
    onError: (_createError) => {
      Modal.error({ message: 'Remove fail' });
    },
  });

  const [updateShowHideComment] = useMutation(UPDATE_SHOW_HIDE_COMMENT, {
    refetchQueries: [
      {
        query: LIST_COMMENT,
        variables: {
          studentId: studentId,
        },
      },
    ],
    onCompleted: (response) => {
      if (response && response.removeComment) {
        Modal.success({ title: 'Update success! ' });
      }
    },
    onError: (_createError) => {
      Modal.error({ message: 'Update fail' });
    },
  });
  const [columnFiltered, setColumnFiltered] = useState({});
  //const [searchedColumn, setSearchedColumn] = useState('');
  // const searchInput = useRef(null);

  const handleUpdateShowHide = (item) => {
    updateShowHideComment({
      variables: {
        commentId: item.commentId,
      },
    });
  };

  const handleSearch = (selectedKeys, dataIndex, confirm) => {
    confirm();
    setColumnFiltered({
      ...columnFiltered,
      [dataIndex]: selectedKeys?.length > 0,
    });
  };

  const handleReset = (clearFilters) => {
    typeof clearFilters === 'function' && clearFilters();
  };

  const getDateColumnSearchProps = (dataIndex) => ({
    width: 150,
    filterDropdown: ({
      selectedKeys,
      setSelectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <DatePicker.RangePicker
          // ref={searchInput}
          //placeholder={`Search ${dataIndex}`}
          value={{
            0: selectedKeys[0] && selectedKeys[0].fromDate,
            1: selectedKeys[0] && selectedKeys[0].toDate,
          }}
          onChange={(e) =>
            setSelectedKeys([{ fromDate: e && e[0], toDate: e && e[1] }])
          }
          onPressEnter={() => handleSearch(selectedKeys, dataIndex, confirm)}
          allowClear={false}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space style={{ width: '100%', justifyContent: 'space-between' }}>
          <Button
            disabled={!columnFiltered[dataIndex]}
            type="text"
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
          >
            Reset
          </Button>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, dataIndex, confirm)}
            size="small"
          >
            OK
          </Button>
        </Space>
      </div>
    ),
    // filterIcon: (filtered) => (
    //   <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    // ),
    onFilter: (value, record) => {
      return (
        record[dataIndex] &&
        value &&
        value.fromDate &&
        moment(value.fromDate)
          .startOf('day')
          .isBefore(moment(record[dataIndex])) &&
        value &&
        value.toDate &&
        moment(value.toDate).endOf('day').isAfter(moment(record[dataIndex]))
      );
    },
    // onFilterDropdownOpenChange: (visible) => {
    //   if (visible) {
    //     setTimeout(() => searchInput.current?.select(), 100);
    //   }
    // },
  });
  const dataSource = (data?.list_comment ?? []).map((item) => ({
    ...item,
    key: item?.commentId,
  }));

  const columns = [
    {
      title: 'No',
      key: 'index',
      render: (_value, _item, index) => index + 1,
    },
    {
      title: 'Class name',
      dataIndex: 'class',
      filters: classes?.map((classInfo) => ({
        text: classInfo.name,
        value: classInfo.name,
      })),
      filterMode: 'tree',
      filterSearch: true,
      onFilter: (value, record) => record.class?.name?.startsWith(value),
      render: (_value, item) => {
        return item?.class?.name;
      },
    },
    {
      title: 'Target Subject',
      dataIndex: 'targetSubject',
      key: 'targetSubject',
      render: (value) => <RenderBody content={value} max={100} />,
    },
    {
      title: 'Target Content',
      dataIndex: 'targetContent',
      key: 'targetContent',
      render: (value) => <RenderBody content={value} max={100} />,
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
      render: (value) => <RenderBody content={value} max={100} />,
    },
    {
      title: 'Content',
      dataIndex: 'content',
      key: 'content',
      render: (value) => <RenderBody content={value} max={100} />,
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at) => moment(created_at).format('DD/MM/YYYY hh:mm:ss'),
      sorter: (a, b) =>
        moment(a.created_at).toDate().getTime() -
        moment(b.created_at).toDate().getTime(),
      ...getDateColumnSearchProps('created_at'),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_value, item) => {
        return (
          <>
            {item?.isShow ? (
              <Tooltip title="Hide comment?">
                <EyeOutlined
                  onClick={() => handleUpdateShowHide(item)}
                  className="mr10"
                />
              </Tooltip>
            ) : (
              <Tooltip title="Show comment?">
                <EyeInvisibleOutlined
                  onClick={() => handleUpdateShowHide(item)}
                  className="mr10"
                />
              </Tooltip>
            )}
            <EditOutlined onClick={() => handleEditComment(item)} />

            <DeleteOutlined
              onClick={() => hanleDeleteComment(item.commentId)}
            />
          </>
        );
      },
    },
  ];
  const handleAddComment = () => {
    form.setFieldsValue({
      classId: classes?.[0]?.classId,
    });
    setShowModalAddComment(true);
  };
  const handleEditComment = (item) => {
    setComment(cloneDeep(item));
    setShowModalEditComment(true);
  };
  const onFinish = (values) => {
    addCommentTeacher({
      variables: {
        createCommentOfTeacherInput: {
          classId: values.classId,
          studentId: studentId,
          subject: values.subject,
          targetSubject: values.targetSubject,
          targetContent: values.targetContent,
          content: values.content,
        },
      },
    });
  };
  const onFinishFailed = () => {};
  const hanleDeleteComment = (id) => {
    Modal.confirm({
      title: 'Delete comment?',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure delete this comment?',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: () => {
        removeComment({
          variables: {
            commentId: id,
          },
        });
      },
    });
  };
  return (
    <>
      <div style={{ textAlign: 'end' }}>
        {(auth?.user?.role === UserRoleConstant.ADMIN ||
          auth?.user?.role === UserRoleConstant.AGENCY_ADMIN ||
          auth?.user?.role === UserRoleConstant.TEACHER) && (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => handleAddComment()}
          >
            Add Comment
          </Button>
        )}
      </div>
      <div className="modal">
        <EditCommentForStudentModal
          key={Date.now()}
          comment={comment}
          setShowModalEditComment={setShowModalEditComment}
          showModalEditComment={showModalEditComment}
        />
      </div>
      <div className="table">
        <Table dataSource={dataSource} columns={columns} />
        <Modal
          forceRender
          title="Add comment / Update comment"
          visible={showModalAddComment}
          onOk={() => setShowModalAddComment(false)}
          onCancel={() => setShowModalAddComment(false)}
          bodyStyle={{
            height: 'auto',
            overflow: 'auto',
          }}
          footer={null}
        >
          <Form
            name="basic"
            form={form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 24 }}
            initialValues={initForm}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            autoComplete="off"
          >
            <Form.Item
              name="classId"
              label="Class:"
              rules={[{ required: true, message: 'Missing Lesson class' }]}
            >
              <Select>
                {classes?.map((classInfo) => (
                  <Select.Option
                    value={classInfo.classId}
                    key={classInfo.classId}
                  >
                    {classInfo.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Target Subject" name="targetSubject">
              <Input.TextArea />
            </Form.Item>
            <Form.Item label="Target Content" name="targetContent">
              <Input.TextArea />
            </Form.Item>
            <Form.Item label="Comment Subject" name="subject">
              <Input.TextArea />
            </Form.Item>
            <Form.Item label="Comment Content" name="content">
              <Input.TextArea />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 20, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Add
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </>
  );
};

const RenderBody = ({ content, max = 100 }) => {
  const [modal, contextHolder] = Modal.useModal();

  const contentRender = useMemo(() => {
    return content.slice(0, max);
  }, [content]);
  return (
    <div>
      <span>
        {contentRender}
        {content.length > max && (
          <Button
            type="link"
            onClick={() => {
              modal.info({
                title: null,
                width: '80vw',
                bodyStyle: {
                  overflowY: 'auto',
                  maxHeight: 'calc(100vh - 200px)',
                },
                maskClosable: true,
                content: <>{content}</>,
              });
            }}
            style={{ cursor: 'pointer' }}
          >
            See more
          </Button>
        )}
      </span>

      {contextHolder}
    </div>
  );
};
export default Comment;

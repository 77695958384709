import React, { useContext, useEffect, useState } from 'react';
import { Breadcrumb, Button, message, Modal, Space, Spin, Table } from "antd";
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, ImportOutlined, PlusOutlined, ReloadOutlined, } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { DELETE_STUDY_PROGRAMS, FIND_ALL_STUDY_PROGRAMS } from '../../graphql/study-program';
import ImportModal, { ImportType } from '../../components/import/ImportModal';
import { AuthContext } from '../../contexts/auth';
import { UserRoleConstant } from '../../constants';

const StudyProgram = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [isShowModalImport, setIsShowModalImport] = useState(false);
  const [loadStudyPrograms, { data, loading }] = useLazyQuery(FIND_ALL_STUDY_PROGRAMS, {
    fetchPolicy: 'no-cache',
  })
  const [deleleStudyProgram, { loading: loadingDelete }] = useMutation(DELETE_STUDY_PROGRAMS, {
    refetchQueries: [{ query: FIND_ALL_STUDY_PROGRAMS }],
    onCompleted: () => {
      message.success('Delete success');
      loadStudyPrograms();
    },
    onError: () => {
      message.error('Study is used');
    }
  });

  const columns = [
    {
      title: 'Index',
      render: (_, _ss, index) => <div>{index + 1}</div>
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Number lesson',
      render: (_, data, index) => {
        return data?.programs?.length;
      }
    },
    ...(user?.role === UserRoleConstant.ADMIN ? [{
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_value, item) => {
        return <>
          <EditOutlined onClick={() => handleUpdate(item)} className='mr10' />
          <DeleteOutlined onClick={() => handleDelete(item)} />
        </>;
      },
    }] : [])
  ];

  const handleUpdate = (item) => {
    navigate(`/study-program/edit/${item.studyProgramId}`);
  }

  const handleDelete = (item) => {
    console.log('handleDelete ', item)
    Modal.confirm({
      title: `Delete program`,
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure delete this program ${item?.name}?`,
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: () => {
        deleleStudyProgram({
          variables: {
            id: item.studyProgramId
          }
        });
      }
    });
  };

  useEffect(() => {
    loadStudyPrograms();
  }, []);

  return <Spin tip="Loading..." spinning={loading || loadingDelete}>
    <div className='post'>
      <div className='breadcrumb'>
        <Breadcrumb>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>Study programs</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className='body'>
        <Space className='add-new'>
          {
            user?.role === UserRoleConstant.ADMIN &&
            <>
              <Button type="primary" icon={<PlusOutlined style={{ verticalAlign: 'text-top' }} />} onClick={() => { navigate('/study-program/create') }} >Add New</Button>
              <Button type="primary" icon={<ImportOutlined style={{ verticalAlign: 'text-top' }} />} onClick={() => { setIsShowModalImport(true); }}>Import</Button>
            </>
          }
          <Button type="primary" icon={<ReloadOutlined style={{ verticalAlign: 'text-top' }} />} onClick={() => { loadStudyPrograms(); }}>Reload</Button>
        </Space>
        <div className='table'>
          <Table dataSource={data?.studyPrograms || []} columns={columns} />
        </div>
      </div>
      {
        user?.role === UserRoleConstant.ADMIN &&
        <>
          <ImportModal
            title={'Import Study Program'}
            visible={isShowModalImport}
            onCancel={() => setIsShowModalImport(false)}
            importType={ImportType.StudyProgramImport}
            callback={() => {
              setIsShowModalImport(false);
              loadStudyPrograms();
            }}
          />
        </>
      }
    </div>
  </Spin>
};

StudyProgram.propTypes = {};

export default StudyProgram;

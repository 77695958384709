import React, { useState } from 'react';

import {
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';

const ScoresViewList = ({
  scoreboards,
  classes,
  handleUpdate,
  handleRemove
}) => {
  return <div>
    <div>
      {
        classes?.map(classInfo => {
          return (
            <div key={classInfo.classId}>
              <div key={classInfo.classId} style={{ font: 'bold', fontSize: 20, marginTop: 20 }}>
                Class: {classInfo.name}
              </div>
              {
                scoreboards?.map((scoreboard, index) => {
                  if (scoreboard.classId !== classInfo.classId) {
                    return <></>
                  }
                  return <div key={`scoreboard${scoreboard.scoreBoardId}`} className='score-content'>
                    <div>
                      {
                        scoreboard?.scores?.map(score => (
                          <div key={score.score} className='score-detail'>
                            <div className='subject'>
                              {
                                score.subject
                              }
                            </div>
                            <div className='subject'>
                              {
                                score.score
                              }
                            </div>
                          </div>
                        ))
                      }
                    </div>
                    <div className='brand-detail'>
                      <div
                        className='brand-content-detail'
                      >
                        Overall Band Score:
                      </div>
                      <div
                        className='brand-content-score'
                      >
                        {
                          parseFloat(scoreboard?.scores.reduce((a, b) => a + b.score, 0) / scoreboard?.scores.length).toFixed(2)
                        }
                      </div>
                    </div>
                    <div className='dailyreport-action'>
                      <EditOutlined
                        onClick={() => handleUpdate(scoreboard)}
                      />
                      {' '}
                      <DeleteOutlined
                        onClick={() => handleRemove(scoreboard)}
                      />
                    </div>
                  </div>
                })
              }
            </div>

          )
        })
      }
    </div>
  </div>
};

ScoresViewList.propTypes = {};

export default ScoresViewList;

export const DateFormat = 'DD/MM/YYYY';
export const DateTimeFormat = 'DD/MM/YYYY HH:mm:ss';
export const LoadContentType = {
    default: 'exam',
    detail: 'exam-detail',
};
export const Mode = {
    View: 'view',
    Add: 'add',
    Edit: 'edit',
};
export const LabelColWidth = 160;
export const MaxLength = {
    Small: 255,
    Medium: 1000,
    Large: 5000,
};
export const QuestionType = {
    SingleChoice: 'SingleChoice',
    //MultipleChoice: 'MultipleChoice',
    ConstructedResponse: 'ConstructedResponse',
};
export const QuestionTypeDisplayName = {
    [QuestionType.SingleChoice]: 'Single-Choice',
    //[QuestionType.MultipleChoice]: 'Multiple-Choice',
    [QuestionType.ConstructedResponse]: 'Constructed-Response',
};
export const ExamStatus = {
    InActive: 'INACTIVE',
    Active: 'ACTIVE',
};
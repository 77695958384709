import gql from 'graphql-tag';

export const GET_NUMBER_USER_USED_APP = gql`
    query getNumberUsedApp($from: DateTime!, $to: DateTime!, $agencies: [String!]!){
        getNumberUsedApp(from: $from, to: $to, agencies: $agencies){
            data
        }
    }
`;

export const GET_NUMBER_USER_USED_APP_BY_USERID = gql`
    query getNumberUsedAppByUserId($from: DateTime!, $to: DateTime!, $userId: String){
        getNumberUsedAppByUserId(from: $from, to: $to, userId: $userId){
            data
        }
    }
`;

import React from 'react';


const Forbidden = () => {
  return <div>Forbidden</div>;
};

Forbidden.propTypes = {};

export default Forbidden;

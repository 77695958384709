import { Breadcrumb, Spin, Tabs } from "antd";
import { useState } from "react";
import StudentReportTable, { StudentReportTableType } from "./StudentReportTable";

const StudentReport = () => {
    const [loading, setLoading] = useState(false);

    return (
        <Spin tip="Loading..." spinning={loading}>
            <div className='post'>
                <div className='breadcrumb'>
                    <Breadcrumb>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item>Student Report</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className='body'>
                    <Tabs defaultActiveKey="1" style={{ marginLeft: 10 }}>
                        <Tabs.TabPane tab="Daily Report" key="1">
                            <StudentReportTable tableType={StudentReportTableType.DailyReport} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Comment" key="2">
                            <StudentReportTable tableType={StudentReportTableType.CommentOfTeacher} />
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            </div>
        </Spin>
    );
};

export default StudentReport;
import { ArrowDownOutlined, ArrowUpOutlined, MinusCircleOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, DatePicker, Divider, Form, Input, InputNumber, message, Modal, Row, Select, Space, Typography, Upload } from "antd";
import { useEffect, useState } from "react";
import moment from "moment";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CREATE_EXAM, EXAM_DETAIL, UPDATE_EXAM } from "../../../../graphql/exam";
import { LabelColWidth, MaxLength, Mode, QuestionType, QuestionTypeDisplayName } from "./const";
import Editor from "../../../../components/editor";
import { CREATE_RESOURCE } from "../../../../graphql/resourceMutations";

const InitialValues = {
  limitedTime: 60, //In minutes
  startDate: moment().startOf('date'),
  endDate: moment().startOf('date').add('day', 7),
  shortDescription: 'Short description',
};

const ExamDetail = ({ classId, examId, mode, returnFunc }) => {
  const [form] = Form.useForm();
  const [loadExamDetail, { loading: serverLoadingData }] = useLazyQuery(EXAM_DETAIL);
  const [createExam, { loading: serverCreatingProcessingData }] = useMutation(CREATE_EXAM);
  const [updateExam, { loading: serverUpdatingProcessingData }] = useMutation(UPDATE_EXAM);
  const [fileListContent, setFileListContent] = useState();
  const [createResource] = useMutation(CREATE_RESOURCE);
  const [content, setContent] = useState('');
  const [des, setDes] = useState('');
  const [lockedQuestion, setLockedQuestion] = useState(false);

  const onFinish = (values) => {
    let dto = {
      ...values,
      startDate: moment(values.startDate).toDate(),
      endDate: moment(values.endDate).toDate(),
      cover: fileListContent && fileListContent.length ? fileListContent[0].url : '',
    };
    let confirmTitle = 'Confirmation';
    let confirmContent = mode ===
      Mode.Add ? 'Are you sure you want to create this exam?' :
      Mode.Edit ? 'Are you sure you want to update this exam?' : '';

    console.log(dto);

    Modal.confirm({
      title: confirmTitle,
      content: confirmContent,
      onOk: () => {
        if (mode === Mode.Add) {
          createExam({
            variables: {
              createExamInput: {
                ...dto,
                description: content,
              }
            },
            onCompleted: (res) => {
              message.success('Created Exam successfully!', 1, () => {
                typeof returnFunc === 'function' && returnFunc();
              });
            },
            onError: (err) => {
              message.error('Created Exam failed!');
            }
          });
        }
        else if (mode === Mode.Edit) {
          clearProperties({ obj: dto, ignoreProperties: ['__typename', 'classId'] });
          updateExam({
            variables: {
              updateExamInput: {
                ...dto,
                description: content
              }
            },
            onCompleted: (res) => {
              message.success('Saved Exam successfully!', 1, () => {
                typeof returnFunc === 'function' && returnFunc();
              });
            },
            onError: (err) => {
              message.error('Saved Exam failed!');
            }
          });
        }
      }
    })
  };

  const isDisable = ({ fieldName }) => {
    return mode === Mode.View;
  };

  const getTitle = () => {
    if (mode === Mode.View) {
      return <Typography.Title level={4}>Exam Details</Typography.Title>;
    }
    else if (mode === Mode.Add) {
      return <Typography.Title level={4}>Create Exam</Typography.Title>;
    }
    else if (mode === Mode.Edit) {
      return <>
        <Typography.Title level={4}>Edit Exam</Typography.Title>
        {
          lockedQuestion &&
          <Typography.Text italic style={{ fontSize: 14 }} type='secondary'>
            <Typography.Text type='warning'>Be aware!&nbsp;</Typography.Text>
            This exam has started. So, you can only edit the text and anything else not affect the exam.
          </Typography.Text>
        }
      </>;
    }

    return '';
  };

  const getActions = () => {
    if (mode === Mode.View) {
      return [];
    }
    else if (mode === Mode.Add) {
      return [
        <Button
          onClick={() => {
            form.submit();
          }}
        >Create</Button>
      ];
    }
    else if (mode === Mode.Edit) {
      return [
        <Button
          onClick={() => {
            form.submit();
          }}
        >Save</Button>
      ];
    }

    return [];
  };

  const clearProperties = ({ obj, ignoreProperties }) => {
    if (typeof obj === 'object' && Array.isArray(ignoreProperties)) {
      Object.keys(obj).forEach((key) => {
        if (ignoreProperties.includes(key)) {
          obj[key] = undefined;
        }
        else if (typeof obj[key] === 'object') {
          clearProperties({ obj: obj[key], ignoreProperties });
        }
      })
    }
  };

  useEffect(() => {
    if ((mode === Mode.Edit || mode === Mode.View) && examId) {
      loadExamDetail({
        variables: {
          id: examId,
        },
        fetchPolicy: 'no-cache',
        onCompleted: (res) => {
          let values = {
            classId,
            ...InitialValues,
            ...res?.exam,
            startDate: moment(res?.exam?.startDate),
            endDate: moment(res?.exam?.endDate),
          };
          setDes(res?.exam?.description);
          console.log('res?.exam?.cover ', res?.exam)

          if (res?.exam?.cover) {
            setFileListContent([{
              uid: res?.exam?.cover,
              name: res?.exam?.cover,
              status: 'done',
              url: res?.exam?.cover,
            }]);
          }
          form.setFieldsValue(values);
          message.success('Loaded data successfully!');
        },
        onError: (err) => {
          message.error('Loaded data failed!');
        }
      });
    }
    else if (mode === Mode.Add && classId) {
      let values = {
        classId,
        ...InitialValues,
      };

      form.setFieldsValue(values);
    }
  }, [mode, examId, classId]);

  const uploadAudioContentUrl = async (file) => {
    const createResourceDt = await createResource({
      variables: {
        file: file
      }
    });
    setFileListContent([{
      uid: createResourceDt?.data?.createResource?.url,
      name: createResourceDt?.data?.createResource?.fileName,
      status: 'done',
      url: createResourceDt?.data?.createResource?.url,
    }]);
  }

  useEffect(() => {
    if (mode !== Mode.View && typeof form.getFieldValue(['lockedQuestion']) === 'boolean') {
      setLockedQuestion(form.getFieldValue(['lockedQuestion']));
    }
    else {
      setLockedQuestion(false);
    }
  }, [form.getFieldValue(['lockedQuestion'])]);

  return <>
    <Card
      loading={serverCreatingProcessingData || serverUpdatingProcessingData || serverLoadingData}
      title={getTitle()}
      actions={getActions()}
    >
      <Form
        className={mode === Mode.View ? 'exam-detail-readonly' : ''}
        form={form}
        layout='horizontal'
        initialValues={{
          classId,
          ...InitialValues,
        }}
        disabled={isDisable({ fieldName: '' })}
        onFinish={onFinish}
      >
        <Form.Item
          name='classId'
          hidden
        >
          <Input />
        </Form.Item>
        {mode === Mode.Edit ?
          <Form.Item
            name='examId'
            hidden
          >
            <Input />
          </Form.Item>
          : <></>}
        <Form.Item
          name='title'
          label='Title'
          tooltip={`Title(Limited ${MaxLength.Small} characters)`}
          labelCol={{ style: { width: LabelColWidth } }}
          rules={[{
            required: true,
            message: 'Title is required.'
          }]}
        >
          <Input maxLength={MaxLength.Small} />
        </Form.Item>
        <Form.Item
          name='cover'
          label='Cover Image'
          tooltip={`Cover Image(Limited An 2Mb Image)`}
          labelCol={{ style: { width: LabelColWidth } }}
          rules={[{
            required: true,
            message: 'Cover Image is required.'
          }]}
        >
          <Upload
            fileList={fileListContent || []}
            action={uploadAudioContentUrl}
          >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Form.Item>
        <Form.Item
          name='shortDescription'
          label='Brief Desc.'
          tooltip={`Brief Description(Limited ${MaxLength.Medium} characters)`}
          labelCol={{ style: { width: LabelColWidth } }}
          style={{ display: 'none' }}
          rules={[{
            required: true,
            message: 'Brief Description is required.'
          }]}
        >
          <Input.TextArea maxLength={MaxLength.Medium} />
        </Form.Item>
        <Form.Item
          name='description'
          label='Detail Desc.'
          tooltip={`Detail Description`}
          labelCol={{ style: { width: LabelColWidth } }}
        >
          <label></label>
          {/* <Input.TextArea maxLength={MaxLength.Large} /> */}
          <Editor
            value={des || ''}
            onChange={(newContent) => { setContent(newContent); }}
            readonly={mode === Mode.View}
          />
        </Form.Item>
        <Form.Item
          name='limitedTime'
          label='Limited Time'
          tooltip={`Limited Time(unit minutes)`}
          labelCol={{ style: { width: LabelColWidth } }}
          rules={[
            {
              required: true,
              message: 'Limited Time is required.'
            },
            ({ }) => ({
              validator(_, value) {
                if (value < 0) {
                  return Promise.reject(new Error('Please input positive number!'));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputNumber addonAfter={<b>minutes</b>} />
        </Form.Item>
        <Form.Item
          name='startDate'
          label='Start Date'
          tooltip={`Start Date`}
          style={{ display: 'none' }}
          labelCol={{ style: { width: LabelColWidth } }}
          rules={[
            {
              required: true,
              message: 'Start Date is required.'
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                let startDate = value;
                let endDate = getFieldValue(['endDate']);
                if (startDate && endDate
                  && moment(endDate).diff(moment(startDate)) < 0) {
                  return Promise.reject(new Error('Please select Start Date must be before End Date!'));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          name='endDate'
          label='End Date'
          tooltip={`End Date`}
          style={{ display: 'none' }}
          labelCol={{ style: { width: LabelColWidth } }}
          dependencies={['startDate']}
          rules={[
            {
              required: true,
              message: 'End Date is required.'
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                let startDate = getFieldValue(['startDate']);
                let endDate = value;
                if (startDate && endDate
                  && moment(endDate).diff(moment(startDate)) < 0) {
                  return Promise.reject(new Error('Please select End Date must be after Start Date!'));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <DatePicker />
        </Form.Item>
        <Card title='Questions'>
          <Form.List name="questions">
            {(fields, { add, remove, move }) => (
              <>
                {fields.map((field, index) => (
                  <Card
                    key={field.key}
                    className='exam-question-create-card' align="baseline"
                    title={`Question ${index + 1}`}
                    extra={mode === Mode.View ? <></> :
                      <>
                        <Space split={<Divider type='vertical' />}>
                          <Button
                            icon={<ArrowUpOutlined />}
                            onClick={() => {
                              // update field OrderID
                              form.setFields([
                                { name: ['questions', field.name, 'orderId'], value: index <= 0 ? 1 : index },
                              ]);
                              if (field.name - 1 >= 0) {
                                form.setFields([
                                  { name: ['questions', field.name - 1, 'orderId'], value: index + 1 },
                                ]);
                              }
                              move(index, index - 1);
                            }}
                          >Move Up</Button>
                          <Button
                            icon={<ArrowDownOutlined />}
                            onClick={() => {
                              // update field OrderID
                              form.setFields([{ name: ['questions', field.name, 'orderId'], value: index + 2 >= fields.length ? fields.length : index + 2 }]);
                              if (field.name + 1 < fields.length) {
                                form.setFields([{ name: ['questions', field.name + 1, 'orderId'], value: index + 1 }]);
                              }
                              move(index, index + 1);
                            }}
                          >Move Down</Button>
                          <MinusCircleOutlined
                            style={{ display: lockedQuestion ? 'none' : 'inline-block' }}
                            className='exam-text-danger'
                            onClick={() => remove(field.name)}
                          />
                        </Space>
                      </>}
                  >
                    <Form.Item
                      hidden
                      name={[field.name, 'orderId']}
                      initialValue={index + 1}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      label="Question Type"
                      name={[field.name, 'questionType']}
                      tooltip={`Question Type`}
                      labelCol={{ style: { width: LabelColWidth } }}
                      rules={[{ required: true, message: 'Question Type is required.' }]}
                      initialValue={QuestionType.SingleChoice}
                    >
                      <Select
                        disabled={lockedQuestion}
                        style={{ width: 200 }}
                        onChange={(value) => {
                          if (value === QuestionType.ConstructedResponse) {
                            form.setFields([{ name: ['questions', field.name, 'selectedResponses'], value: undefined }]);
                            form.validateFields([['questions', field.name, 'selectedResponses']]);
                          }
                          else {
                            form.setFields([{ name: ['questions', field.name, 'selectedResponses'], value: [] }]);
                          }
                        }}>
                        {Object.keys(QuestionType).map((item) => (
                          <Select.Option key={item} value={QuestionType[item]}>
                            {QuestionTypeDisplayName[QuestionType[item]]}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...field}
                      label="Title"
                      name={[field.name, 'title']}
                      tooltip={`Question Title(Limited ${MaxLength.Small} characters)`}
                      labelCol={{ style: { width: LabelColWidth } }}
                      rules={[{ required: true, message: 'Title is required.' }]}
                    >
                      <Input maxLength={MaxLength.Small} />
                    </Form.Item>
                    <Form.List
                      name={[field.name, 'selectedResponses']}
                      initialValue={[]}
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (getFieldValue(['questions', field.name, 'questionType']) === QuestionType.SingleChoice
                              && (!Array.isArray(value) || value.length === 0)) {
                              return Promise.reject(new Error('Please add Selected-Responses for Selected-Response Question!'));
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      {(selectedFields, selectedActions, { errors }) => (
                        <Card>
                          {selectedFields.map((selectedField, selectedFieldIndex) => (<>
                            <Row align='top' style={{ marginBottom: '1rem' }}>
                              <Form.Item
                                hidden
                                name={[selectedField.name, 'orderId']}
                                initialValue={selectedFieldIndex + 1}
                              >
                                <Input />
                              </Form.Item>
                              <Form.Item
                                hidden
                                name={[selectedField.name, 'selectedResponseId']}
                              >
                                <Input />
                              </Form.Item>
                              <Form.Item
                                hidden
                                name={[selectedField.name, 'questionId']}
                              >
                                <Input />
                              </Form.Item>
                              <Form.Item
                                style={{ flex: 1 }}
                                {...selectedField}
                                label={`Selected-Res ${selectedFieldIndex + 1}`}
                                name={[selectedField.name, 'content']}
                                tooltip={`Selected Response(Limited ${MaxLength.Medium} characters)`}
                                labelCol={{ style: { width: LabelColWidth } }}
                                rules={[{ required: true, message: 'Selected Response Content is required.' }]}
                              >
                                <Input maxLength={MaxLength.Medium} />
                              </Form.Item>
                              <Form.Item
                                style={{ marginLeft: '1rem', width: '160px' }}
                                {...selectedField}
                                // label={`Is Correct?`}
                                name={[selectedField.name, 'isCorrect']}
                                // tooltip={`Check if this is the correct response.`}
                                valuePropName='checked'
                                initialValue={false}
                                dependencies={selectedFields.map((selectedFieldData) => (['questions', field.name, 'selectedResponses', selectedFieldData.name, 'isCorrect']))}
                                rules={[
                                  ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (value
                                        && getFieldValue(['questions', field.name, 'selectedResponses'])
                                          .some((selectedResponse, index) => index !== selectedField.name
                                            && selectedResponse.isCorrect === value)) {
                                        return Promise.reject(new Error('Only one answer!'));
                                      }
                                      else if (!value
                                        && getFieldValue(['questions', field.name, 'selectedResponses'])
                                          .every((selectedResponse) => selectedResponse.isCorrect === value)) {
                                        return Promise.reject(new Error('Not select answer yet!'));
                                      }
                                      return Promise.resolve();
                                    },
                                  }),
                                ]}
                              >
                                <Checkbox disabled={mode === Mode.View || lockedQuestion}>Is Answer?</Checkbox>
                              </Form.Item>
                              {mode === Mode.View ? <></> :
                                <MinusCircleOutlined
                                  style={{ margin: '1rem', display: lockedQuestion ? 'none' : 'inline-block' }}
                                  className='exam-text-danger'
                                  title='Delete selected response'
                                  onClick={() => selectedActions.remove(selectedField.name)}
                                />}
                            </Row>
                          </>))}
                          <Form.ErrorList errors={errors} />
                          {mode === Mode.View ? <></> :
                            <Form.Item style={{ display: lockedQuestion ? 'none' : 'flex' }}>
                              <Button
                                disabled={
                                  lockedQuestion
                                  || form.getFieldValue(['questions', field.name, 'questionType']) === QuestionType.ConstructedResponse
                                  || form.getFieldValue(['questions', field.name, 'selectedResponses']) === undefined
                                }
                                type="dashed" onClick={() => selectedActions.add()} block icon={<PlusOutlined />}>
                                Add selected-responses
                              </Button>
                            </Form.Item>}
                        </Card>
                      )}
                    </Form.List>
                  </Card>
                ))}

                {mode === Mode.View ? <></> :
                  <Form.Item style={{ display: lockedQuestion ? 'none' : 'flex' }}>
                    <Button disabled={lockedQuestion} type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      Add questions
                    </Button>
                  </Form.Item>}
              </>
            )}
          </Form.List>
        </Card>
      </Form>
    </Card>
  </>;
};

export default ExamDetail;
import React, { useContext, useEffect, useState } from 'react';

import * as moment from 'moment';
import { Breadcrumb, Table, Button, Modal, Spin, Form, Input, Divider } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

import { CREATE_AGENCY, LIST_AGENCY, REMOVE_AGENCY, UPDATE_AGENCY } from '../../graphql/agency';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/auth';
import { UserRoleConstant } from '../../constants';

const Agency = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  const [isShowModal, setIsShowModal] = useState(false);
  const [updateAgencyItem, setUpdateAgencyItem] = useState();

  useEffect(() => {
    form.setFieldsValue({
      name: updateAgencyItem?.name ?? '',
      description: updateAgencyItem?.description ?? '',
    });
  }, [updateAgencyItem, form])

  const [removeAgency] = useMutation(REMOVE_AGENCY, {
    refetchQueries: [{ query: LIST_AGENCY, variables: { filter: {} } }],
    onCompleted: (response) => {
      if (response) {
        Modal.success({ content: 'Remove agency success! ' });
      }
    },
    onError: () => {
      Modal.error({
        content: <>Remove agency fail.<Divider type='horizontal' />You cannot remove the used agency.</>
      });
    }
  });

  const [updateAgency] = useMutation(UPDATE_AGENCY, {
    refetchQueries: [{ query: LIST_AGENCY, variables: { filter: {} } }],
    onCompleted: (response) => {
      if (response && response.updateAgency) {
        Modal.success({ content: 'Update success! ' });
      }
    },
    onError: (_createError) => {
      Modal.error({ content: 'Update fail!' });
    }
  });

  const [createAgency] = useMutation(CREATE_AGENCY, {
    refetchQueries: [{ query: LIST_AGENCY, variables: { filter: {} } }],
    onCompleted: (response) => {
      if (response && response.createAgency) {
        Modal.success({ content: 'Create success! ' });
      }
    },
    onError: (_createError) => {
      Modal.error({ content: 'Create fail!' });
    }
  });

  const { loading, error, data } = useQuery(LIST_AGENCY, { variables: { filter: {} } });

  const handleDelete = (id) => {
    Modal.confirm({
      title: 'Delete agency?',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure delete this agency?',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: () => {
        removeAgency({
          variables: {
            id: id
          }
        })
      }
    });
  }

  const handleAddNew = () => {
    setUpdateAgencyItem(null);
    form.resetFields();
    setIsShowModal(true);
  }

  const handleUpdate = (item) => {
    setIsShowModal(true);
    setUpdateAgencyItem(item);
  }

  if (error) {
    Modal.error({ content: 'Get agencies error' });
  }

  const columns = [
    {
      title: 'No',
      key: 'index',
      render: (value, item, index) => index + 1
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (value, item, index) => {
        return <div
          style={{ color: 'blue', cursor: 'pointer' }}
          onClick={() => navigate(`/agencies/${item.agencyId}`)}
        >
          {value}
        </div>
      }
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (createdAt) => moment(createdAt).format('DD/MM/YYYY hh:mm:ss'),
    },
    ...(user?.role === UserRoleConstant.ADMIN ? [{
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (value, item) => {
        return <>
          <EditOutlined onClick={() => handleUpdate(item)} className='mr10' />
          <DeleteOutlined onClick={() => handleDelete(item.agencyId)} />
        </>;
      },
    }] : []),
  ];

  const onFinish = (value) => {
    console.log('value ', value);
    if (!updateAgencyItem) {
      // handle create
      createAgency({
        variables: {
          createAgencyInput: {
            ...value
          }
        }
      });
    } else {
      updateAgency({
        variables: {
          updateAgencyInput: {
            agencyId: updateAgencyItem.agencyId,
            ...value
          }
        }
      })
    }

    setIsShowModal(false);
  }

  return (
    <Spin tip="Loading..." spinning={loading}>
      <div className='post'>
        <div className='breadcrumb'>
          <Breadcrumb>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>Agency</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className='body'>
          <div className='add-new'>
            {
              user?.role === UserRoleConstant.ADMIN &&
              <Button type="primary" icon={<PlusOutlined />} onClick={handleAddNew}>Add New</Button>
            }
          </div>
          <div className='table'>
            <Table dataSource={data?.agencies || []} columns={columns} />
          </div>
        </div>
      </div>
      <Modal
        title={updateAgencyItem ? 'Update' : 'Add new'}
        visible={isShowModal}
        width={800}
        onOk={() => {
          form.submit();
        }}
        onCancel={() => {
          setIsShowModal(false);
        }}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          layout={'vertical'}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            name="name"
            label="Name:"
            rules={[{ required: true, message: 'Name is required' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description:"
            rules={[{ required: true, message: 'Description is required' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </Spin>
  );
};

Agency.propTypes = {};

export default Agency;

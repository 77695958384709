import gql from 'graphql-tag';

export const TEACHER_STUDENTS = gql`
  query teacherStudents($take: Float, $skip: Float, $agencyId: String!) {
    teachers: teachers_findAll(take: 10000) {
      userId
      fullname
      agencyId
      agencies {
        agencyId
      }
    }
    students: students_findAll_byAgency(
      take: $take
      skip: $skip
      agencyId: $agencyId
    ) {
      userId
      fullname
    }
  }
`;

export const CREATE_CLASS = gql`
  mutation createClass($createClassInput: CreateClassInput!) {
    createClass(createClassInput: $createClassInput) {
      name
      classId
    }
  }
`;

export const UPDATE_CLASS = gql`
  mutation updateClass($updateClassInput: UpdateClassInput!) {
    updateClass(updateClassInput: $updateClassInput) {
      name
      classId
    }
  }
`;

export const REMOVE_CLASS = gql`
  mutation removeClass($classId: String!) {
    removeClass(classId: $classId) {
      name
      classId
    }
  }
`;

export const LIST_CLASS = gql`
  query classes(
    $take: Float
    $skip: Float
    $search: String
    $filters: FiltersClassInput
  ) {
    total: classes_findTotal(search: $search, filters: $filters)
    classes: classes_findAll(
      take: $take
      skip: $skip
      search: $search
      filters: $filters
    ) {
      name
      classId
      description
      startAt
      endAt
      fromAt
      toAt
      agencyId
      agency {
        name
        description
      }
      grade
      gradeLevel
      schoolYear
      studyProgram {
        studyProgramId
      }
      scheduleTime
      students {
        studentId
      }
      status
      teachers {
        teacherId
        role
      }
    }
  }
`;

export const SCORE_BOARD_CLASS = gql`
  query scoreBoards($classId: String!) {
    scoreBoards: scoreBoard_findByClassId(classId: $classId) {
      scoreBoardId
      scores
      userId
      created_at
      class {
        name
        description
      }
      user {
        fullname
        userId
        email
      }
    }
  }
`;

export const ADD_COMMENT_TEACHER = gql`
  mutation commentOfTeacher_create(
    $createCommentOfTeacherInput: CreateCommentOfTeacherInput!
  ) {
    commentOfTeacher_create(
      createCommentOfTeacherInput: $createCommentOfTeacherInput
    ) {
      subject
    }
  }
`;
export const UPDATE_COMMENT_TEACHER = gql`
  mutation commentOfTeacher_update(
    $updateCommentOfTeacherInput: UpdateCommentOfTeacherInput!
  ) {
    commentOfTeacher_update(
      updateCommentOfTeacherInput: $updateCommentOfTeacherInput
    ) {
      subject
    }
  }
`;

export const ADD_SMART_QUESTION = gql`
  mutation addSmartQuestionsToClass(
    $classId: String!
    $smartQuestions: [String!]!
  ) {
    addSmartQuestionsToClass(classId: $classId, smartQuestions: $smartQuestions)
  }
`;

export const SMART_QUESTION_BY_CLASS = gql`
  query smartQuestionsByClass($classId: String!) {
    smartQuestion: smartQuestionsByClass(classId: $classId) {
      smartQuestionId
      answerEn
      answerVi
      contentEn
      contentVi
      created_at
    }
  }
`;
export const REMOVE_SMART_QUESTION_BY_CLASS = gql`
  mutation removeSmartQuestionsFromClass(
    $classId: String!
    $smartQuestionId: String!
  ) {
    removeSmartQuestionsFromClass(
      classId: $classId
      smartQuestionId: $smartQuestionId
    )
  }
`;

export const LIST_MATERIALS = gql`
  query materials($classId: String!) {
    materials: resources_findAllStudySupportMaterials(classId: $classId) {
      resourceId
      category
      encoding
      fileName
      mimetype
      url
    }
  }
`;

export const UPLOAD_MATERIALS = gql`
  mutation resources_uploadStudySupportMaterials(
    $category: String!
    $classId: String!
    $file: Upload!
    $fileName: String!
  ) {
    resources_uploadStudySupportMaterials(
      category: $category
      classId: $classId
      file: $file
      fileName: $fileName
    )
  }
`;

export const REMOVE_MATERIALS = gql`
  mutation resources_removeStudySupportMaterials($resourceId: String!) {
    resources_removeStudySupportMaterials(resourceId: $resourceId)
  }
`;

export const GET_CLASS_NAME_LIST = gql`
  query classes_getClassNameList {
    classes: classes_getClassNameList
  }
`;

import React, { useState, useEffect, useContext } from 'react';
import * as moment from 'moment';
import { Breadcrumb, Table, Button, Modal, Form, Input, Spin, Upload } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
  UploadOutlined,
} from '@ant-design/icons';

import { useMutation, useQuery } from '@apollo/client';
import { CREATE_RESOURCE } from '../graphql/resourceMutations';
import { ADD_SMART_QUESTION, REMOVE_SMART_QUESTION, SMART_QUESTIONS, UPDATE_SMART_QUESTION } from '../graphql/smartQuestion';
import { AuthContext } from '../contexts/auth';
import { UserRoleConstant } from '../constants';

const SmartQuestion = () => {
  const { user } = useContext(AuthContext);
  const [isShowModalAddNew, setIsShowModalAddNew] = useState(false);
  const [audioContentUrl, setAudioContentUrl] = useState('');
  const [audioAnswerUrl, setAudioAnswerUrl] = useState('');
  const [fileListAnswer, setFileListAnswer] = useState();
  const [fileListContent, setFileListContent] = useState();
  const [pageLoading, setPageLoading] = useState(false);
  const [initForm, setInitForm] = useState({});
  const [form] = Form.useForm();

  const { loading, error, data } = useQuery(SMART_QUESTIONS);

  const [createResource] = useMutation(CREATE_RESOURCE);
  const [createSmartQuestion] = useMutation(ADD_SMART_QUESTION, {
    refetchQueries: [{ query: SMART_QUESTIONS }],
    onCompleted: (response) => {
      setPageLoading(false);
      if (response && response.smartQuestion) {
        Modal.success({ title: 'Create success! ' });
        setInitForm({});
        form.setFieldsValue({
          contentEn: '',
          contentVi: '',
          answerEn: '',
          answerVi: '',
          audioContentUrl: '',
          audioAnswerUrl: '',
          smartQuestionId: ''
        });
      }
    },
    onError: (_createError) => {
      setPageLoading(false)
      Modal.error({ content: 'Create fail' });
    }
  });

  const [updateSmartQuestion] = useMutation(UPDATE_SMART_QUESTION, {
    refetchQueries: [{ query: SMART_QUESTIONS }],
    onCompleted: (response) => {
      if (response) {
        setPageLoading(false);
        Modal.success({ title: 'Update success! ' });
        setInitForm({});
        form.setFieldsValue({
          contentEn: '',
          contentVi: '',
          answerEn: '',
          answerVi: '',
          audioContentUrl: '',
          audioAnswerUrl: '',
          smartQuestionId: ''
        });
      }
    },
    onError: () => {
      setPageLoading(false)
      Modal.error({ content: 'Update fail' });
      form.setFieldsValue({
        contentEn: '',
        contentVi: '',
        answerEn: '',
        answerVi: '',
        audioContentUrl: '',
        audioAnswerUrl: '',
        smartQuestionId: ''
      });
    }
  });

  const [removeSmartQuestion] = useMutation(REMOVE_SMART_QUESTION, {
    refetchQueries: [{ query: SMART_QUESTIONS }],
    onCompleted: (response) => {
      if (response) {
        setPageLoading(false);
        Modal.success({ title: 'Remove success! ' });
        setInitForm({});
      }
    },
    onError: () => {
      setPageLoading(false)
      Modal.error({ title: 'Lỗi', content: 'Remove fail' });
    }
  });

  useEffect(() => {
    form.setFieldsValue({
      contentEn: initForm.contentEn,
      contentVi: initForm.contentVi,
      answerEn: initForm.answerEn,
      answerVi: initForm.answerVi,
      audioContentUrl: initForm.audioContentUrl,
      audioAnswerUrl: initForm.audioAnswerUrl,
      smartQuestionId: initForm.smartQuestionId
    });

    setAudioContentUrl(initForm.audioContentUrl);
    setAudioAnswerUrl(initForm.audioAnswerUrl);
    setFileListAnswer([{
      uid: initForm.audioAnswerUrl,
      name: initForm.audioAnswerUrl,
      status: 'done',
      url: initForm.audioAnswerUrl,
    }]);
    setFileListContent([{
      uid: initForm.audioContentUrl,
      name: initForm.audioContentUrl,
      status: 'done',
      url: initForm.audioContentUrl,
    }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initForm]);

  if (error) {
    if (error?.message?.includes('Failed to fetch')) {
      Modal.error({ title: 'Lỗi', content: 'Lỗi kết nối server' });
    } else {
      Modal.error({ title: 'Lỗi', content: error.message });
    }
  }

  const uploadAudioContentUrl = async (file) => {
    const createResourceDt = await createResource({
      variables: {
        file: file
      }
    });
    setAudioContentUrl(createResourceDt?.data?.createResource?.url);
    setFileListContent([{
      uid: createResourceDt?.data?.createResource?.url,
      name: createResourceDt?.data?.createResource?.fileName,
      status: 'done',
      url: createResourceDt?.data?.createResource?.url,
    }]);
  }


  const uploadAudioAnswerUrl = async (file) => {
    const createResourceDt = await createResource({
      variables: {
        file: file
      }
    });
    setAudioAnswerUrl(createResourceDt?.data?.createResource?.url);
    setFileListAnswer([{
      uid: createResourceDt?.data?.createResource?.url,
      name: createResourceDt?.data?.createResource?.fileName,
      status: 'done',
      url: createResourceDt?.data?.createResource?.url,
    }]);
  }

  const handleAddNew = () => {
    setInitForm({});
    setIsShowModalAddNew(true);
  };

  const closeModal = () => {
    setIsShowModalAddNew(false);
  };

  const handleSaveUser = () => {
    setIsShowModalAddNew(false);
  };

  const hanleDelete = (smartQuestionsId) => {
    Modal.confirm({
      title: 'Delete student?',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure delete this smart question?',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: () => {
        setPageLoading(true);
        removeSmartQuestion({
          variables: {
            smartQuestionsId
          }
        })
      }
    });
  };

  const handleUpdate = (item) => {
    setInitForm(item);
    setIsShowModalAddNew(true);
  };

  const columns = [
    {
      title: 'No',
      key: 'index',
      render: (_value, _item, index) => index + 1
    },
    {
      title: 'Content',
      dataIndex: 'contentEn',
      key: 'contentEn'
    },
    {
      title: 'Answer',
      dataIndex: 'answerEn',
      key: 'answerEn',
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at) => moment(created_at).format('DD/MM/YYYY hh:mm:ss'),
    },
    ...(user?.role === UserRoleConstant.ADMIN ? [{
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_value, item) => {
        return <>
          <EditOutlined onClick={() => handleUpdate(item)} className='mr10' />
          <DeleteOutlined onClick={() => hanleDelete(item.smartQuestionId)} />
        </>;
      },
    }] : []),
  ];


  const onFinish = async (values) => {
    setPageLoading(true);
    setIsShowModalAddNew(false);
    if (initForm.smartQuestionId) {
      updateSmartQuestion({
        variables: {
          updateSmartQuestionsInput: {
            ...values,
            audioContentUrl,
            audioAnswerUrl,
            smartQuestionId: initForm.smartQuestionId
          }
        }
      })
    } else {
      createSmartQuestion({
        variables: {
          createSmartQuestionsInput: {
            ...values,
            audioAnswerUrl,
            audioContentUrl
          },
        }
      });
    }
  };

  const onFinishFailed = () => {
    return;
  };

  return <Spin tip="Loading..." spinning={pageLoading || loading}>
    <div className='post'>
      <div className='breadcrumb'>
        <Breadcrumb>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>Smart Questions</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className='body'>
        <div className='add-new'>
          {
            user?.role === UserRoleConstant.ADMIN &&
            <Button type="primary" icon={<PlusOutlined />} onClick={handleAddNew}>Add New</Button>
          }
        </div>
        <div className='table'>
          <Table dataSource={data?.smartQuestions} columns={columns} />
        </div>
      </div>
      {
        user?.role === UserRoleConstant.ADMIN &&
        <Modal
          forceRender
          title={initForm?.smartQuestionId ? "Edit smart questions" : "Add smart questions"}
          visible={isShowModalAddNew}
          onOk={handleSaveUser}
          onCancel={closeModal}
          bodyStyle={{
            height: 500,
            overflow: 'auto'
          }}
          footer={null}
        >
          <Form
            name="basic"
            form={form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 24 }}
            initialValues={initForm}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout='vertical'
            autoComplete="off"
          >
            <Form.Item
              label="Content (EN)"
              name="contentEn"
              rules={[{ required: true, message: 'Please input content!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Content (Vi)"
              name="contentVi"
              rules={[{ required: true, message: 'Please input content!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Answer (EN)"
              name="answerEn"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Answer (Vi)"
              name="answerVi"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Audio content"
              name="audioContentUrl"
            >
              <Upload
                fileList={fileListContent}
                action={uploadAudioContentUrl}
              >
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Form.Item>
            <Form.Item
              label="Audio answer"
              name="audioAnswerUrl"
            >
              <Upload
                action={uploadAudioAnswerUrl}
                fileList={fileListAnswer}
              >
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 20, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      }
    </div>
  </Spin>;
};

SmartQuestion.propTypes = {};

export default SmartQuestion;

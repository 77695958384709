import { useLazyQuery } from "@apollo/client";
import { Checkbox, Select, Space, Typography } from "antd";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { UserRoleConstant } from "../../constants";
import { AuthContext } from "../../contexts/auth";
import { LIST_AGENCY } from "../../graphql/agency";

const AgencyFilter = ({ onSelect }) => {
    const { user } = useContext(AuthContext);
    const [agencies, setAgencies] = useState(null);
    const [selectedAgencies, setSelectedAgencies] = useState([]);
    const [checkAll, setCheckAll] = useState(true);
    const [loadAgencies, { data, loading }] = useLazyQuery(LIST_AGENCY);

    useEffect(() => {
        loadAgencies();
    }, []);

    useEffect(() => {
        let tmp = (data?.agencies ?? []).map(agency => ({ label: agency.name, value: agency.agencyId }));
        if (user?.role === UserRoleConstant.ADMIN) {
            setSelectedAgencies(tmp.map((val) => val.value));
            typeof onSelect === 'function' && onSelect(tmp.map((val) => val.value));
        }
        setAgencies(tmp);
    }, [data]);

    useEffect(() => {
        if (checkAll) {
            setSelectedAgencies((agencies ?? []).map((val) => val.value));
        }
    }, [checkAll]);

    useEffect(() => {
        typeof onSelect === 'function' && onSelect(selectedAgencies);
    }, [selectedAgencies]);

    return <>
        <Space direction='horizontal'>
            <Typography.Text>Select Agencies</Typography.Text>
            <Checkbox
                checked={checkAll}
                onChange={(e) => {
                    setCheckAll(e.target.checked);
                    !e.target.checked && setSelectedAgencies([]);
                }}>All</Checkbox>
            <Select
                className='min-w-[200px]'
                mode='tags'
                value={selectedAgencies}
                onChange={(values) => {
                    setSelectedAgencies(values);
                    if (values.length === (agencies ?? []).length) {
                        setCheckAll(true);
                    }
                    else {
                        setCheckAll(false);
                    }
                }}
                loading={loading} options={(agencies ?? [])} />
        </Space>
    </>;
};

export default AgencyFilter;
export const ColorList = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae'];
export const UserRoleConstant = {
  ADMIN: 'admin',
  TEACHER: 'teacher',
  STUDENT: 'student',
  AGENCY_ADMIN: 'agency_admin',
};

export const HOME_ROUTE = '/student';

export let GLOBAL_VISIBLE = false;

export const GRADE_CONST = [
  {
    name: 'ANH VĂN THIẾU NHI',
  },
  {
    name: 'ANH VĂN QUỐC TẾ',
  },
  {
    name: 'AVGT',
  },
  {
    name: 'IELTS',
  },
  {
    name: 'AVGT2',
  },
  {
    name: '1 on 1',
  },
  {
    name: 'ANH VĂN MẦM NON',
  },
  {
    name: 'ANH VĂN PHỔ THÔNG',
  },
];

export const LEVELS_CONST = [
  {
    name: 'Movers',
  },
  {
    name: '1 on 1',
  },
  {
    name: 'Starters',
  },
  {
    name: 'Pre-Starters',
  },
  {
    name: 'IELTS level 1',
  },
  {
    name: 'IELTS level 2',
  },
  {
    name: 'IELTS level 3',
  },
  {
    name: 'IELTS level 4',
  },
  {
    name: 'IELTS level 5',
  },
  {
    name: 'KET 1',
  },
  {
    name: 'KET 2',
  },
  {
    name: 'PET 1',
  },
  {
    name: 'PET 2',
  },
  {
    name: 'ANH VĂN GIAO TIẾP',
  },
  {
    name: 'FA 1',
  },
  {
    name: 'FA 2',
  },
  {
    name: 'FA 3',
  },
  {
    name: 'FA 4',
  },
  {
    name: 'GRADE 6',
  },
  {
    name: 'GRADE 7',
  },
  {
    name: 'GRADE 9',
  },
  {
    name: 'GRADE 12',
  },
  {
    name: 'IELTS 1',
  },
  {
    name: 'Imagine 1',
  },
  {
    name: 'Imagine 2',
  },
  {
    name: 'Imagine 3',
  },
  {
    name: 'Imagine 4',
  },
  {
    name: "LET'S 1A",
  },
  {
    name: "LET'S BEGIN",
  },
  {
    name: 'THINK 1',
  },
  {
    name: 'THINK 2',
  },
  {
    name: 'THINK STARTER',
  },
  {
    name: "Let's 1B",
  },
  {
    name: 'Pre-Movers',
  },
  {
    name: 'Pre-Flyers',
  },
  {
    name: 'Flyers',
  },
  {
    name: 'Access 1',
  },
  {
    name: 'Access 2',
  },
  {
    name: 'Access 3',
  },
  {
    name: 'Access 4',
  },
  {
    name: 'GRADE 8',
  },
  {
    name: 'GRADE 10',
  },
  {
    name: 'GRADE 11',
  },
  {
    name: 'TOEIC 1',
  },
  {
    name: 'TOEIC 2',
  },
  {
    name: 'TOEIC 3',
  },
  {
    name: 'TOEIC 4',
  },
];

export const STUDENT_CLASS_STATUS = {
  isActive: 'isActive',
  isDisable: 'isDisable',
};

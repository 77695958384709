import { gql } from "@apollo/client";

export const LOAD_EXAMS = gql`
    query exams($filter: FilterExamInput!){
        exams(filter: $filter){
            examId
            examType
            title
            shortDescription
            description
            limitedTime
            startDate
            endDate
            totalQuestion
            status
            lockedQuestion
        }
    }
`;

export const EXAM_DETAIL = gql`
    query exam($id: String!){
        exam(id: $id){
            examId
            examType
            title
            shortDescription
            cover
            description
            limitedTime
            startDate
            endDate
            questions {
                questionId
                questionType
                title
                selectedResponses {
                    selectedResponseId
                    content
                    isCorrect
                }
            }
            lockedQuestion
        }
    }
`;

export const LOAD_STUDENT_EXAMS = gql`
    query studentExams($studentId: String!){
        studentExams(studentId: $studentId){
            examId
            examType
            title
            shortDescription
            description
            limitedTime
            startDate
            endDate
            score
            correctQuestion
            totalQuestion
            submittedDate
        }
    }
`;

export const LOAD_STUDENT_EXAM = gql`
    query studentExam($examId: String!, $studentId: String!){
        studentExam(examId: $examId, studentId: $studentId){
            studentExamId
            examId
            examType
            title
            shortDescription
            description
            limitedTime
            startDate
            endDate
            score
            correctQuestion
            totalQuestion
            submittedDate
            studentExamConstructedResponses {
                studentExamConstructedResponseId
                content
                attachment
                score
            }
            studentExamSelectedResponses {
                selectedResponseId
            }
            questions {
                questionId
                questionType
                title
                content
                selectedResponseId
                studentExamConstructedResponse {
                    content
                    attachment
                }
                selectedResponses {
                    selectedResponseId
                    content
                    isCorrect
                }
            }
        }
    }
`;

export const CREATE_EXAM = gql`
    mutation createExam($createExamInput: CreateExamInput!){
        createExam(createExamInput: $createExamInput){
            examId
        }
    }
`;

export const UPDATE_EXAM = gql`
    mutation updateExam($updateExamInput: UpdateExamInput!){
        updateExam(updateExamInput: $updateExamInput){
            examId
        }
    }
`;

export const UPDATE_EXAM_STATUS = gql`
    mutation updateExamStatus($updateExamStatusInput: UpdateExamStatusInput!){
        updateExamStatus(updateExamStatusInput: $updateExamStatusInput)
    }
`;

export const REMOVE_EXAM = gql`
    mutation removeExam($id: String!){
        removeExam(id: $id)
    }
`;
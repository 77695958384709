import React, { useState, useRef, useEffect, useContext } from 'react';
import {
  Breadcrumb,
  Button,
  Modal,
  Form,
  Input,
  Avatar,
  Spin,
  Select,
  Space,
} from 'antd';
import {
  PlusOutlined,
  ExclamationCircleOutlined,
  UserOutlined,
  ImportOutlined,
  ReloadOutlined,
} from '@ant-design/icons';

import {
  STUDENTS,
  CREATE_STUDENT,
  UPDATE_STUDENT,
  REMOVE_STUDENT,
  STUDENT_BY_ID,
  CHECK_EXIST_UID,
} from '../graphql/student';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { CREATE_RESOURCE } from '../graphql/resourceMutations';
import { useSearchParams } from 'react-router-dom';
import { LIST_CLASS } from '../graphql/class';
import { AuthContext } from '../contexts/auth';
import ImportModal, { ImportType } from '../components/import/ImportModal';
import { LIST_AGENCY } from '../graphql/agency';
import { UserRoleConstant } from '../constants';
import { RESET_PASSWORD } from '../graphql/users';
import StudentTableComponent from '../components/tables/student.table.component';
import { StorageHelper } from '../utils/storage.helper';
import { PagingType } from '../constants/paging';

const Student = () => {
  let [searchParams, setSearchParams] = useSearchParams();

  const [isShowModalAddNew, setIsShowModalAddNew] = useState(false);
  const [isShowModalImport, setIsShowModalImport] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [students, setStudents] = useState(false);
  const [initForm, setInitForm] = useState({});
  const [form] = Form.useForm();
  const [fileAvatar, setFileAvatar] = useState();
  const avatarRef = useRef();
  const [resetForm, setResetForm] = useState(0);
  const [filterAgencyId, setFilterAgencyId] = useState(null);

  const [loadStudents, { loading, error, data }] = useLazyQuery(STUDENTS, {
    fetchPolicy: 'no-cache',
  });
  const [checkExistUid] = useLazyQuery(CHECK_EXIST_UID);
  const [resetPassword] = useLazyQuery(RESET_PASSWORD);
  const { data: classData } = useQuery(LIST_CLASS, {
    variables: {
      take: 50000,
    },
  });
  const { data: agencies } = useQuery(LIST_AGENCY, {
    variables: { filter: {} },
  });
  const auth = useContext(AuthContext);

  const [createResource] = useMutation(CREATE_RESOURCE);
  const [createStudent] = useMutation(CREATE_STUDENT, {
    refetchQueries: [{ query: STUDENTS }],
    onCompleted: (response) => {
      if (response && response.createStudent) {
        setPageLoading(false);
        Modal.success({ title: 'Create success! ' });
        setInitForm({});
        loadStudents();
      }
    },
    onError: (_createError) => {
      setPageLoading(false);
      Modal.error({ content: 'Create student fail' });
    },
  });

  const [updateStudent] = useMutation(UPDATE_STUDENT, {
    refetchQueries: [
      { query: STUDENTS },
      { query: LIST_CLASS },
      { query: STUDENT_BY_ID, variables: { id: initForm.userId } },
    ],
    onCompleted: (response) => {
      if (response) {
        setPageLoading(false);
        Modal.success({ title: 'Update student success! ' });
        setInitForm({});
        loadStudents();
      }
    },
    onError: () => {
      setPageLoading(false);
      Modal.error({ content: 'Update student fail' });
    },
  });

  const [removeStudent] = useMutation(REMOVE_STUDENT, {
    refetchQueries: [{ query: STUDENTS }],
    onCompleted: (response) => {
      if (response) {
        setPageLoading(false);
        Modal.success({ title: 'Remove student success! ' });
        setInitForm({});
        loadStudents();
      }
    },
    onError: () => {
      setPageLoading(false);
      Modal.error({ title: 'Lỗi', content: 'Remove student fail' });
    },
  });

  useEffect(() => {
    form.setFieldsValue({
      fullname: initForm.fullname,
      email: initForm.email,
      phone: initForm.phone,
      parentPhone: initForm.parentPhone,
      parentName: initForm.parentName,
      uid: initForm.uid,
      agencyId: initForm.agencyId,
      classes: initForm?.studentInClasses
        ?.filter((o) => o?.status === 'isActive')
        ?.map((o) => o?.classId),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initForm, resetForm]);

  useEffect(() => {
    if (data?.students) {
      setStudents(data?.students);
    }
  }, [data]);

  if (error) {
    if (error?.message?.includes('Failed to fetch')) {
      Modal.error({ title: 'Lỗi', content: 'Lỗi kết nối server' });
    } else {
      Modal.error({ title: 'Lỗi', content: error.message });
    }
  }

  const handleAddNew = () => {
    setInitForm({});
    form.resetFields();
    setIsShowModalAddNew(true);
    setFilterAgencyId(null);
  };

  const closeModal = () => {
    setIsShowModalAddNew(false);
    setResetForm(Date.now());
  };

  const handleSaveUser = () => {
    setIsShowModalAddNew(false);
  };

  const hanleDeleteUser = (userId) => {
    Modal.confirm({
      title: 'Delete student?',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure delete this student?',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: () => {
        removeStudent({
          variables: {
            userId,
          },
        });
      },
    });
  };

  const handleUpdateUser = (user) => {
    setInitForm(user);
    setFileAvatar('');
    setIsShowModalAddNew(true);
    setFilterAgencyId(user?.agencyId);
  };

  const handleResetPassword = (user) => {
    Modal.confirm({
      title: 'Are you sure reset password this student?',
      icon: <ExclamationCircleOutlined />,
      content: "The new password is the student's phone number",
      okText: 'Ok',
      cancelText: 'Cancel',
      onOk: () => {
        resetPassword({
          variables: {
            userId: user.userId,
          },
        });
      },
    });
  };

  const onFinish = async (values) => {
    // Upload file
    let userAvatarUrl = initForm.avatar;
    if (fileAvatar) {
      const createResourceDt = await createResource({
        variables: {
          file: fileAvatar,
        },
      });
      userAvatarUrl = createResourceDt?.data?.createResource?.url;
    }
    userAvatarUrl = userAvatarUrl ?? '';
    setPageLoading(true);
    setIsShowModalAddNew(false);
    if (initForm.userId) {
      updateStudent({
        variables: {
          updateStudentInput: {
            ...values,
            userId: initForm.userId,
            avatar: userAvatarUrl,
            classes: values?.classes?.length ? values.classes : ['delete'],
          },
        },
      });
    } else {
      createStudent({
        variables: {
          createStudentInput: {
            ...values,
            avatar: userAvatarUrl,
            classes: values?.classes?.length ? values.classes : ['delete'],
          },
        },
      });
    }
  };

  const onFinishFailed = () => {
    return;
  };

  const changeAvatar = () => {
    avatarRef.current.click();
  };
  const handleChangeImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      setFileAvatar(event.target.files[0]);
    }
  };

  const renderAvatar = () => {
    if (fileAvatar) return URL.createObjectURL(fileAvatar);
    if (initForm.avatar) return initForm.avatar;
    return '';
  };
  const onSearch = (value) => {
    setSearchParams({
      search: value ?? '',
    });
  };

  useEffect(() => {
    const take = searchParams.get('take');
    const skip = searchParams.get('skip');
    const search = searchParams.get('search');
    loadStudents({
      variables: {
        take: take ? Number(take) : null,
        skip: skip ? Number(skip) : null,
        search: search ?? '',
      },
    });
  }, [searchParams]);
  const getDefaultValue = () => {
    const search = searchParams.get('search');
    if (search === 'null') return '';
    return search ?? '';
  };

  const getClasses = (classData) => {
    return (classData?.classes ?? [])
      .filter((o) => o.agencyId === filterAgencyId)
      .map((o) => ({ label: o.name, value: o.classId }));
  };

  const SearchComponent = () => {
    const recentSearchValue = StorageHelper.getRecentSearch(PagingType.student);
    return (
      <div>
        <div>
          <Input.Search
            placeholder="Search text"
            onSearch={onSearch}
            style={{ width: 200 }}
            defaultValue={getDefaultValue()}
          />
        </div>
        {recentSearchValue && getDefaultValue() === '' && (
          <div>
            Từ khoá tìm kiếm gần đây:
            <p>{recentSearchValue}</p>
          </div>
        )}
      </div>
    );
  };

  return (
    <Spin tip="Loading..." spinning={pageLoading || loading}>
      <div className="post">
        <div className="breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>Student</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="body">
          <Space className="add-new">
            {auth?.user?.role !== UserRoleConstant.TEACHER && (
              <>
                <Button
                  type="primary"
                  icon={<PlusOutlined style={{ verticalAlign: 'text-top' }} />}
                  onClick={handleAddNew}
                >
                  Add New
                </Button>
                <Button
                  type="primary"
                  icon={
                    <ImportOutlined style={{ verticalAlign: 'text-top' }} />
                  }
                  onClick={() => {
                    setIsShowModalImport(true);
                  }}
                >
                  Import
                </Button>
              </>
            )}
            <Button
              type="primary"
              icon={<ReloadOutlined style={{ verticalAlign: 'text-top' }} />}
              onClick={() => {
                loadStudents();
              }}
            >
              Reload
            </Button>
          </Space>
          <div className="add-new">
            <SearchComponent />
          </div>
          <div className="table">
            <StudentTableComponent
              loadStudents={loadStudents}
              total={data?.total}
              students={students}
              handleResetPassword={handleResetPassword}
              hanleDeleteUser={hanleDeleteUser}
              handleUpdateUser={handleUpdateUser}
            />
          </div>
        </div>
        <Modal
          forceRender
          title={initForm.userId ? 'Update' : 'Add new'}
          visible={isShowModalAddNew}
          onOk={handleSaveUser}
          onCancel={closeModal}
          bodyStyle={{
            height: 500,
            overflow: 'auto',
          }}
          footer={null}
        >
          <div className="avatar_form">
            <div className="avatar_form__item">
              <Avatar src={renderAvatar()} icon={<UserOutlined />} size={100} />
              <div className="avatar_form__button">
                <Button onClick={changeAvatar}>Change Avatar</Button>
                <input
                  type="file"
                  ref={avatarRef}
                  id="cover"
                  name="cover"
                  accept="image/png, image/jpeg"
                  style={{ display: 'none' }}
                  onChange={handleChangeImage}
                ></input>
              </div>
            </div>
          </div>
          <Form
            name="basic"
            form={form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 24 }}
            initialValues={initForm}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            autoComplete="off"
          >
            <Form.Item
              label="Full name"
              name="fullname"
              rules={[{ required: true, message: 'Please input full name!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Email" name="email">
              <Input />
            </Form.Item>
            <Form.Item
              label="Student code"
              name="uid"
              rules={[
                { required: true, message: 'Please input student code!' },
                {
                  validator: (rule, value, cb) => {
                    if (initForm.uid !== value) {
                      checkExistUid({
                        variables: {
                          uid: value,
                        },
                      }).then((isExist) => {
                        isExist?.data?.checkExistUid
                          ? cb('Code is existed')
                          : cb();
                      });
                    } else {
                      cb();
                    }
                  },
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Phone"
              name="phone"
              rules={[{ required: true, message: 'Please input phone!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Parent name" name="parentName">
              <Input />
            </Form.Item>
            <Form.Item label="Parent phone" name="parentPhone">
              <Input />
            </Form.Item>
            <Form.Item
              label="Agency"
              name="agencyId"
              rules={[{ required: true, message: 'Please select agency!' }]}
            >
              <Select
                onChange={(value) => {
                  setFilterAgencyId(value);
                }}
              >
                {agencies?.agencies.map((o) => (
                  <Select.Option value={o.agencyId}>{o.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            {auth?.user?.role === UserRoleConstant.ADMIN && (
              <Form.Item label="Classes" name="classes">
                <Select
                  disabled={!filterAgencyId}
                  mode="multiple"
                  options={getClasses(classData)}
                ></Select>
              </Form.Item>
            )}
            <Form.Item wrapperCol={{ offset: 20, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <ImportModal
          title={'Import Student'}
          visible={isShowModalImport}
          onCancel={() => setIsShowModalImport(false)}
          importType={ImportType.StudentImport}
          callback={() => {
            setIsShowModalImport(false);
            loadStudents();
          }}
        />
      </div>
    </Spin>
  );
};

Student.propTypes = {};

export default Student;

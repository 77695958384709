import React, { useState } from 'react'
import { Table, Modal, Button, Form, Select, message } from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import { SMART_QUESTION_BY_CLASS, REMOVE_SMART_QUESTION_BY_CLASS, ADD_SMART_QUESTION } from '../../graphql/class';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import * as moment from 'moment';
import { SMART_QUESTIONS } from '../../graphql/smartQuestion';

const BodySmartQuestionComponent = ({ classId }) => {
  const [form2] = Form.useForm();

  // eslint-disable-next-line no-unused-vars
  const [initForm2, setInitForm2] = useState({});
  const [showModalAddSmartQuestion, setShowModalAddSmartQuestion] = useState(false);
  const { data: smartQuestionQuery } = useQuery(SMART_QUESTIONS);
  const [addSmartQuestionToClass] = useMutation(ADD_SMART_QUESTION, {
    refetchQueries: [{ query: SMART_QUESTION_BY_CLASS, variables: { classId } }],
    onCompleted: (response) => {
      message.success('Success');
      setShowModalAddSmartQuestion(false);
    }
  })

  const { data } = useQuery(SMART_QUESTION_BY_CLASS, {
    variables: {
      classId,
    },
  });

  const addSmartQuestion = (values) => {
    addSmartQuestionToClass({
      variables: {
        smartQuestions: values.smartQuestionIds,
        classId
      }
    });
    form2.setFieldsValue({
      smartQuestionIds: []
    });
  }
  
  const [removeSmartQuestionsFromClass] = useMutation(REMOVE_SMART_QUESTION_BY_CLASS, {
    refetchQueries: [{ query: SMART_QUESTION_BY_CLASS, variables: { classId } }],
    onCompleted: (response) => {
      if (response && response.removeSmartQuestionsFromClass) {
        Modal.success({ title: 'Remove success! ' });
      }
    },
    onError: (_createError) => {
      Modal.error({ message: 'Remove fail' });
    },
  });
  const hanleDeleteSmartQuestion = (id, classId) => {
    Modal.confirm({
      title: 'Delete smart question?',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure delete this smart question?',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: () => {
        removeSmartQuestionsFromClass({
          variables: {
            smartQuestionId: id,
            classId: classId
          },
        });
      },
    });
  };
  const columns = [
    {
      title: 'No',
      key: 'index',
      render: (_value, _item, index) => index + 1,
    },
    {
      title: 'Content En',
      dataIndex: 'contentEn',
      key: 'contentEn',
    },
    {
      title: 'Answer En',
      dataIndex: 'answerEn',
      key: 'answerEn',
    },
    {
      title: 'Content Vi',
      dataIndex: 'contentVi',
      key: 'contentVi',
    },
    {
      title: 'Answer Vi',
      dataIndex: 'answerVi',
      key: 'answerVi',
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at) => moment(created_at).format('DD/MM/YYYY hh:mm:ss'),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_value, item) => {
        return (
          <DeleteOutlined
            onClick={() => hanleDeleteSmartQuestion(item.smartQuestionId, classId)}
          />
        );
      },
    },
  ];

  const handleAddSmartQuestion = () => {
    setShowModalAddSmartQuestion(true);
  }

  return (
    <div className="table">
      <div className='addSmartQuestionContainer'>
        <Button
          type='primary'
          className='addSmartQuestion'
          onClick={handleAddSmartQuestion}
        >
          Add smart question
        </Button>
      </div>
      <Table
        dataSource={data?.smartQuestion?.map((item) => ({
          ...item,
          key: item.smartQuestionId,
        }))}
        columns={columns}
      />
      <Modal
        forceRender
        title="Smart question"
        visible={showModalAddSmartQuestion}
        onOk={() => setShowModalAddSmartQuestion(false)}
        onCancel={() => setShowModalAddSmartQuestion(false)}
        bodyStyle={{
          height: 'auto',
          overflow: 'auto'
        }}
        footer={null}
      >
        <Form
          name="basic"
          form={form2}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 24 }}
          initialValues={initForm2}
          onFinish={addSmartQuestion}
          layout='vertical'
          autoComplete="off"
        >
          <Form.Item
            label="Smart question"
            name="smartQuestionIds"
            rules={[{ required: true, message: 'Missing question' }]}
          >
            <Select
              mode="multiple"
            >
              {
                (smartQuestionQuery?.smartQuestions
                  ? smartQuestionQuery.smartQuestions
                  :
                  []
                ).map(smartQuestion => {
                  if (data?.smartQuestion.findIndex(o => o.smartQuestionId === smartQuestion.smartQuestionId) > -1) {
                    return <></>
                  }
                  return (
                    <Select.Option
                      key={smartQuestion.smartQuestionId}
                      value={smartQuestion.smartQuestionId}
                    >
                      {smartQuestion.contentEn}
                    </Select.Option>
                  )
                })
              }
            </Select>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 20, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Add
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default BodySmartQuestionComponent
import React, { Suspense } from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';

import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { setContext } from '@apollo/client/link/context';

import './styles/main.scss';
import './App.css';

import Loading from './components/Loading';
import ProtectedRoute from './components/ProtectedRoute';
import ProtectedAgencyAdminRoute from './components/ProtectedAgencyAdminRoute';
import Page404 from './pages/404';

import AuthProvider from './contexts/auth';
import Login from './pages/Login';
import Teacher from './pages/Teachers';
import Student from './pages/Student';
import Class from './pages/class/Class';
import News from './pages/news/News';
import CreateNews from './pages/news/CreateNews';
import UpdateNews from './pages/news/UpdateNews';
import Profile from './pages/Profile';
import Feedback from './pages/Feedback';
import ClassExperiencePage from './pages/class/detail/experience/ClassExperiencePage';
import ClassDetailPage from './pages/class/detail/ClassDetailPage';
import Programs from './pages/template/Programs';
import Levels from './pages/template/Levels';
import Lessons from './pages/template/Lessons';
import SmartQuestion from './pages/SmartQuestion';
import ClassDetailExperiencePage from './pages/class/detail/experience/ClassDetail-ExperiencePage';
import StudentDetail from './pages/StudentDetail';
import Forbidden from './pages/Forbidden';
import ProtectedTeacherRoute from './components/ProtectedTeacherRoute';
import StudyProgram from './pages/study-program';
import CreateNewStudyProgram from './pages/study-program/new';
import EditNewStudyProgram from './pages/study-program/edit';
import Agency from './pages/agency';
import AgencyDetail from './pages/agency/detail';
import Statistic from './pages/statistics';
import StudentReport from './pages/student-report';
import Maintenance from './pages/Maintenance';

const httpLink = createUploadLink({
  uri: (process.env.REACT_APP_API_HOST ?? 'http://localhost:3000/') + 'graphql',
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const App = () => {
  if (process.env.REACT_APP_MAINTENANCE) {
    return <Maintenance></Maintenance>;
  }
  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <BrowserRouter>
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route
                path="/"
                element={
                  <ProtectedTeacherRoute>
                    <Student />
                  </ProtectedTeacherRoute>
                }
              />
              <Route
                path="/programs"
                element={
                  <ProtectedRoute>
                    <Programs />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/program"
                element={
                  <ProtectedRoute>
                    <Programs />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/levels"
                element={
                  <ProtectedRoute>
                    <Levels />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/lessons"
                element={
                  <ProtectedRoute>
                    <Lessons />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/student-report"
                element={
                  <ProtectedTeacherRoute>
                    <StudentReport />
                  </ProtectedTeacherRoute>
                }
              />
              <Route
                path="/statistics"
                element={
                  <ProtectedAgencyAdminRoute>
                    <Statistic />
                  </ProtectedAgencyAdminRoute>
                }
              />
              <Route
                path="/teachers"
                element={
                  <ProtectedAgencyAdminRoute>
                    <Teacher />
                  </ProtectedAgencyAdminRoute>
                }
              />
              <Route
                path="/student"
                element={
                  <ProtectedTeacherRoute>
                    <Student />
                  </ProtectedTeacherRoute>
                }
              />
              <Route
                path="/student/:studentId"
                element={
                  <ProtectedTeacherRoute>
                    <StudentDetail />
                  </ProtectedTeacherRoute>
                }
              />
              <Route
                path="/smart-questions"
                element={
                  <ProtectedAgencyAdminRoute>
                    <SmartQuestion />
                  </ProtectedAgencyAdminRoute>
                }
              />
              <Route
                path="/class"
                element={
                  <ProtectedTeacherRoute>
                    <Class />
                  </ProtectedTeacherRoute>
                }
              />
              <Route
                path="/class-detail"
                element={
                  <ProtectedTeacherRoute>
                    <ClassDetailPage />
                  </ProtectedTeacherRoute>
                }
              />
              <Route
                path="/class-experience"
                element={
                  <ProtectedTeacherRoute>
                    <ClassExperiencePage />
                  </ProtectedTeacherRoute>
                }
              />
              <Route
                path="/class-experience-detail"
                element={
                  <ProtectedTeacherRoute>
                    <ClassDetailExperiencePage />
                  </ProtectedTeacherRoute>
                }
              />
              <Route
                path="/agencies"
                element={
                  <ProtectedAgencyAdminRoute>
                    <Agency />
                  </ProtectedAgencyAdminRoute>
                }
              />
              <Route
                path="/agencies/:agencyId"
                element={
                  <ProtectedAgencyAdminRoute>
                    <AgencyDetail />
                  </ProtectedAgencyAdminRoute>
                }
              />
              <Route
                path="/news"
                element={
                  <ProtectedAgencyAdminRoute>
                    <News />
                  </ProtectedAgencyAdminRoute>
                }
              />
              <Route
                path="/news/create"
                element={
                  <ProtectedRoute>
                    <CreateNews />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/news/:id/update"
                element={
                  <ProtectedRoute>
                    <UpdateNews />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/profile"
                element={
                  <ProtectedTeacherRoute>
                    <Profile />
                  </ProtectedTeacherRoute>
                }
              />
              <Route
                path="/feedbacks"
                element={
                  <ProtectedAgencyAdminRoute>
                    <Feedback />
                  </ProtectedAgencyAdminRoute>
                }
              />
              <Route
                path="/study-program"
                element={
                  <ProtectedAgencyAdminRoute>
                    <StudyProgram />
                  </ProtectedAgencyAdminRoute>
                }
              />
              <Route
                path="/study-program/create"
                element={
                  <ProtectedRoute>
                    <CreateNewStudyProgram />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/study-program/edit/:id"
                element={
                  <ProtectedRoute>
                    <EditNewStudyProgram />
                  </ProtectedRoute>
                }
              />
              <Route path="/Forbidden" element={<Forbidden />} />
              <Route path="/login" element={<Login />} />
              <Route path="/:404" element={<Page404 />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </AuthProvider>
    </ApolloProvider>
  );
};

export default App;

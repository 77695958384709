import gql from 'graphql-tag';

export const LIST_PROGRAM = gql`
  query programs {
    programs: programs_findAll {
      programId
      programName
      description
      status
    }
  }
`;

export const PROGRAM = gql`
  query program($programId: String!) {
    program: programs_findOne(programId: $programId) {
      programId
      programName
      description
      levels {
        levelId
        levelName
        description
        duration
        dayPerWeek
        lessons {
          lessonId
          levelName
          lessonName
          fullName
          priority
          target
          unit
        }
      }
    }
  }
`;

import gql from 'graphql-tag';

export const STUDENTS_ON_CLASS = gql`
  query students_findByClassId($classId: String!) {
    students: students_findByClassId(classId: $classId) {
      userId
      fullname
      avatar
      phone
      uid
      email
      created_at
      start_time
      end_time
      classStartAt
      classEndAt
      status
      commentOfTeachers {
        commentId
        targetContent
        targetSubject
        content
        subject
      }
    }
  }
`;

export const UPDATE_JOIN_TIME_CLASS = gql`
  mutation students_updateJoinTimeClass(
    $classId: String!
    $studentId: String!
    $start_time: DateTime!
    $end_time: DateTime!
  ) {
    students_updateJoinTimeClass(
      classId: $classId
      studentId: $studentId
      start_time: $start_time
      end_time: $end_time
    )
  }
`;

import React, { useContext, useRef, useState } from 'react';

import { Avatar, Button, Form, Input, message, Modal } from 'antd';
import {
  UserOutlined,
} from '@ant-design/icons';

import { AuthContext } from '../contexts/auth';
import { UPDATE_PASSWORD } from '../graphql/users';
import { useMutation } from '@apollo/client';

const Profile = () => {
  const auth = useContext(AuthContext);
  const avatarRef = useRef(null);
  const [form] = Form.useForm();
  const [updatePassword] = useMutation(UPDATE_PASSWORD, {
    onCompleted: () => {
      message.success('Success!');
    },
    onError: () => {
      message.error('Wrong password!')
    }
  });

  const [showModalChangePassword, setShowModalChangePassword] = useState(false);

  const changeAvatar = () => {
    avatarRef.current.click();
  };

  const handleChangeImage = event => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      let formData = new FormData();
      formData.append('file', img);
    }
  };

  const handleChangePassword = () => {
    setShowModalChangePassword(true);
  }

  const onFinish = (values) => {
    if (values.newPassword !== values.confirmPassword) {
      message.error('New password and confirm password does not match!');
      return;
    }
    setShowModalChangePassword(false);
    updatePassword({
      variables: {
        changePasswordInput: {
          currentPassword: values.currentPassword,
          newPassword: values.newPassword,
        }
      }
    })
  }

  return <div className='profile'>
    <div className='center_horizontal vertical'>
      <div className='change_avatar'>
        {
          auth.user.avatar
            ?
            <Avatar size={100} className={'avatar'} src={auth.user.avatar} />
            :
            <Avatar size={100} className={'avatar'} icon={<UserOutlined />} />
        }
        <Button onClick={changeAvatar}>Change Avatar</Button>
        <input type="file"
          ref={avatarRef}
          id="cover" name="cover"
          accept="image/png, image/jpeg"
          style={{ display: 'none' }}
          onChange={handleChangeImage}></input>
      </div>

      <div className='info_line'>
        <div className='title'>Full name:</div>
        <div className='value'>{auth.user.fullname}</div>
      </div>

      <div className='info_line'>
        <div className='title'>Email:</div>
        <div className='value'>{auth.user.email}</div>
      </div>
      <div className='change_password'>
        <Button
          onClick={handleChangePassword}
        >
          Change password
        </Button>
      </div>
    </div>
    <Modal
      title="Change password"
      visible={showModalChangePassword}
      onOk={() => form.submit()}
      onCancel={() => setShowModalChangePassword(false)}
    >
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Current Password"
          name="currentPassword"
          rules={[{ required: true, message: 'Please input your current password!' }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="New Password"
          name="newPassword"
          rules={[{ required: true, message: 'Please input your new password!' }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Confirm Password"
          name="confirmPassword"
          rules={[{ required: true, message: 'Please input your confirm password!' }]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  </div>;
};

Profile.propTypes = {};

export default Profile;

import React, { useState, useEffect } from 'react';
import * as moment from 'moment';
import { useQuery, useLazyQuery } from '@apollo/client';
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import { GET_NUMBER_USER_USED_APP, GET_NUMBER_USER_USED_APP_BY_USERID } from '../../graphql/statistics';
import { STUDENTS } from '../../graphql/student';
import { Card, Select, Spin, } from "antd";
import { useContext } from 'react';
import { AuthContext } from '../../contexts/auth';
import { UserRoleConstant } from '../../constants';
import AgencyFilter from '../../components/filters/AgencyFilter';

const from = moment().subtract(30, 'days').toDate();
const to = moment().endOf('date').toDate();

const Statistic = () => {
  Highcharts.setOptions({
    chart: {
      // spacing: [20, 20, 20, 20],
      margin: [80, 80, 80, 80],
    },
    time: {
      timezone: 'Europe/Paris'
    }
  });

  const { user } = useContext(AuthContext);
  const [selectedAgencies, setSelectedAgencies] = useState([]);
  const [chartOptions, setChartOptions] = useState();
  const [studentId, setStudentId] = useState(null);
  const [chartOptionsByUser, setChartOptionsByUser] = useState();

  const [getNumberUserUsedApp, { data }] = useLazyQuery(GET_NUMBER_USER_USED_APP, {
    fetchPolicy: 'no-cache',
  });
  const [getNumberUsedAppByUserId, { data: numberUsedAppByUser }] = useLazyQuery(GET_NUMBER_USER_USED_APP_BY_USERID, {
    fetchPolicy: 'no-cache',
  });
  const { data: studentData } = useQuery(STUDENTS);

  const setStudentIdDefault = (selectedAgenciesInput) => {
    let studentByAgencies = (studentData?.students ?? []).filter((student) =>
      user?.role === UserRoleConstant.ADMIN ?
        (selectedAgenciesInput ?? selectedAgencies).includes(student?.agencyId)
        : true);
    if (studentByAgencies?.length > 0) {
      setStudentId(studentByAgencies[0].userId);
    }
    else {
      setStudentId(null);
    }
  };

  useEffect(() => {
    if (data?.getNumberUsedApp?.data) {
      setChartOptions({
        title: {
          text: 'Number used app'
        },
        series: [{
          name: 'Used app',
          data: data.getNumberUsedApp.data,
          type: 'line',
        }],
        tooltip: {
          formatter: function () {
            return `${moment(new Date(this.x)).format('DD/MM/YYYY')} <br/> <b>used ${this.y}</b>`;
          }
        },
        xAxis: {
          labels: {
            formatter: function () {
              return moment(new Date(this.value)).format('DD/MM/YYYY');
            },
          }
        },
      });
    }
  }, [data]);

  useEffect(() => {
    // if (numberUsedAppByUser?.getNumberUsedAppByUserId?.data) {
    console.log(new Date().toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'long' }).substring(4));

    setChartOptionsByUser({
      title: {
        text: 'Number used app by user'
      },
      series: [{
        name: 'Used app',
        data: numberUsedAppByUser?.getNumberUsedAppByUserId?.data ?? [],
        type: 'line',
      }],
      tooltip: {
        formatter: function () {
          return `${moment(new Date(this.x)).format('DD/MM/YYYY')} <br/> <b>used ${this.y}</b>`;
        }
      },
      xAxis: {
        labels: {
          formatter: function () {
            return moment(new Date(this.value)).format('DD/MM/YYYY');
          },
        }
      },
    });
    // }
  }, [numberUsedAppByUser]);

  useEffect(() => {
    setStudentIdDefault();
  }, [studentData]);

  useEffect(() => {
    getNumberUserUsedApp({
      variables: {
        from, to,
        agencies: selectedAgencies,
      }
    });
  }, [selectedAgencies, getNumberUserUsedApp]);

  useEffect(() => {
    // if (studentId) {
    getNumberUsedAppByUserId({
      variables: {
        from, to,
        userId: studentId,
      }
    });
    // }
  }, [studentId, getNumberUsedAppByUserId]);

  return <Spin spinning={false}>
    <div className='uppercase font-bold text-2xl flex justify-center mt-8'>
      Statistic
    </div>
    {user?.role === UserRoleConstant.ADMIN &&
      <Card className='w-[90%] flex justify-start mt-10 mx-auto'>
        <AgencyFilter onSelect={(selecteds) => {
          setSelectedAgencies(selecteds);
          setStudentIdDefault(selecteds);
        }} />
      </Card>
    }
    <div className='flex justify-center mt-10'>
      {
        chartOptions
        &&
        <div className='w-[90%]'>
          <HighchartsReact
            highcharts={Highcharts}
            constructorType={'chart'}
            options={chartOptions}
          />
        </div>
      }
    </div>
    <div>
      <div className='flex justify-end mr-[5%] mt-10'>
        <Select
          className='w-48'
          showSearch
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input?.toLowerCase())}
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          value={studentId}
          onChange={(value) => {
            setStudentId(value);
          }}
          options={
            (studentData?.students ?? [])
              .filter((student) =>
                user?.role === UserRoleConstant.ADMIN ?
                  selectedAgencies.includes(student?.agencyId)
                  : true)
              .map(student => ({
                value: student.userId,
                label: student.fullname,
              }))
          }
        />
      </div>
      <div className='flex justify-center mt-5'>
        {
          chartOptionsByUser
          &&
          <div className='w-[90%]'>
            <HighchartsReact
              highcharts={Highcharts}
              constructorType={'chart'}
              options={chartOptionsByUser}
            />
          </div>
        }
      </div>
    </div>
  </Spin>
};

Statistic.propTypes = {};

export default Statistic;

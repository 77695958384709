import { useContext } from 'react';
import {
  Navigate,
} from 'react-router-dom';
import { AuthContext } from '../contexts/auth';
import Layout from './LayoutPage';

const ProtectedTeacherRoute = ({ children }) => {
  const auth = useContext(AuthContext);

  const { token } = auth;
  if (!token) {
    return <Navigate to="/login" replace />;
  }

  return  <Layout>
      {children}
    </Layout>
};

export default ProtectedTeacherRoute;
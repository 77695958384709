import gql from 'graphql-tag';


export const LOGIN = gql`
	query authenticate(
		$input: AuthenticateInput!
	) {
		authenticate: auth_authenticate(
			input: $input
		) {
			email
			fullname
			userId
			accessToken
			role
		}
	}
`;

export const RESET_PASSWORD = gql`
	query resetPassword(
		$userId: String!
	) {
		resetPassword: resetPassword(
			userId: $userId
		)
	}
`;


export const GET_PROFILE = gql`
	query users_getProfile{
		getProfile: users_getProfile{
			userId
			email
			fullname
			userId
			phone
			role
		}
	}
`;


export const CREATE_ADMIN_AGENCY = gql`
	mutation createAgencyAdmin($createAgencyAdminInput: CreateAgencyAdminInput!) {
		createAgencyAdmin(createAgencyAdminInput: $createAgencyAdminInput) {
			userId
		}
	}
`;

export const REMOVE_ADMIN_AGENCY = gql`
	mutation removeAgencyAdmin($userId: String!) {
		removeAgencyAdmin(userId: $userId)
	}
`;

export const UPDATE_ADMIN_AGENCY = gql`
	mutation updateAgencyAdmin($updateAgencyAdminInput: UpdateAgencyAdminInput!) {
		updateAgencyAdmin(updateAgencyAdminInput: $updateAgencyAdminInput)
	}
`;

export const LIST_ADMIN_AGENCIES = gql`
	query findAllAgencyAdmin($agencyId: String!) {
		findAllAgencyAdmin(agencyId: $agencyId) {
			userId
			email
			avatar
			fullname
			userId
			phone
			created_at
		}
	}
`;

export const FORGOT_PASSWORD = gql`
	mutation forgotPassword($email: String!){
		forgotPassword: auth_forgotPassword(email: $email)
	}
`;


export const UPDATE_PASSWORD = gql`
	mutation changePasswordInput($changePasswordInput: ChangePasswordInput!){
		changePassword: auth_changePassword(changePasswordInput: $changePasswordInput)
	}
`;


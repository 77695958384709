import React, { useEffect, useState } from 'react';

import { Divider, Table } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { orderBy } from 'lodash';

import { useQuery } from '@apollo/client';
import { SCORE_BOARD_CLASS } from '../../../../graphql/class';

const ScoreBoard = ({
  classId
}) => {
  const [tableData, setTableData] = useState([]);


  const { data: scoreboardResponses } = useQuery(SCORE_BOARD_CLASS, { variables: { classId: classId } });

  useEffect(() => {
    if (scoreboardResponses?.scoreBoards) {
      const groupByUser = scoreboardResponses.scoreBoards.reduce((group, scoreBoard) => {
        const { userId } = scoreBoard;
        group[userId] = group[userId] ?? [];
        group[userId].push(scoreBoard);
        return group;
      }, {});

      console.log('groupByUser ', groupByUser);

      const userIds = Object.keys(groupByUser);

      const tableDt = userIds.map(userId => {
        const datas = orderBy(groupByUser[userId], o => o.created_at, 'desc');
        let previousScore, nearestScore, avgPre, avgNearest;
        if (datas.length >= 2) {
          previousScore = datas[1].scores;
          nearestScore = datas[0].scores;
        }
        else if (datas.length > 0) {
          previousScore = datas[0].scores;
          nearestScore = datas[0].scores;
        }
        else {
          previousScore = [];
          nearestScore = [];
        }

        if (previousScore) {
          avgPre = parseFloat(previousScore.reduce((a, b) => a + b.score, 0) / previousScore.length).toFixed(2);
          avgNearest = parseFloat(nearestScore.reduce((a, b) => a + b.score, 0) / nearestScore.length).toFixed(2);
        }

        return {
          ...datas[0],
          nearestScore,
          previousScore,
          avgPre,
          avgNearest
        };
      });

      console.log('tableDt ', tableDt);

      setTableData(tableDt);
    }

  }, [scoreboardResponses])

  const handleUpdate = (scoreboard) => {
  }

  const handleRemove = (scoreboard) => {

  }


  const columns = [
    {
      title: 'Student name',
      dataIndex: ['user', 'fullname'],
      key: 'fullname'
    },
    {
      title: 'Nearest scores',
      dataIndex: 'nearestScore',
      key: 'content',
      render: (value, row) => {
        let color = '#FF3030';
        if (parseFloat(row?.avgNearest ?? 0) > parseFloat(row?.avgPre ?? 0)) {
          color = '#54FF9F';
        } else if (parseFloat(row?.avgNearest ?? 0) === parseFloat(row?.avgPre ?? 0)) {
          color = '#00EEEE';
        }
        return <div style={{ color }}>
          {
            value.map(dt => (
              <div key={dt.subject}>
                <div>Subject: {dt.subject}</div>
                <div>Score: {dt.score}</div>
                <Divider dashed style={{ margin: 0 }} />
              </div>
            ))
          }
          Average: {parseFloat(value.reduce((a, b) => a + b.score, 0) / value.length).toFixed(2)}
        </div>
      }
    },
    {
      title: 'Previous scores',
      dataIndex: 'previousScore',
      key: 'content',
      render: (value, row) => {
        return <div>
          {
            value.map(dt => (
              <div key={dt.subject}>
                <div>Subject: {dt.subject}</div>
                <div>Score: {dt.score}</div>
                <Divider dashed style={{ margin: 0 }} />
              </div>
            ))
          }
          Average: {parseFloat(value.reduce((a, b) => a + b.score, 0) / value.length).toFixed(2)}
        </div>
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_value, item) => {
        return <>
          <EditOutlined onClick={() => handleUpdate(item)} className='mr10' />
          <DeleteOutlined onClick={() => handleRemove(item)} />
        </>;
      },
    },
  ];

  return <div>
    <div className='score-container'>
      <h2>
        Scores
      </h2>
    </div>
    <div>
      <Table dataSource={tableData ?? []} columns={columns} />
    </div>
  </div>
};

ScoreBoard.propTypes = {};

export default ScoreBoard;

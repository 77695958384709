import gql from 'graphql-tag';


export const LIST_AGENCY = gql`
   query agency {
      agencies: findAllAgencies {
         agencyId
         name
         description
         created_at
      }
   }
`;

export const CREATE_AGENCY = gql`
   mutation createAgency($createAgencyInput: CreateAgencyInput!) {
      createAgency(createAgencyInput: $createAgencyInput)
   }
`;

export const UPDATE_AGENCY = gql`
   mutation updateAgency($updateAgencyInput: UpdateAgencyInput!) {
      updateAgency(updateAgencyInput: $updateAgencyInput)
   }
`;

export const REMOVE_AGENCY = gql`
   mutation deleteAgency($id: String!) {
      deleteAgency(id: $id)
   }
`;
import { Table } from 'antd';
import React, { useContext } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { UserRoleConstant } from '../../constants';
import * as moment from 'moment/moment';
import { DeleteOutlined, EditOutlined, KeyOutlined } from '@ant-design/icons';
import { AuthContext } from '../../contexts/auth';

const defineColumns = (
  auth,
  handleResetPassword,
  handleUpdateUser,
  hanleDeleteUser,
) => {
  return [
    {
      title: 'No',
      key: 'index',
      render: (_value, _item, index) => index + 1,
    },
    {
      title: 'Full name',
      dataIndex: 'fullname',
      key: 'fullname',
      render: (value, item) => {
        return (
          <>
            <Link to={`/student/${item.userId}`} className="mr10">
              {value}
            </Link>
          </>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Student code',
      dataIndex: 'uid',
      key: 'uid',
    },
    ...(auth?.user?.role === UserRoleConstant.TEACHER
      ? [{}]
      : [
          {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
          },
        ]),
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at) => moment(created_at).format('DD/MM/YYYY hh:mm:ss'),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_value, item) => {
        return (
          <>
            <KeyOutlined
              onClick={() => handleResetPassword(item)}
              className="mr10"
            />
            {auth?.user?.role !== UserRoleConstant.TEACHER && (
              <>
                <EditOutlined
                  onClick={() => handleUpdateUser(item)}
                  className="mr10"
                />
                <DeleteOutlined onClick={() => hanleDeleteUser(item.userId)} />
              </>
            )}
          </>
        );
      },
    },
  ];
};

export default function StudentTableComponent({
  total,
  students,
  loadStudents,
  handleResetPassword,
  handleUpdateUser,
  hanleDeleteUser,
}) {
  let [searchParams, setSearchParams] = useSearchParams();

  const auth = useContext(AuthContext);
  const columns = defineColumns(
    auth,
    handleResetPassword,
    handleUpdateUser,
    hanleDeleteUser,
  );
  const skip = Number(searchParams.get('skip'));
  const search = searchParams.get('search');

  return (
    <Table
      dataSource={students || []}
      columns={columns}
      pagination={{
        current: !isNaN(skip) ? skip + 1 : 0,
        total: total ?? 0,
        onChange: (page, pageSize) => {
          setSearchParams({
            skip: page - 1,
            take: pageSize,
            search: search ?? '',
          });
          loadStudents({
            variables: {
              skip: page - 1,
              take: pageSize,
              search: search ?? '',
            },
          });
        },
      }}
    />
  );
}

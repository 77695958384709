import gql from 'graphql-tag';

export const CREATE_NEWS = gql`
	mutation createNews($createNewsInput: CreateNewsInput!) {
		createNews(createNewsInput: $createNewsInput) {
      description
      image
      category
      newsId
      shortDescription
      status
      title
    }
	}
`;

export const NEWS = gql`
	query news($filter: FilterInput!) {
		news: news_findAll(filter: $filter) {
      description
      image
      newsId
      category
      shortDescription
      status
      title
      created_at
      createdBy {
        fullname
      }
		}
	}
`;

export const NEWS_BY_ID = gql`
	query newsById($newsId: String!) {
		newsById: news_findOne(newsId: $newsId) {
      description
      image
      newsId
      shortDescription
      status
      category
      title
      created_at
      createdBy {
        fullname
      }
		}
	}
`;

export const UPDATE_NEW = gql`
	mutation updateNews($updateNewsInput: UpdateNewsInput!) {
		updateNews(updateNewsInput: $updateNewsInput) {
      description
      image
      newsId
      shortDescription
      status
      category
      title
      created_at
      createdBy {
        fullname
      }
		}
	}
`;

export const REMOVE_NEWS = gql`
	mutation removeNews($newsId: String!) {
		removeNews(newsId: $newsId) {
      description
      image
      newsId
      shortDescription
      status
      category
      title
      created_at
      createdBy {
        fullname
      }
		}
	}
`;

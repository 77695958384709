import { ArrowLeftOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Space, Table } from "antd";
import { useEffect, useState } from "react";
import { DateFormat, DateTimeFormat, LoadContentType, Mode } from "./const";
import moment from 'moment';
import StudentExamDetail from "./StudentExamDetail";
import { useLazyQuery } from "@apollo/client";
import { LOAD_STUDENT_EXAMS } from "../../../../graphql/exam";

const StudentExam = ({ studentId, setSpinning }) => {
    const [loadContentType, setLoadContentType] = useState(LoadContentType.default);
    const [loadContent, setLoadContent] = useState({
        examId: null,
        mode: null,
    });
    const [loadStudentExam, { data, loading }] = useLazyQuery(LOAD_STUDENT_EXAMS, {
        variables: {
            studentId,
        },
        fetchPolicy: 'no-cache',
    });
    const columns = [
        {
            title: 'No',
            key: 'index',
            render: (value, item, index) => {
                return (
                    <>
                        {index + 1}
                    </>
                );
            },
            width: '10%'
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (value, item, index) => {
                return (
                    <>
                        <Button
                            type='link'
                            title='Click to view.'
                            onClick={() => viewStudentExam(item?.examId)}
                        >{value}</Button>
                    </>
                );
            },
        },
        {
            title: 'Brief.Desc',
            dataIndex: 'shortDescription',
            key: 'shortDescription',
        },
        {
            title: 'Submitted Date',
            dataIndex: 'submittedDate',
            key: 'submittedDate',
            render: (value) => {
                return <>{value && moment(value).format(DateTimeFormat)}</>;
            }
        },
        {
            title: 'Duration(minutes)',
            dataIndex: 'limitedTime',
            key: 'limitedTime',
        },
        // {
        //     title: 'Start Date',
        //     dataIndex: 'startDate',
        //     key: 'startDate',
        //     render: (value, item, index) => {
        //         return (
        //             <>
        //                 {value && moment(value).format(DateFormat)}
        //             </>
        //         );
        //     },
        // },
        // {
        //     title: 'End Date',
        //     dataIndex: 'endDate',
        //     key: 'endDate',
        //     render: (value, item, index) => {
        //         return (
        //             <>
        //                 {value && moment(value).format(DateFormat)}
        //             </>
        //         );
        //     },
        // },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: '10%',
            render: (value, item) => {
                return (
                    <>
                        {/* <EditOutlined onClick={() => editExam(item?.examId)} /> */}
                    </>
                );
            },
        },
    ];

    const viewStudentExam = (examId) => {
        setLoadContentType(LoadContentType.detail);
        setLoadContent({
            ...loadContent,
            examId: examId,
            mode: Mode.View,
            returnFunc: () => {
                setLoadContentType(LoadContentType.default);
            }
        });
    };

    useEffect(() => {
        if (loadContentType === LoadContentType.default) {
            loadStudentExam();
        }
    }, [loadContentType]);

    return <>
        <div className='student-exam'>
            <Space className="student-exam-btn-actions">
                {
                    loadContentType !== LoadContentType.default &&
                    <>
                        <Button
                            type='primary'
                            onClick={() => {
                                setLoadContentType(LoadContentType.default);
                            }}
                        >
                            <ArrowLeftOutlined />
                        </Button>
                    </>
                }
                {
                    loadContentType === LoadContentType.default &&
                    <>
                        <Button
                            type='primary'
                            onClick={() => {
                                loadStudentExam();
                            }}
                        >
                            <ReloadOutlined />
                        </Button>
                    </>
                }
            </Space>
            <div className="table">
                {
                    loadContentType === LoadContentType.default &&
                    <>
                        <Table
                            loading={{
                                spinning: loading,
                                tip: (<>Loading</>)
                            }}
                            dataSource={data?.studentExams || [
                                {
                                    examId: 'dddd',
                                    title: 'Test',
                                    startDate: moment(),
                                    endDate: moment().add('day', 3),
                                }
                            ]} columns={columns} />
                    </>
                }
                {
                    loadContentType === LoadContentType.detail &&
                    <>
                        <StudentExamDetail studentId={studentId} {...loadContent} />
                    </>
                }
            </div>
        </div>
    </>;
};

export default StudentExam;
import gql from 'graphql-tag';

export const STUDENTS = gql`
  query students($take: Float, $skip: Float, $search: String) {
    total: students_findTotal(search: $search)
    students: students_findAll(take: $take, skip: $skip, search: $search) {
      userId
      fullname
      uid
      avatar
      phone
      email
      country
      language
      parentName
      agencyId
      parentPhone
      createdById
      studentInClasses {
        status
        class {
          name
        }
        classId
      }
      created_at
      createdBy {
        userId
        email
        fullname
      }
    }
  }
`;

export const CHECK_EXIST_UID = gql`
  query checkExistUid($uid: String!) {
    checkExistUid(uid: $uid)
  }
`;

export const STUDENT_BY_ID = gql`
  query students($id: String!) {
    students: student_findOne(id: $id) {
      userId
      fullname
      avatar
      phone
      email
      country
      language
      createdById
      created_at
      scoreboards {
        scoreBoardId
        classId
        scores
        created_at
      }
      dailyReports {
        classId
        lessonContent
        reports
        dailyReportId
        isShow
        created_at
      }
      createdBy {
        userId
        email
        fullname
      }
    }
    classes: studentClassesAdmin(studentId: $id) {
      classId
      status
      name
    }
  }
`;

export const CREATE_STUDENT = gql`
  mutation createStudent($createStudentInput: CreateStudentInput!) {
    createStudent(createStudentInput: $createStudentInput) {
      userId
      fullname
      avatar
      phone
      email
      country
      language
      createdById
      created_at
      createdBy {
        userId
        email
        fullname
      }
    }
  }
`;

export const UPDATE_STUDENT = gql`
  mutation updateStudent($updateStudentInput: UpdateStudentInput!) {
    updateStudent(updateStudentInput: $updateStudentInput) {
      userId
      fullname
      avatar
      phone
      email
      country
      language
      createdById
      created_at
      createdBy {
        userId
        email
        fullname
      }
    }
  }
`;

export const REMOVE_STUDENT = gql`
  mutation removeStudent($userId: String!) {
    removeStudent(userId: $userId) {
      userId
    }
  }
`;

export const CREATE_SCORE_BOARD = gql`
  mutation scoreBoard_create($createScoreBoardInput: CreateScoreBoardInput!) {
    scoreBoard_create(createScoreBoardInput: $createScoreBoardInput) {
      userId
    }
  }
`;

export const UPDATE_SCORE_BOARD = gql`
  mutation scoreBoard_update($updateScoreBoardInput: UpdateScoreBoardInput!) {
    scoreBoard_update(updateScoreBoardInput: $updateScoreBoardInput) {
      userId
    }
  }
`;

export const DELETE_SCORE_BOARD = gql`
  mutation scoreBoard_remove($scoreBoardId: String!) {
    scoreBoard_remove(scoreBoardId: $scoreBoardId) {
      userId
    }
  }
`;

export const CLASS_ADD_DAILY_REPORT = gql`
  mutation class_dailyReport_create(
    $classCreateDailyReportInput: ClassCreateDailyReportInput!
  ) {
    class_dailyReport_create(
      classCreateDailyReportInput: $classCreateDailyReportInput
    ) {
      studentId
    }
  }
`;

export const ADD_DAILY_REPORT = gql`
  mutation dailyReport_create(
    $createDailyReportInput: CreateDailyReportInput!
  ) {
    dailyReport_create(createDailyReportInput: $createDailyReportInput) {
      studentId
    }
  }
`;

export const UPDATE_DAILY_REPORT = gql`
  mutation dailyReport_update(
    $updateDailyReportInput: UpdateDailyReportInput!
  ) {
    dailyReport_update(updateDailyReportInput: $updateDailyReportInput) {
      studentId
    }
  }
`;

export const REMOVE_DAILY_REPORT = gql`
  mutation dailyReport_remove($dailyReportId: String!) {
    dailyReport_remove(dailyReportId: $dailyReportId) {
      studentId
    }
  }
`;

export const UPDATE_IS_SHOW_DAILY_REPORT = gql`
  mutation dailyReport_update_isShow($dailyReportId: String!) {
    dailyReport_update_isShow(dailyReportId: $dailyReportId) {
      studentId
    }
  }
`;

export const LIST_COMMENT = gql`
  query commentOfTeacher_findByStudentId($studentId: String!) {
    list_comment: commentOfTeacher_findByStudentId(studentId: $studentId) {
      classId
      class {
        name
      }
      commentId
      content
      studentId
      subject
      isShow
      targetContent
      targetSubject
      created_at
    }
  }
`;

export const REMOVE_COMMENT = gql`
  mutation commentOfTeacher_remove($commentId: String!) {
    commentOfTeacher_remove(commentId: $commentId) {
      commentId
    }
  }
`;

export const UPDATE_SHOW_HIDE_COMMENT = gql`
  mutation commentOfTeacher_updateShowHide($commentId: String!) {
    commentOfTeacher_updateShowHide(commentId: $commentId) {
      commentId
    }
  }
`;

import { gql } from "@apollo/client";

export const IMPORT_DATA = gql`
    mutation importData($createDataImportInput: CreateDataImportInput!, $file: Upload!){
        importData(createDataImportInput: $createDataImportInput, file: $file){
            status
            error
            email
            fullname
            phone
            parentName
            parentPhone
            classes
            agencyId
            fileResultName
            fileResultUrl
        }
    }
`;
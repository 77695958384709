import { useMutation, useQuery } from '@apollo/client';
import {
  STUDENTS_ON_CLASS,
  UPDATE_JOIN_TIME_CLASS,
} from '../../graphql/studentsOnClass';
import * as moment from 'moment';
import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Table,
  Tabs,
} from 'antd';
import { CommentOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { ADD_COMMENT_TEACHER } from '../../graphql/class';
import BodySmartQuestionComponent from './BodySmartQuestionComponent';
import { Link, useNavigate } from 'react-router-dom';
import Materials from './Material/Materials';
import ScoreBoard from './detail/score-board';
import Exam from './detail/exam/Exam';
import StudentInClass from './detail/StudentInClass';

const ClassDetailComponent = ({ classId }) => {
  return (
    <Tabs defaultActiveKey="1" style={{ marginLeft: 10 }}>
      <Tabs.TabPane tab="Student" key="1">
        <StudentInClass classId={classId} />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Smart Question" key="2">
        <div className="body" style={{ paddingLeft: 0 }}>
          <BodySmartQuestionComponent classId={classId} />
        </div>
      </Tabs.TabPane>
      <Tabs.TabPane tab="Materials" key="3">
        <div className="body" style={{ paddingLeft: 0 }}>
          <Materials classId={classId} />
        </div>
      </Tabs.TabPane>
      <Tabs.TabPane tab="Score board" key="4">
        <div className="body" style={{ paddingLeft: 0 }}>
          <ScoreBoard classId={classId} />
        </div>
      </Tabs.TabPane>
      <Tabs.TabPane tab="Exam" key="5">
        <div className="body" style={{ paddingLeft: 0 }}>
          <Exam classId={classId} />
        </div>
      </Tabs.TabPane>
    </Tabs>
  );
};
export default ClassDetailComponent;

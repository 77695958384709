import {
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useForm } from 'antd/lib/form/Form';
import { useMutation } from '@apollo/client';
import {
  ADD_DAILY_REPORT,
  CLASS_ADD_DAILY_REPORT,
  STUDENT_BY_ID,
  UPDATE_DAILY_REPORT,
} from '../../../graphql/student';
import { useSearchParams } from 'react-router-dom';

export const AddReportOnClassComponent = ({ classId }) => {
  let [searchParams, setSearchParams] = useSearchParams();

  const [isShowAddDailyReport, setIsShowAddDailyReport] = useState(false);
  const className = searchParams.get('name');
  const [form] = useForm();
  const [classes, setClasses] = useState([]);
  useEffect(() => {
    if (!!classId && !!className) {
      setClasses([
        {
          name: className,
          classId,
        },
      ]);
      form.setFieldsValue({
        lessonContent: className,
        reports: [
          {
            subject: className,
            description: '',
            score: 4,
          },
        ],
        classId,
      });
    }
  }, [className, classId]);
  const [addDailyReportOnClass] = useMutation(CLASS_ADD_DAILY_REPORT, {
    onCompleted: () => {
      message.success('Success');
    },
  });

  const onFinishAddDailyReport = (values) => {
    addDailyReportOnClass({
      variables: {
        classCreateDailyReportInput: {
          lessonContent: values.lessonContent,
          reports: values.reports,
          classId: searchParams.get('classId'),
        },
      },
    });
    setIsShowAddDailyReport(false);
  };

  return (
    <>
      <Button type="primary" onClick={() => setIsShowAddDailyReport(true)}>
        Add report of class
      </Button>
      <Modal
        title={'Add new report on class'}
        visible={isShowAddDailyReport}
        width={800}
        onOk={() => {
          form.submit();
        }}
        onCancel={() => {
          setIsShowAddDailyReport(false);
        }}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          layout={'vertical'}
          onFinish={onFinishAddDailyReport}
          autoComplete="off"
        >
          <Form.Item
            name="classId"
            label="Class:"
            rules={[{ required: true, message: 'Missing Lesson class' }]}
          >
            <Select>
              {classes?.map((classInfo) => (
                <Select.Option
                  value={classInfo.classId}
                  key={classInfo.classId}
                >
                  {classInfo.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="lessonContent"
            label="Lesson content:"
            rules={[{ required: true, message: 'Missing Lesson content' }]}
          >
            <Input.TextArea readonly placeholder="Lesson content" />
          </Form.Item>

          <Form.List name="reports">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div key={key} style={{ marginBottom: 8 }} align="center">
                    <Divider />
                    <Form.Item
                      {...restField}
                      name={[name, 'subject']}
                      rules={[{ required: true, message: 'Missing Subject' }]}
                    >
                      <Input.TextArea readonly placeholder="Subject" />
                    </Form.Item>
                    <Form.Item {...restField} name={[name, 'description']}>
                      <Input.TextArea placeholder="Description" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'score']}
                      rules={[{ required: true, message: 'Missing Rating' }]}
                    >
                      <InputNumber
                        min={0}
                        max={4}
                        defaultValue={4}
                        placeholder="Rating"
                        className="w-full"
                      />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </div>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add field
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </>
  );
};

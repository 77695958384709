import React, { useEffect } from 'react';
import {
  Breadcrumb,
  Input,
  Button,
  message,
  Form,
  Space,
  Spin,
  InputNumber,
} from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useLazyQuery, useMutation } from '@apollo/client';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  FIND_ALL_STUDY_PROGRAMS,
  FIND_ONE_STUDY_PROGRAM,
  UPDATE_STUDY_PROGRAMS,
} from '../../graphql/study-program';

const EditNewStudyProgram = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();

  const [findOneStudyProgram, { data, loading, refetch }] = useLazyQuery(
    FIND_ONE_STUDY_PROGRAM,
  );

  const [updateStudyProgram] = useMutation(UPDATE_STUDY_PROGRAMS, {
    refetchQueries: [{ query: FIND_ALL_STUDY_PROGRAMS }],
    onCompleted: () => {
      message.success('Success');
      navigate('/study-program');
      refetch();
    },
  });

  useEffect(() => {
    findOneStudyProgram({
      variables: { id },
    });
  }, [id]);

  useEffect(() => {
    if (data?.studyProgram) {
      form.setFieldsValue({
        studyProgramId: data?.studyProgram.studyProgramId,
        name: data?.studyProgram.name,
        description: data?.studyProgram.description,
        programs: data?.studyProgram.programs,
      });
    }
  }, [data]);

  const onFinish = (values) => {
    updateStudyProgram({
      variables: {
        updateStudyProgramInput: {
          ...values,
          studyProgramId: data?.studyProgram.studyProgramId,
        },
      },
    });
  };

  return (
    <Spin tip="Loading..." spinning={loading}>
      <div className="post">
        <div className="breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/study-program">Study Program</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/study-program/create">Update</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div style={{ padding: 20 }}>
          <Form
            name="dynamic_form_nest_item"
            onFinish={onFinish}
            layout={'vertical'}
            autoComplete="off"
            form={form}
          >
            <Form.Item
              name="name"
              label="Name:"
              rules={[{ required: true, message: 'Missing name' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description:"
              rules={[{ required: true, message: 'Missing description' }]}
            >
              <Input />
            </Form.Item>
            <span>Programs</span>
            <Form.List name="programs">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <>
                      <Space
                        key={key}
                        style={{
                          display: 'flex',
                          marginBottom: 8,
                          width: '100%',
                        }}
                        align="baseline"
                        block
                      >
                        <Form.Item style={{ width: '10px' }}>
                          {index + 1}.
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          style={{ width: '200px' }}
                          name={[name, 'lessonName']}
                          rules={[
                            { required: true, message: 'Missing last name' },
                          ]}
                        >
                          <Input placeholder="Lesson name" />
                        </Form.Item>
                        <Form.Item
                          style={{ width: '800px' }}
                          {...restField}
                          name={[name, 'lessonDescription']}
                          rules={[
                            { required: true, message: 'Missing last name' },
                          ]}
                        >
                          <Input placeholder="Lesson description" />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                      <Form.List name={[name, 'reports']}>
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(({ key, name, ...restField }) => (
                              <Space
                                key={key}
                                style={{ display: 'flex', marginBottom: 8 }}
                                align="baseline"
                              >
                                <Form.Item
                                  {...restField}
                                  name={[name, 'subject']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Missing Subject',
                                    },
                                  ]}
                                >
                                  <Input placeholder="Subject" />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'shortDescription']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Missing short description',
                                    },
                                  ]}
                                >
                                  <Input placeholder="Short Description" />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'description']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Missing description',
                                    },
                                  ]}
                                >
                                  <Input placeholder="Description" />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'score']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Missing Rating',
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    min={0}
                                    max={4}
                                    placeholder="Rating"
                                  />
                                </Form.Item>
                                <MinusCircleOutlined
                                  onClick={() => remove(name)}
                                />
                              </Space>
                            ))}
                            <Form.Item>
                              <Button
                                type="dashed"
                                onClick={() => add()}
                                block
                                icon={<PlusOutlined />}
                              >
                                Add daily report
                              </Button>
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                    </>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add field
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Spin>
  );
};

EditNewStudyProgram.propTypes = {};

export default EditNewStudyProgram;

import React, { useState, useEffect, useContext, useMemo } from 'react';

import * as moment from 'moment';
import {
  Table,
  Button,
  Modal,
  Form,
  DatePicker,
  Input,
  Select,
  Spin,
  Space,
  Typography,
  Row,
  Breadcrumb,
} from 'antd';

import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  CREATE_CLASS,
  LIST_CLASS,
  REMOVE_CLASS,
  TEACHER_STUDENTS,
  UPDATE_CLASS,
} from '../../graphql/class';
import BreadCrumbComponent from '../../components/breadcrumb/BreadCrumbComponent';
import ClassDetailComponent from './ClassDetailComponent';
import { AuthContext } from '../../contexts/auth';
import { FIND_ALL_STUDY_PROGRAMS } from '../../graphql/study-program';
import { sortBy } from 'lodash';
import { GRADE_CONST, LEVELS_CONST, UserRoleConstant } from '../../constants';
import { STUDENTS } from '../../graphql/student';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LIST_AGENCY } from '../../graphql/agency';
import ClassTableComponent from '../../components/tables/class.table.component';
import { StorageHelper } from '../../utils/storage.helper';
import { PagingType } from '../../constants/paging';

const times = [
  '0:00',
  '1:00',
  '2:00',
  '3:00',
  '4:00',
  '5:00',
  '6:00',
  '7:00',
  '8:00',
  '9:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00',
];
const extendTimes = times
  .map((value) => [
    value,
    value.replace('00', '15'),
    value.replace('00', '30'),
    value.replace('00', '45'),
    value.replace('00', '55'),
  ])
  .flat(1);
const Class = () => {
  let [searchParams, setSearchParams] = useSearchParams();

  const [isShowModalAddNew, setIsShowModalAddNew] = useState(false);
  const [initForm, setInitForm] = useState({});
  const [form] = Form.useForm();
  const [teachers, setTeachers] = useState([]);
  const [students, setStudents] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [selectedClass, setSelectedClass] = useState(null);
  const [filterAgencyId, setFilterAgencyId] = useState(null);
  const auth = useContext(AuthContext);
  let navigate = useNavigate();
  const [filters, setFilters] = useState({
    filters: {},
  });
  const [loadClasses, { loading, error, data: classData }] = useLazyQuery(
    LIST_CLASS,
    {
      fetchPolicy: 'no-cache',
    },
  );
  // const classData = useQuery(LIST_CLASS);

  // useEffect(() => {
  //   if (classData?.data?.classes) {
  //     setClassDatasources(classData?.data?.classes);
  //   }
  // }, [classData]);
  const fetchClasses = (isFetchAll = true) => {
    const take = searchParams.get('take');
    const skip = searchParams.get('skip');
    const search = searchParams.get('search');
    if (isFetchAll) {
      loadClasses({
        variables: {
          take: 100000,
          skip: 0,
          search: search ?? '',
          filters: filters.filters,
        },
      });
      return;
    }
    loadClasses({
      variables: {
        take: take ? Number(take) : null,
        skip: skip ? Number(skip) : null,
        search: search ?? '',
        filters: filters.filters,
      },
    });
  };
  useEffect(() => {
    fetchClasses();
  }, [searchParams]);

  const defaultRoutes = [
    {
      id: -1,
      path: '/',
      breadcrumbName: 'Home',
    },
    {
      id: 0,
      path: 'class',
      breadcrumbName: 'Class',
    },
  ];
  const route = {
    id: selectedClass?.classId,
    path: `class-detail?classId=${selectedClass?.classId}&name=${selectedClass?.name}`,
    breadcrumbName: selectedClass?.name,
  };
  // const [routes, setRoutes] = useState(defaultRoutes)
  let routes = defaultRoutes;

  const [fetchTeacherStudents, { data: teacherStudentsData }] =
    useLazyQuery(TEACHER_STUDENTS);
  const { data: agencyRes } = useQuery(LIST_AGENCY);
  const { data: datastudyProgram } = useQuery(FIND_ALL_STUDY_PROGRAMS);

  const [createClass] = useMutation(CREATE_CLASS, {
    refetchQueries: [
      // { query: LIST_CLASS },
      { query: STUDENTS },
    ],
    onCompleted: (response) => {
      fetchClasses();
      if (response && response.createClass) {
        setPageLoading(false);
        Modal.success({ content: 'Create success! ' });
        setInitForm({});
      }
    },
    onError: (_createError) => {
      setPageLoading(false);
      Modal.error({ content: 'Create student fail' });
    },
  });
  const [updateClass] = useMutation(UPDATE_CLASS, {
    refetchQueries: [{ query: LIST_CLASS }, { query: STUDENTS }],
    onCompleted: (response) => {
      fetchClasses();
      if (response && response.updateClass) {
        setPageLoading(false);
        Modal.success({ content: 'Update success! ' });
        setInitForm({});
      }
    },
    onError: (_createError) => {
      setPageLoading(false);
      Modal.error({ content: 'update class fail' });
    },
  });
  const [removeClass] = useMutation(REMOVE_CLASS, {
    refetchQueries: [
      // { query: LIST_CLASS },
      { query: STUDENTS },
    ],
    onCompleted: (response) => {
      fetchClasses();
      if (response && response.removeClass) {
        setPageLoading(false);
        Modal.success({ content: 'Remove success! ' });
        setInitForm({});
      }
    },
    onError: (_createError) => {
      setPageLoading(false);
      Modal.error({ content: 'Remove fail' });
    },
  });

  useEffect(() => {
    if (selectedClass?.classId) {
      navigate(`/${route.path}`);
    }
  }, [selectedClass]);

  useEffect(() => {
    if (teacherStudentsData?.teachers) {
      setTeachers(sortBy(teacherStudentsData?.teachers, (o) => o.fullname));
    }
    if (teacherStudentsData?.students) {
      setStudents(sortBy(teacherStudentsData?.students, (o) => o.fullname));
    }
  }, [teacherStudentsData]);

  useEffect(() => {
    if (filterAgencyId) {
      fetchTeacherStudents({
        variables: {
          take: 50000,
          agencyId: filterAgencyId,
        },
      });
    }
  }, [filterAgencyId]);

  useEffect(() => {
    if (initForm && initForm.name) {
      form.setFieldsValue({
        name: initForm.name,
        description: initForm.description,
        startAt: initForm.startAt ? moment(initForm.startAt) : null,
        scheduleTime: initForm.scheduleTime,
        endAt: initForm.endAt ? moment(initForm.endAt) : null,
        fromAt: initForm.fromAt,
        toAt: initForm.toAt,
        agencyId: initForm.agencyId,
        grade: initForm.grade,
        gradeLevel: initForm.gradeLevel,
        schoolYear: initForm.schoolYear,
        students: initForm.students.map((student) => student.studentId),
        studyProgramId: initForm?.studyProgram?.studyProgramId,
        teachers: initForm.teachers.map((teacher) => ({
          teacherId: teacher.teacherId,
          role: teacher.role,
        })),
      });
    } else {
      form.setFieldsValue({
        startAt: moment(),
        endAt: moment(),
        name: '',
        description: '',
        scheduleTime: [],
        fromAt: '',
        toAt: '',
        teachers: [],
        students: [],
        studyProgramId: null,
        agencyId: '',
        grade: '',
        gradeLevel: '',
        schoolYear: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initForm]);

  const handleAddNew = () => {
    setInitForm({});
    // form.resetFields();
    setIsShowModalAddNew(true);
    setFilterAgencyId(null);
  };

  const closeModal = () => {
    form.resetFields();
    setIsShowModalAddNew(false);
  };

  const hanleDeleteClass = (id) => {
    Modal.confirm({
      title: 'Delete class?',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure delete this class?',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: () => {
        removeClass({
          variables: {
            classId: id,
          },
        });
      },
    });
  };

  const handleUpdateClass = (item) => {
    setInitForm({
      ...item,
      startAt: moment(item.startAt),
      endAt: moment(item.endAt),
    });
    setIsShowModalAddNew(true);
    setFilterAgencyId(item?.agencyId);
  };

  const onFinish = (values) => {
    setPageLoading(true);

    if (initForm.classId) {
      updateClass({
        variables: {
          updateClassInput: {
            classId: initForm.classId,
            ...values,
          },
        },
      });
    } else {
      createClass({
        variables: {
          createClassInput: {
            ...values,
          },
        },
      });
    }
    setInitForm({});
    setIsShowModalAddNew(false);
  };

  const onFinishFailed = () => {};

  const onSearch = (value) => {
    StorageHelper.setPaging(PagingType.class, {
      skip: null,
      take: null,
      search: value ?? '',
    });
    StorageHelper.setRecentSearch(PagingType.class, value ?? '');
    setSearchParams({
      search: value ?? '',
    });
  };
  const getDefaultValue = () => {
    const search = searchParams.get('search');
    if (search === 'null') return '';
    return search ?? '';
  };

  const parseStudents = (students) => {
    return (students ?? []).map((student) => (
      <Select.Option key={student?.userId}>{student?.fullname}</Select.Option>
    ));
  };

  const SearchComponent = () => {
    const recentSearchValue = StorageHelper.getRecentSearch(PagingType.class);
    return (
      <div>
        <div>
          <Input.Search
            id="search"
            placeholder="Search text"
            onSearch={onSearch}
            style={{ width: 200 }}
            defaultValue={getDefaultValue()}
          />
        </div>
        {recentSearchValue && getDefaultValue() === '' && (
          <div>
            Từ khoá tìm kiếm gần đây:
            <p>{recentSearchValue}</p>
          </div>
        )}
      </div>
    );
  };
  console.log('classData', classData);
  return (
    <Spin tip="Loading..." spinning={pageLoading || loading}>
      <div className="post">
        <div className="breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>Class</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="body">
          {selectedClass ? (
            <ClassDetailComponent classId={selectedClass.classId} />
          ) : (
            <>
              <div className="add-new">
                {(auth?.user?.role === UserRoleConstant.ADMIN ||
                  auth?.user?.role === UserRoleConstant.AGENCY_ADMIN) && (
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={handleAddNew}
                  >
                    Add New
                  </Button>
                )}
              </div>
              <div className="add-new">
                <SearchComponent />
              </div>
              <div className="table">
                <ClassTableComponent
                  total={classData?.data?.total}
                  classes={classData?.classes}
                  handleUpdateClass={handleUpdateClass}
                  hanleDeleteClass={hanleDeleteClass}
                  loadClasses={loadClasses}
                  agencyRes={agencyRes}
                  setSelectedClass={setSelectedClass}
                  setFilters={setFilters}
                  filters={filters}
                />
              </div>
            </>
          )}
        </div>
        <Modal
          forceRender
          title={initForm.classId ? 'Update class' : 'Add New Class'}
          visible={isShowModalAddNew}
          onOk={closeModal}
          onCancel={closeModal}
          bodyStyle={{
            height: 500,
            overflow: 'auto',
          }}
          footer={null}
        >
          <Form
            name="basic"
            form={form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 24 }}
            initialValues={initForm}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            autoComplete="off"
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please input class name!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              rules={[{ required: true, message: 'Please input description!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Agency"
              name="agencyId"
              rules={[{ required: true, message: 'Please select agency!' }]}
            >
              <Select
                onChange={(value) => {
                  setFilterAgencyId(value);
                }}
              >
                {agencyRes?.agencies.map((o) => (
                  <Select.Option key={o.agencyId} value={o.agencyId}>
                    {o.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="School year" name="schoolYear">
              <Input />
            </Form.Item>
            <Form.Item
              label="Start date"
              name="startAt"
              rules={[{ required: true, message: 'Please input start date!' }]}
            >
              <DatePicker />
            </Form.Item>
            <Form.Item
              label="End date"
              name="endAt"
              rules={[{ required: true, message: 'Please input end date!' }]}
            >
              <DatePicker />
            </Form.Item>
            <Form.Item
              label="From at"
              name="fromAt"
              rules={[{ required: true, message: 'Please input from at!' }]}
            >
              <Select>
                {extendTimes.map((o) => (
                  <Select.Option key={o} value={o}>
                    {o}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="To At"
              name="toAt"
              rules={[{ required: true, message: 'Please input to at!' }]}
            >
              <Select>
                {extendTimes.map((o) => (
                  <Select.Option key={o} value={o}>
                    {o}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Week day"
              name="scheduleTime"
              rules={[
                { required: true, message: 'Please input schedule time!' },
              ]}
            >
              <Select
                mode="multiple"
                size={'middle'}
                placeholder="Please select schedule"
                style={{ width: '100%' }}
              >
                <Select.Option key={1}>Monday</Select.Option>
                <Select.Option key={2}>Tuesday</Select.Option>
                <Select.Option key={3}>Wednesday</Select.Option>
                <Select.Option key={4}>Thursday</Select.Option>
                <Select.Option key={5}>Friday</Select.Option>
                <Select.Option key={6}>Saturday</Select.Option>
                <Select.Option key={0}>Sunday</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Study program"
              name="studyProgramId"
              // rules={[
              //   { required: true, message: 'Please input study program!' },
              // ]}
            >
              <Select
                size={'middle'}
                placeholder="Please select study program"
                style={{ width: '100%' }}
              >
                {datastudyProgram?.studyPrograms?.map((studyProgram) => (
                  <Select.Option key={studyProgram?.studyProgramId}>
                    {studyProgram?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Grade" name="grade">
              <Select
                size={'middle'}
                placeholder="Please select schedule"
                style={{ width: '100%' }}
              >
                {sortBy(GRADE_CONST, (o) => o.name).map((grade) => (
                  <Select.Option key={grade.name} value={grade.name}>
                    {grade.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Level" name="gradeLevel">
              <Select
                size={'middle'}
                placeholder="Please select level"
                style={{ width: '100%' }}
              >
                {sortBy(LEVELS_CONST, (o) => o.name).map((level) => (
                  <Select.Option key={level.name} value={level.name}>
                    {level.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Students"
              name="students"
              rules={[{ required: true, message: 'Please input students!' }]}
            >
              <Select
                disabled={!filterAgencyId}
                showSearch
                mode="multiple"
                size={'middle'}
                placeholder="Please select students"
                style={{ width: '100%' }}
                filterOption={(input, option) => {
                  return (option?.children ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
              >
                {parseStudents(students)}
              </Select>
            </Form.Item>
            <Row>
              <Typography.Text
                style={{
                  fontFamily: 'SimSun, sans-serif',
                  fontSize: 14,
                  color: '#ff4d4f',
                  marginRight: 4,
                }}
              >
                *
              </Typography.Text>
              Teachers
            </Row>
            <Form.List
              name="teachers"
              rules={[
                {
                  required: true,
                  message: 'Please add teacher(s)!',
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space
                      key={key}
                      style={{ display: 'flex', marginBottom: 8 }}
                      align="baseline"
                    >
                      <Form.Item
                        {...restField}
                        name={[name, 'teacherId']}
                        rules={[{ required: true, message: 'Missing teacher' }]}
                      >
                        <Select
                          showSearch
                          size={'middle'}
                          style={{ width: '200px' }}
                          placeholder="Please select teacher"
                          filterOption={(input, option) => {
                            return (option?.children ?? '')
                              .toLowerCase()
                              .includes(input.toLowerCase());
                          }}
                        >
                          {(teachers ?? [])
                            .filter((teacher) =>
                              teacher?.agencies?.some(
                                (agency) => agency?.agencyId === filterAgencyId,
                              ),
                            )
                            .map((teacher) => (
                              <Select.Option key={teacher?.userId}>
                                {teacher?.fullname}
                              </Select.Option>
                            ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'role']}
                        rules={[{ required: true, message: 'Missing role' }]}
                      >
                        <Input placeholder="Please input role" />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.ErrorList errors={errors} />
                  <Form.Item>
                    <Button
                      disabled={!filterAgencyId}
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add teachers
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Form.Item wrapperCol={{ offset: 20, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </Spin>
  );
};

Class.propTypes = {};

export default Class;

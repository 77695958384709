import {
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
} from 'antd';
import React, { useEffect } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_DAILY_REPORT, STUDENT_BY_ID } from '../../graphql/student';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

export const DailyReportForStudentModal = ({
  isShowAddDailyReport,
  setIsShowAddDailyReport,
  studentId,
}) => {
  const { data: studentData } = useQuery(STUDENT_BY_ID, {
    variables: {
      id: studentId,
    },
  });
  const classes = studentData?.classes || [];
  const className = classes?.[0]?.name;
  const classId = classes?.[0]?.classId;

  const [form] = useForm();
  const resetForm = () => {
    form.setFieldsValue({
      lessonContent: '',
      reports: [],
      classId: '',
    });
  };

  useEffect(() => {
    if (!!studentData) {
      const classes = studentData?.classes;
      form.setFieldsValue({
        lessonContent: classes?.[0]?.name,
        reports: [
          {
            subject: classes?.[0]?.name,
            description: '',
            score: 4,
          },
        ],
        classId: classes?.[0]?.classId,
      });
    }
  }, [studentData]);

  const [addDailyReport] = useMutation(ADD_DAILY_REPORT, {
    refetchQueries: [{ query: STUDENT_BY_ID, variables: { id: studentId } }],
    onCompleted: () => {
      message.success('Success');
      resetForm();
    },
  });

  const onFinishAddDailyReport = (values) => {
    setIsShowAddDailyReport(false);
    addDailyReport({
      variables: {
        createDailyReportInput: {
          lessonContent: values.lessonContent,
          reports: values.reports,
          studentId,
          classId,
        },
      },
    });
  };
  return (
    <Modal
      title={'Add new'}
      visible={isShowAddDailyReport}
      width={800}
      onOk={() => {
        form.submit();
      }}
      onCancel={() => {
        setIsShowAddDailyReport(false);
      }}
    >
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout={'vertical'}
        onFinish={onFinishAddDailyReport}
        autoComplete="off"
      >
        <Form.Item
          name="classId"
          label="Class:"
          rules={[{ required: true, message: 'Missing Lesson class' }]}
        >
          <Select>
            {classes?.map((classInfo) => (
              <Select.Option value={classInfo.classId} key={classInfo.classId}>
                {classInfo.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="lessonContent"
          label="Lesson content:"
          rules={[{ required: true, message: 'Missing Lesson content' }]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.List name="reports">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <div key={key} style={{ marginBottom: 8 }} align="center">
                  <Divider />
                  <Form.Item
                    {...restField}
                    name={[name, 'subject']}
                    initialValue={className}
                    rules={[{ required: true, message: 'Missing Subject' }]}
                  >
                    <Input
                      readonly
                      defaultValue={className}
                      placeholder="Subject"
                    />
                  </Form.Item>
                  <Form.Item {...restField} name={[name, 'description']}>
                    <Input.TextArea placeholder="Description" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'score']}
                    rules={[{ required: true, message: 'Missing Rating' }]}
                    initialValue={4}
                  >
                    <InputNumber
                      min={0}
                      max={4}
                      defaultValue={4}
                      placeholder="Rating"
                      className="w-full"
                    />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </div>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add field
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
};

import React, { useContext, useEffect, useState } from 'react';
import { Breadcrumb, Row, Col, Input, Button, Modal, message, Select } from 'antd';
import * as moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';

import Editor from '../../components/editor';
import defaut_image from '../../images/images.png';
import { AuthContext } from '../../contexts/auth';
import { useMutation } from '@apollo/client';
import { CREATE_RESOURCE } from '../../graphql/resourceMutations';
import { CREATE_NEWS, NEWS } from '../../graphql/news';

const CreateNews = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [article, setArticle] = useState({});
  const [fileCover, setFileCover] = useState();
  const [content, setContent] = useState('');
  const [isPreview, setIsPreview] = useState(false);

  const [createResource] = useMutation(CREATE_RESOURCE);
  const [createNews] = useMutation(CREATE_NEWS, {
    refetchQueries: [{query: NEWS, variables: { filter: {}}}],
    onCompleted: (response) => {
      if (response && response.createNews) {
        setLoading(false);
        Modal.success({ title: 'Create success! ' });
        navigate("/news", { replace: true });
      }
    },
    onError: (_createError) => {
      setLoading(false)
      Modal.error({ message: 'Create student fail' });
    }
  });

  useEffect(() => {
    if (loading) {
      const hide = message.loading('Action in progress..', 0);
      setTimeout(hide, 2500);
    }
  }, [loading]);

  const handleSave = async () => {
    // Save image
    if (fileCover) {
      const createResourceDt = await createResource({
        variables: {
          file: fileCover
        }
      });
      const coverUrl = createResourceDt?.data?.createResource?.url;

      createNews({
        variables: {
          createNewsInput: {
            ...article,
            image: coverUrl,
            description: content,
            status: 'INACTIVE',
          }
        }
      });
    } else {
      Modal.warning({title: 'Cover is required!'});
    }
  };

  const handleChangeImage = event => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      setFileCover(img);
    }
  };
  
  return <div className='post'>
    <div className='breadcrumb'>
      <Breadcrumb>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/news">News</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/news/create">Create</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
    {
      !isPreview
        ?
        <div className='body'>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <span style={{ color: 'red' }}>*</span>Name:
              <Input value={article.title} onChange={(e) => { setArticle({...article, title: e.target.value }); }}/>
            </Col>
            <Col span={12}>
              <span style={{ color: 'red' }}>*</span>Category:
              <br></br>
              <Select style={{ width: '50%' }} onChange={(value) => { setArticle({...article, category: value }); }}>
                <Select.Option value="dashboard">Dashboard</Select.Option>
                <Select.Option value="learn">Learn</Select.Option>
                <Select.Option value="news">News</Select.Option>
              </Select>
            </Col>
            <Col span={24}>
              <label htmlFor="cover"><span style={{ color: 'red' }}>*</span>Choose a cover picture (260 x 146):</label>
              <br />
              <input type="file"
                id="cover" name="cover"
                accept="image/png, image/jpeg"
                onChange={handleChangeImage}></input>
            </Col>
            <Col span={24}>
              <img src={fileCover ? URL.createObjectURL(fileCover) : defaut_image} width={260} height={146} alt="ok"/>
            </Col>
            <Col span={24}>
              <label htmlFor="shortDescription"><span style={{ color: 'red' }}>*</span>Sort description:</label>
              <Input value={article.shortDescription} onChange={(e) => { setArticle({...article, shortDescription: e.target.value }); }}/>
            </Col>
            <Col span={24}>
              <label><span style={{ color: 'red' }}>*</span>Description:</label>
              <Editor
                value={article.description || ''}
                onChange={(newContent) => { setContent(newContent); }}
              />
            </Col>
          </Row>
          <div className='action_button'>
            <Button type="primary" onClick={() => { setIsPreview(true); }}>
              Preview
            </Button>
          </div>
        </div>
        :
        <div className='preview-container'>
          <div className='preview'>
            <img src={fileCover ? URL.createObjectURL(fileCover) : defaut_image} width={600} height={400} alt="ok"/>
            <div className='content'>
              <h3>{article.title || 'Lorem ipsum dolor sit amet'}</h3>
              <h4>{`Written by ${user.fullname}`} | {moment().format('DD/MM/YYYY hh:mm:ss')}</h4>
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
          </div>
          <div className='action_button'>
            <Button className='button_dds' type="primary" onClick={() => { setIsPreview(false);} }>
              Back
            </Button>
            <Button className='button_dds' type="primary" onClick={() => handleSave() }>
              Publish
            </Button>
          </div>
        </div>
    }
  </div>;
};

CreateNews.propTypes = {};

export default CreateNews;

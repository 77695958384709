import gql from 'graphql-tag';

export const FEEDBACKS = gql`
	query feedbacks($filter: FilterFeedbacksInput!) {
		feedbacks: feedbacks_findAll(filter: $filter) {
      feedbackId
      content
      status
      response
      created_at
      typeOfFeedback
      title
      createdBy {
        agencyId
        userId
        email
        fullname
        phone
      }
		}
	}
`;

export const UPDATE_FEEDBACK = gql`
	mutation updateFeedbacks($updateFeedbacksInput: UpdateFeedbacksInput!) {
		updateFeedbacks(updateFeedbacksInput: $updateFeedbacksInput) {
      content
      status
      response
      created_at
      typeOfFeedback
      title
      createdBy {
        userId
        email
        fullname
        phone
      }
		}
	}
`;

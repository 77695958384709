import { Table } from 'antd';
import React, { useContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { GRADE_CONST, LEVELS_CONST, UserRoleConstant } from '../../constants';
import * as moment from 'moment/moment';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { AuthContext } from '../../contexts/auth';
import { StorageHelper } from '../../utils/storage.helper';
import { PagingType } from '../../constants/paging';

const defineColumns = (
  auth,
  setSelectedClass,
  agencyRes,
  handleUpdateClass,
  hanleDeleteClass,
) => {
  return [
    {
      title: 'No',
      key: 'index',
      render: (value, item, index) => index + 1,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (value, item, _) => {
        const handleClickOnClass = (e) => {
          e.preventDefault();
          setSelectedClass(item);
        };
        return (
          <>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="src/pages/class/Class" onClick={handleClickOnClass}>
              {value}
            </a>
          </>
        );
      },
      sorter: (a, b) => a?.name.localeCompare(b.name),
    },
    {
      title: 'Grade',
      dataIndex: 'grade',
      key: 'grade',
      filters: GRADE_CONST.map((grade) => ({
        value: grade.name,
        text: grade.name,
      })),
      onFilter: (value, record) => {
        return record?.grade.startsWith(value);
      },
    },
    {
      title: 'Level',
      dataIndex: 'gradeLevel',
      key: 'gradeLevel',
      filters: LEVELS_CONST.map((lv) => ({ value: lv.name, text: lv.name })),
      onFilter: (value, record) => record?.gradeLevel.startsWith(value),
    },
    {
      title: 'Agency',
      dataIndex: ['agency', 'name'],
      key: 'agency',
      filters: agencyRes?.agencies.map((agency) => ({
        value: agency.name,
        text: agency.name,
      })),
      onFilter: (value, record) => record?.agency?.name.startsWith(value),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_item, row) => {
        if (
          moment(row.startAt).startOf('day').toDate().getTime() <
            moment().toDate().getTime() &&
          moment(row.endAt).endOf('day').toDate().getTime() >
            moment().toDate().getTime()
        ) {
          return 'ACTIVE';
        } else {
          return 'INACTIVE';
        }
      },
      filters: [
        {
          text: 'ACTIVE',
          value: 'ACTIVE',
        },
        {
          text: 'INACTIVE',
          value: 'INACTIVE',
        },
      ],
      filterSearch: true,
      onFilter: (value, record) => {
        if (value === 'ACTIVE') {
          return (
            moment(record.startAt).toDate().getTime() <
              moment().toDate().getTime() &&
            moment(record.endAt).toDate().getTime() >
              moment().toDate().getTime()
          );
        } else {
          return !(
            moment(record.startAt).toDate().getTime() <
              moment().toDate().getTime() &&
            moment(record.endAt).toDate().getTime() >
              moment().toDate().getTime()
          );
        }
      },
    },
    {
      title: 'Start at',
      dataIndex: 'startAt',
      key: 'startAt',
      render: (startAt) => moment(startAt).format('DD/MM/YYYY'),
      sorter: (a, b) => moment(a.startAt).toDate() - moment(b.startAt).toDate(),
    },
    {
      title: 'End at',
      dataIndex: 'endAt',
      key: 'endAt',
      render: (endAt) => moment(endAt).format('DD/MM/YYYY'),
      sorter: (a, b) => moment(a.endAt).toDate() - moment(b.endAt).toDate(),
    },
    ...(auth?.user?.role === UserRoleConstant.ADMIN ||
    auth?.user?.role === UserRoleConstant.AGENCY_ADMIN
      ? [
          {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (value, item) => {
              return (
                <>
                  <EditOutlined
                    onClick={() => handleUpdateClass(item)}
                    className="mr10"
                  />
                  <DeleteOutlined
                    onClick={() => hanleDeleteClass(item.classId)}
                  />
                </>
              );
            },
          },
        ]
      : []),
  ];
};

export default function ClassTableComponent({
  total,
  classes,
  loadClasses,
  agencyRes,
  setSelectedClass,
  handleUpdateClass,
  hanleDeleteClass,
  setFilters,
  filters,
}) {
  const auth = useContext(AuthContext);
  const columns = defineColumns(
    auth,
    setSelectedClass,
    agencyRes,
    handleUpdateClass,
    hanleDeleteClass,
  );

  return (
    <Table
      dataSource={classes || []}
      columns={columns}
      onChange={(pagination, paramFilters, sorter) => {
        setFilters({
          filters: paramFilters,
        });
      }}
    />
  );
}

import React, { useState } from 'react';

import { Button, message, Table } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { LIST_MATERIALS, REMOVE_MATERIALS } from '../../../graphql/class';
import { DeleteOutlined } from '@ant-design/icons';
import AddMaterial from './AddMaterial';
import * as moment from 'moment';
import AddMultipleMaterial from './AddMultipleMaterial';

const Materials = ({ classId }) => {
  const [isShowAddMaterial, setIsShowAddMaterial] = useState(false);
  const [isShowAddMultipleMaterial, setIsShowAddMultipleMaterial] = useState(false);
  const { data } = useQuery(LIST_MATERIALS, {
    variables: {
      classId
    }
  });

  const [removeMaterial] = useMutation(REMOVE_MATERIALS, {
    refetchQueries: [{ query: LIST_MATERIALS, variables: { classId }}],
    onCompleted: () => {
      message.success('Success');
    }
  })

  const handleAddMaterials = () => {
    setIsShowAddMaterial(true);
  }

  const handleAddMultipleMaterials = () => {
    setIsShowAddMultipleMaterial(true);
  }

  const hanleDelete = (item) => {
    console.log('item ', item)
    removeMaterial({
      variables: {
        resourceId: item.resourceId
      }
    })
  }

  const columns = [
    {
      title: 'No',
      key: 'index',
      render: (value, item, index) => index + 1,
      width: '10%'
    },
    {
      title: 'Name',
      dataIndex: 'fileName',
      key: 'fileName',
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category'
    },
    {
      title: 'Url',
      dataIndex: 'url',
      key: 'url',
      render: (value) => <a href={value} target="_blank" rel="noreferrer">Url</a>
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (value) => moment(value).format('DD/MM/YYYY')
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '10%',
      render: (value, item) => {
        return (
          <>
            <DeleteOutlined onClick={() => hanleDelete(item)} />
          </>
        );
      },
    },
  ];

  return <>
    <div>
      <div>
        <div className='flex justify-end'> 
          <Button
            type='primary'
            className='addSmartQuestion mr-5'
            onClick={handleAddMultipleMaterials}
          >
            Add (n) materials
          </Button>
          <Button
            type='primary'
            className='addSmartQuestion'
            onClick={handleAddMaterials}
          >
            Add (1) material
          </Button>

        </div>
        <div className="table">
          <Table dataSource={data?.materials || []} columns={columns} />
        </div>
      </div>
      <AddMaterial 
        isShowAddMaterial={isShowAddMaterial}
        setIsShowAddMaterial={setIsShowAddMaterial}
        classId={classId}
      />
      <AddMultipleMaterial
        isShowAddMultipleMaterial={isShowAddMultipleMaterial}
        setIsShowAddMultipleMaterial={setIsShowAddMultipleMaterial}
        classId={classId}
      />
    </div>
  </>
};

Materials.propTypes = {};

export default Materials;

import React, { useState, useEffect } from 'react';
import * as moment from 'moment';
import { Breadcrumb, Table, Button, Modal, Form, Input, Spin, Card } from 'antd';
import {
  EditOutlined,
} from '@ant-design/icons';

import { useMutation, useQuery } from '@apollo/client';
import { FEEDBACKS, UPDATE_FEEDBACK } from '../graphql/feedback';
import { UserRoleConstant } from '../constants';
import AgencyFilter from '../components/filters/AgencyFilter';
import { useContext } from 'react';
import { AuthContext } from '../contexts/auth';

const Feedback = () => {
  const { user } = useContext(AuthContext);
  const [selectedAgencies, setSelectedAgencies] = useState([]);
  const [isShowModalAddNew, setIsShowModalAddNew] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [initForm, setInitForm] = useState({});
  const [form] = Form.useForm();

  const { loading, error, data } = useQuery(FEEDBACKS, {
    variables: {
      filter: {}
    }
  });

  const [updateFeedback] = useMutation(UPDATE_FEEDBACK, {
    refetchQueries: [{ query: FEEDBACKS, variables: { filter: {} } }],
    onCompleted: (response) => {
      if (response) {
        setPageLoading(false);
        Modal.success({ title: 'Update feedback success! ' });
        setInitForm({});
      }
    },
    onError: () => {
      setPageLoading(false)
      Modal.error({ message: 'Update feedback fail' });
    }
  });

  if (error) {
    Modal.error({ message: error.message });
  }

  const handleUpdate = (feedback) => {
    setInitForm(feedback);
    setIsShowModalAddNew(true);
  };

  const closeModal = () => {
    setIsShowModalAddNew(false);
  }


  useEffect(() => {
    form.setFieldsValue({
      feedbackId: initForm.feedbackId,
      title: initForm.title,
      content: initForm.content,
      response: initForm.response,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initForm]);


  const columns = [
    {
      title: 'No',
      key: 'index',
      render: (_value, _item, index) => index + 1
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: 'Content',
      dataIndex: 'content',
      key: 'content',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Type',
      dataIndex: 'typeOfFeedback',
      key: 'typeOfFeedback',
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at) => moment(created_at).format('DD/MM/YYYY hh:mm:ss'),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_value, item) => {
        return <>
          <EditOutlined onClick={() => handleUpdate(item)} className='mr10' />
        </>;
      },
    },
  ];


  const onFinish = async (values) => {
    // Upload file
    setIsShowModalAddNew(false);
    setPageLoading(true);
    updateFeedback({
      variables: {
        updateFeedbacksInput: {
          feedbackId: initForm.feedbackId,
          status: 'CLOSE',
          response: values.response,
        }
      }
    });

  };

  const onFinishFailed = () => {
    return;
  };

  return <Spin tip="Loading..." spinning={pageLoading || loading}>
    <div className='post'>
      <div className='breadcrumb'>
        <Breadcrumb>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>Feedback</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className='body'>
        {user?.role === UserRoleConstant.ADMIN &&
          <Card className='flex justify-start my-4 mx-auto'>
            <AgencyFilter onSelect={(selecteds) => { setSelectedAgencies(selecteds) }} />
          </Card>
        }
        <div className='table'>
          <Table
            dataSource={
              (data?.feedbacks ?? [])
                .filter((feedback) =>
                  user?.role === UserRoleConstant.ADMIN ?
                    selectedAgencies.includes(feedback?.createdBy?.agencyId)
                    : true)
            } columns={columns} />
        </div>
      </div>
      <Modal
        forceRender
        title="Response feedback"
        visible={isShowModalAddNew}
        onOk={closeModal}
        onCancel={closeModal}
        bodyStyle={{
          height: 'auto',
          overflow: 'auto'
        }}
        footer={null}
      >
        <Form
          name="basic"
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 24 }}
          initialValues={initForm}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout='vertical'
          autoComplete="off"
        >
          <Form.Item
            label="Title"
            name="title"
          >
            <Input
              disabled
            />
          </Form.Item>
          <Form.Item
            label="Content"
            name="content"
          >
            <Input.TextArea
              disabled
            />
          </Form.Item>
          <Form.Item
            label="Response"
            name="response"
            rules={[{ required: true, message: 'Please input response!' }]}
          >
            <Input.TextArea
              rows={4}
            />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 20, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  </Spin>;
};

Feedback.propTypes = {};

export default Feedback;

import React from 'react';
import {Breadcrumb} from "antd";
import { useNavigate } from 'react-router-dom';

const BreadCrumbComponent = ({ defaultRoutes, route }) => {
  const navigate = useNavigate();
  const newRoutes = (routes) => {
    const lastPosition = routes.length - 1;
    const _routes = [...routes];
    if (!route.id) return ;
    if (routes.length > defaultRoutes.length) {
      _routes[lastPosition] = route
    }
    else {
      _routes.push(route)
    }
    return _routes;
  }
  
  let routeElements = [];
  
  if (newRoutes(defaultRoutes)) {
    routeElements = newRoutes(defaultRoutes).map((route) => {
      return (
        <Breadcrumb.Item key={route.id}>
          <span onClick={() => { navigate(route.path) }}>{ route.breadcrumbName }</span>
        </Breadcrumb.Item>
      )
    })
  }
  if (routeElements.length === 0) {
    routeElements = defaultRoutes.map((route) => {
      return (
        <Breadcrumb.Item key={route.id}>
          <span onClick={() => { navigate(route.path) }}>{ route.breadcrumbName }</span>
        </Breadcrumb.Item>
      )
    })
  }
  return (
    <Breadcrumb>
      { routeElements}
    </Breadcrumb>
  )
}

export default BreadCrumbComponent;

import React, { useState, useEffect } from 'react';

import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Spin,
} from 'antd';

import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
  CloudUploadOutlined,
} from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import {
  LIST_CLASS,
  REMOVE_CLASS,
  TEACHER_STUDENTS,
} from '../../graphql/class';
import BreadCrumbComponent from '../../components/breadcrumb/BreadCrumbComponent';
import { LIST_PROGRAM } from '../../graphql/programs';
import ProgramDetailComponent from './programs/ProgramDetailComponent';
// import ClassDetailComponent from './ClassDetailComponent';

const Programs = () => {
  const [isShowModalAddNew, setIsShowModalAddNew] = useState(false);
  const [initForm, setInitForm] = useState({});
  const [form] = Form.useForm();
  // const [teachers, setTeachers] = useState([]);
  // const [students, setStudents] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState(null);

  const defaultRoutes = [
    {
      id: -1,
      path: '/',
      breadcrumbName: 'Home',
    },
    {
      id: 0,
      path: 'programs',
      breadcrumbName: 'Programs',
    },
  ];
  const route = {
    id: selectedProgram?.programId,
    path: `program?programId=${selectedProgram?.programId}&name=${selectedProgram?.programName}`,
    breadcrumbName: selectedProgram?.programName,
  };
  // const [routes, setRoutes] = useState(defaultRoutes)
  let routes = defaultRoutes;
  const { data } = useQuery(TEACHER_STUDENTS);
  // const classData = useQuery(LIST_CLASS);
  const classData = useQuery(LIST_PROGRAM);
  console.log({ classData });
  // const [createClass] = useMutation(CREATE_CLASS, {
  //   refetchQueries: [{ query: LIST_CLASS }],
  //   onCompleted: (response) => {
  //     if (response && response.createClass) {
  //       setPageLoading(false);
  //       Modal.success({ title: 'Create success! ' });
  //       setInitForm({});
  //     }
  //   },
  //   onError: (_createError) => {
  //     setPageLoading(false);
  //     Modal.error({ message: 'Create student fail' });
  //   },
  // });
  // const [updateClass] = useMutation(UPDATE_CLASS, {
  //   refetchQueries: [{ query: LIST_CLASS }],
  //   onCompleted: (response) => {
  //     if (response && response.updateClass) {
  //       setPageLoading(false);
  //       Modal.success({ title: 'Update success! ' });
  //       setInitForm({});
  //     }
  //   },
  //   onError: (_createError) => {
  //     setPageLoading(false);
  //     Modal.error({ message: 'update class fail' });
  //   },
  // });
  const [removeClass] = useMutation(REMOVE_CLASS, {
    refetchQueries: [{ query: LIST_CLASS }],
    onCompleted: (response) => {
      if (response && response.removeClass) {
        setPageLoading(false);
        Modal.success({ title: 'Remove success! ' });
        setInitForm({});
      }
    },
    onError: (_createError) => {
      setPageLoading(false);
      Modal.error({ message: 'Remove fail' });
    },
  });

  useEffect(() => {
    if (data?.teachers) {
      // setTeachers(data?.teachers);
    }
    if (data?.students) {
      // setStudents(data?.students);
    }
  }, [data]);

  useEffect(() => {
    if (initForm && initForm.name) {
      form.setFieldsValue({
        name: initForm.name,
        description: initForm.description,
      });
    } else {
      form.setFieldsValue({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initForm]);

  const handleAddNew = () => {
    setInitForm({});
    form.resetFields();
    setIsShowModalAddNew(true);
  };

  const closeModal = () => {
    setIsShowModalAddNew(false);
  };

  const hanleDeleteClass = (id) => {
    Modal.confirm({
      title: 'Delete class?',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure delete this class?',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: () => {
        removeClass({
          variables: {
            classId: id,
          },
        });
      },
    });
  };

  const handleUpdateClass = (item) => {
    setInitForm(item);
    setIsShowModalAddNew(true);
  };

  const handleUploadClass = (item) => {
    console.log({ item });
  };

  const columns = [
    {
      title: 'No',
      key: 'index',
      render: (value, item, index) => index + 1,
    },
    {
      title: 'Name',
      dataIndex: 'programName',
      key: 'programName',
      render: (value, item, _) => {
        const handleClickOnClass = (e) => {
          e.preventDefault();
          setSelectedProgram(item);
        };
        return (
          <>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="src/pages/class/Class" onClick={handleClickOnClass}>
              {value}
            </a>
          </>
        );
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    // {
    //   title: 'Start at',
    //   dataIndex: 'startAt',
    //   key: 'startAt',
    //   render: (startAt) => moment(startAt).format('DD/MM/YYYY'),
    // },
    // {
    //   title: 'End at',
    //   dataIndex: 'endAt',
    //   key: 'endAt',
    //   render: (endAt) => moment(endAt).format('DD/MM/YYYY'),
    // },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (value, item) => {
        return (
          <>
            <CloudUploadOutlined
              onClick={() => handleUploadClass(item)}
              className="mr10"
            />
            <EditOutlined
              onClick={() => handleUpdateClass(item)}
              className="mr10"
            />
            <DeleteOutlined onClick={() => hanleDeleteClass(item.classId)} />
          </>
        );
      },
    },
  ];

  const onFinish = (values) => {
    // if (initForm.classId) {
    //   updateClass({
    //     variables: {
    //       updateClassInput: {
    //         classId: initForm.classId,
    //         ...values,
    //       },
    //     },
    //   });
    // } else {
    //   createClass({
    //     variables: {
    //       createClassInput: {
    //         ...values,
    //       },
    //     },
    //   });
    // }
    // setInitForm({});
    // setIsShowModalAddNew(false);
  };
  console.log({ selectedProgram });
  const onFinishFailed = () => {};
  const dataSource = (classData?.data?.programs ?? []).map((item) => ({
    ...item,
    key: item?.classId,
  }));
  const BodyClassComponent = () => {
    if (!selectedProgram) {
      return (
        <>
          <div className="add-new">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={handleAddNew}
            >
              Add New
            </Button>
          </div>
          <div className="table">
            <Table dataSource={dataSource} columns={columns} />
          </div>
        </>
      );
    }
    return <ProgramDetailComponent programId={selectedProgram.programId} />;
  };
  return (
    <Spin tip="Loading..." spinning={pageLoading}>
      <div className="post">
        <div className="breadcrumb">
          <BreadCrumbComponent
            routes={routes}
            defaultRoutes={defaultRoutes}
            route={route}
          />
        </div>
        {}
        <div className="body">
          <BodyClassComponent />
        </div>
        <Modal
          forceRender
          title={initForm.classId ? 'Update' : 'Add New'}
          visible={isShowModalAddNew}
          onOk={closeModal}
          onCancel={closeModal}
          bodyStyle={{
            height: 500,
            overflow: 'auto',
          }}
          footer={null}
        >
          <Form
            name="basic"
            form={form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 24 }}
            initialValues={initForm}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            autoComplete="off"
          >
            <Form.Item
              label="Name"
              name="programName"
              rules={[
                { required: true, message: 'Please input Program name!' },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              rules={[{ required: true, message: 'Please input description!' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 20, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </Spin>
  );
};

Programs.propTypes = {};

export default Programs;

import React, { useEffect, useState } from 'react';

import * as moment from 'moment';
import { Table, Spin, Card } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { LIST_CLASS } from '../../../../graphql/class';
import BreadCrumbComponent from '../../../../components/breadcrumb/BreadCrumbComponent';
import ClassDetailExperiencePage from './ClassDetail-ExperiencePage';
import AgencyFilter from '../../../../components/filters/AgencyFilter';
import { useContext } from 'react';
import { AuthContext } from '../../../../contexts/auth';
import { UserRoleConstant } from '../../../../constants';

const ClassExperiencePage = () => {
  const { user } = useContext(AuthContext);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedAgencies, setSelectedAgencies] = useState([]);
  let navigate = useNavigate();
  const defaultRoutes = [
    {
      id: -1,
      path: '/',
      breadcrumbName: 'Home',
    },
    {
      id: 0,
      path: '/class',
      breadcrumbName: 'Class',
    },

    {
      id: 0,
      path: '/class-experience',
      breadcrumbName: 'Experience',
    },
    // {
    //   id: 1,
    //   path: '/class/experience',
    //   breadcrumbName: 'Experience',
    // },
  ];
  const route = {
    id: selectedClass?.classId,
    path: `/class-experience-detail?classId=${selectedClass?.classId}&name=${selectedClass?.name}`,
    // path: `/class-experience/${selectedClass?.classId}`,
    breadcrumbName: selectedClass?.name,
  };
  useEffect(() => {
    if (selectedClass?.classId) {
      navigate(route.path);
    }
  }, [selectedClass]);
  // const [routes, setRoutes] = useState(defaultRoutes)
  let routes = defaultRoutes;
  const classData = useQuery(LIST_CLASS, {
    variables: {
      take: 50000,
    },
  });

  const columns = [
    {
      title: 'No',
      key: 'index',
      render: (value, item, index) => index + 1,
    },
    {
      title: 'Class Name',
      dataIndex: 'name',
      key: 'name',
      render: (value, item, _) => {
        const handleClickOnClass = (e) => {
          e.preventDefault();
          setSelectedClass(item);
        };
        return (
          <>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="src/pages/class/Class" onClick={handleClickOnClass}>
              {value}
            </a>
          </>
        );
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Start at',
      dataIndex: 'startAt',
      key: 'startAt',
      render: (startAt) => moment(startAt).format('DD/MM/YYYY'),
    },
    {
      title: 'End at',
      dataIndex: 'endAt',
      key: 'endAt',
      render: (endAt) => moment(endAt).format('DD/MM/YYYY'),
    },
  ];

  const BodyClassComponent = () => {
    if (!selectedClass) {
      return (
        <>
          <div className="table">
            <Table
              dataSource={(classData?.data?.classes ?? [])
                .filter((item) =>
                  user?.role === UserRoleConstant.ADMIN
                    ? selectedAgencies.includes(item?.agencyId)
                    : true,
                )
                .map((item) => ({
                  ...item,
                  key: item?.classId,
                }))}
              columns={columns}
            />
          </div>
        </>
      );
    }
    return <ClassDetailExperiencePage classId={selectedClass.classId} />;
  };
  return (
    <Spin tip="Loading..." spinning={false || classData?.loading}>
      <div className="post">
        <div className="breadcrumb">
          <BreadCrumbComponent
            routes={routes}
            defaultRoutes={defaultRoutes}
            route={route}
          />
        </div>
        {}
        <div className="body">
          {user?.role === UserRoleConstant.ADMIN && (
            <Card className="flex justify-start my-4 mx-auto">
              <AgencyFilter
                onSelect={(selecteds) => {
                  setSelectedAgencies(selecteds);
                }}
              />
            </Card>
          )}
          <BodyClassComponent />
        </div>
      </div>
    </Spin>
  );
};

ClassExperiencePage.propTypes = {};

export default ClassExperiencePage;

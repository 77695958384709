import React, { useState } from 'react';
import {
  Divider,
  Table
} from 'antd';
import * as moment from 'moment';
import {
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { groupBy, orderBy } from 'lodash';

const ScoresViewTable = ({
  scoreboards,
  classes,
  handleUpdate,
  handleRemove
}) => {
  const dataSource = () => {
    let datas = orderBy(scoreboards ?? [], ['classId', 'created_at'], ['asc', 'desc']);
    let rowSpanData = groupBy(datas, d => d.classId) ?? {};

    return datas.map((scoreboard) => {
      return {
        key: scoreboard.scoreBoardId,
        scoreBoardId: scoreboard.scoreBoardId,
        classInfo: classes.find(classInfo => classInfo.classId === scoreboard.classId),
        scores: scoreboard.scores,
        created_at: scoreboard.created_at,
        classId: scoreboard.classId,
        rowSpan: rowSpanData[scoreboard.classId]?.length ?? 0,
        rowSpanIndex: rowSpanData[scoreboard.classId]?.findIndex((rowSpan) => rowSpan?.scoreBoardId === scoreboard?.scoreBoardId) ?? 0,
      }
    })
  };

  const columns = [
    {
      title: 'Class name',
      dataIndex: ['classInfo', 'name'],
      key: 'className',
      render: (value, item) => {
        let obj = {
          children: value,
          props: {}
        };

        if (item.rowSpanIndex === 0) {
          obj.props.rowSpan = item.rowSpan;
        } else {
          obj.props.rowSpan = 0;
        }

        return obj;
      },
    },
    {
      title: 'Content',
      dataIndex: 'scores',
      key: 'content',
      render: (value) => {
        return <div>
          {
            value.map(dt => (
              <div key={dt.subject}>
                <div>Subject: {dt.subject}</div>
                <div>Score: {dt.score}</div>
                <Divider dashed style={{ margin: 0 }} />
              </div>
            ))
          }
        </div>
      }
    },
    {
      title: 'Average score',
      dataIndex: 'scores',
      key: 'averageScore',
      render: (value) => {
        return parseFloat(value.reduce((a, b) => a + b.score, 0) / value.length).toFixed(2)
      }
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at) => moment(created_at).format('DD/MM/YYYY hh:mm:ss'),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_value, item) => {
        return <>
          <EditOutlined onClick={() => handleUpdate(item)} className='mr10' />
          <DeleteOutlined onClick={() => handleRemove(item)} />
        </>;
      },
    },
  ];

  return <div>
    <div>
      {
        <Table dataSource={dataSource()} columns={columns} />
      }
    </div>
  </div>
};

ScoresViewTable.propTypes = {};

export default ScoresViewTable;

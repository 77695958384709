import React, { useRef, useState, useMemo } from 'react';
import JoditEditor from 'jodit-react';

const Editor = ({ value, onChange, readonly }) => {
  const editor = useRef(null);
  const token = localStorage.getItem('token');

  const [, setContent] = useState(value);
  const config = {
    height: 600,
    uploader: {
      url: `${process.env.REACT_APP_API_URL}/api/file/upload`,
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
      isSuccess: function (resp) {
        return resp;
      },
      error: function (e) {
        console.log(e);
      },
      process: function (resp) {
        console.log(JSON.stringify(resp));

        return {
          files: resp.data.files,
          path: resp.data.path,
          baseurl: resp.data.baseurl,
          error: resp.data.error,
          message: resp.data.message
        };
      },
      defaultHandlerSuccess: function (data) {
        console.log(JSON.stringify(data));

        let i, field = 'files';
        if (data[field] && data[field].length) {
          for (i = 0; i < data[field].length; i += 1) {
            this.selection.insertImage(data.baseurl + data[field][i]);
          }
        }
      },
    },
  };

  return useMemo(() => (
    <JoditEditor
      ref={editor}
      value={value}
      config={{
        ...config,
        readonly: readonly
      }}
      onChange={ct => {
        setContent(ct);
        onChange(ct);
      }}
    />
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [value]);
};

export default Editor;
import React, {memo, useEffect, useState} from 'react';
import { Button, Form, Input, Typography, message, Modal } from 'antd';
import queryString from 'query-string';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import { FORGOT_PASSWORD, LOGIN } from '../graphql/users';
import {HOME_ROUTE, UserRoleConstant} from '../constants';
import logo from '../images/logo.png';


const Login = memo(() => {
  document.title = 'NQH IELTS ADMIN PAGE';
  const [login, { loading, error, data }] = useLazyQuery(LOGIN);
  const [forgotPassword] = useMutation(FORGOT_PASSWORD, {
    onCompleted: (response) => {
      if (response) {
        Modal.success({ title: 'A email is send to your email, please check email! ' });
      }
    },
    onError: (_createError) => {
      Modal.error({ message: 'Fail' });
    }
  });
  
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  useEffect(() => {
    if (localStorage.getItem('token')) {
      navigate(HOME_ROUTE);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  if (error) {
    message.error(`Error! ${error}`);
  }

  if (data && data.authenticate?.accessToken) {
    const redirect = queryString.parse(window.location.search, {
      ignoreQueryPrefix: true,
    }).redirect;

    if (data.authenticate.role === UserRoleConstant.STUDENT) {
      message.error('Login fail');
    } else {
      localStorage.setItem('token', data.authenticate?.accessToken);
      localStorage.setItem('user', JSON.stringify({
        email: data.authenticate?.email,
        fullname: data.authenticate?.fullname,
        userId: data.authenticate?.userId,
        role: data.authenticate?.role
      }));
  
      window.location.href = redirect || window.location.origin;
    }
    
  }

  const formSubmit = (values) => {
    if (values?.email && values?.password) {
      login({
        variables: {
          input: {
            email: values.email,
            password: values.password
          }
        }
      });
    } else {
      message.error('Missing field');
    }
  };

  const onFinishForgotPassword = (values) => {
    if (values?.email) {
      forgotPassword({variables: { email: values.email }});
      return;
    }
    message.error('Missing field');
  }

  const onFinish = (values) => {
    formSubmit({
      ...values,
      email: values?.email.trim()
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  
  return (
    <div className="login">
      <div className='header'>
      </div>
      <div className='body'>
        <div className='logo'>
          <img src={logo} alt="" width={200}></img>
          {/* <h4 className='title'>NQH - IELTS</h4> */}
        </div>
        <div className='content'>
          <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 24 }}
            layout={'vertical'}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: 'Please input your email!' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item name="sfsdfsd" wrapperCol={{ offset: 0, span: 16 }}>
              <Typography.Paragraph
                style={{ color: 'blue', cursor: 'pointer'}}
                onClick={() => {
                  setShowForgotPasswordModal(true);
                }}
              >
                Forgot password?
              </Typography.Paragraph>
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 10, span: 16 }}>
              {
                !loading
                ?
                <Button type="primary" htmlType="submit">
                  Login
                </Button>
                :
                <Button type="primary" htmlType="submit" loading disabled>
                  Login
                </Button>
              }
            </Form.Item>
          </Form>
        </div>
      </div>
      <Modal
        title="Forgot password?"
        visible={showForgotPasswordModal}
        onOk={() => {
          form.submit();
          setShowForgotPasswordModal(false);
        }}
        onCancel={() => {
          setShowForgotPasswordModal(false);
        }}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 24 }}
          layout={'vertical'}
          initialValues={{ remember: true }}
          onFinish={onFinishForgotPassword}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Please input your email!' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
});

Login.propTypes = {
};

export default Login;

import { Button, Form, Modal, message, Upload } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useState } from 'react';

import { childExperiences_createExperiences } from '../../../../graphql/experience';
import { useMutation } from '@apollo/client';

import { InboxOutlined } from '@ant-design/icons';
import { CREATE_RESOURCE_EXPERIENCE } from '../../../../graphql/resourceMutations';
const { Dragger } = Upload;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);

    reader.onerror = (error) => reject(error);
  });

export const ModalExperienceComponent = ({
  selectedExperience,
  classId,
  showModalExperience,
  handleCloseModel,
}) => {
  const [createResource] = useMutation(CREATE_RESOURCE_EXPERIENCE);
  const [createExperiences] = useMutation(childExperiences_createExperiences);
  const [initForm, setInitForm] = useState({});
  const [form] = Form.useForm();
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]);

  React.useEffect(() => {
    if (showModalExperience) {
      if (selectedExperience.childExperienceId) {
        const images = selectedExperience.resourceUrls.map((url, index) => ({
          uid: index,
          name: url,
          status: 'done',
          url,
        }));
        setInitForm({
          description: selectedExperience.description,
          images,
        });
        form.setFieldsValue({
          description: selectedExperience.description,
          images,
        });
        setFileList(images);
      } else {
        setInitForm({});
        form.setFieldsValue({ description: null, images: null });
        setFileList([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModalExperience]);

  const onFinish = async (values) => {
    const resourceUrls = values?.images.map(({ url }) => url);
    const description = values?.description;
    const { error } = await createExperiences({
      variables: {
        createExperiencesInput: {
          childExperienceId: selectedExperience.childExperienceId ?? null,
          classId: classId,
          description,
          resourceUrls,
        },
      },
    });

    if (error) {
      message.error('Có lỗi upload, vui lòng liên hệ quản trị');
    }
    Promise.resolve()
      .then(() => {
        form.setFieldsValue({ description: null, images: null });
      })
      .then(() => {
        setFileList([]);
      })
      .then(() => {
        setInitForm({});
      })
      .then(() => {
        handleCloseModel();
      });
  };
  const closeModal = () => {
    Promise.resolve()
      .then(() => {
        form.setFieldsValue({ description: null, images: null });
      })
      .then(() => {
        setFileList([]);
      })
      .then(() => {
        setInitForm({});
      })
      .then(() => {
        handleCloseModel();
      });
  };
  const onFinishFailed = () => {};
  const onChangeDescribeExperience = (e) => {
    console.log('Change:', e.target.value);
  };

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    );
  };

  const props = {
    name: 'file',
    listType: 'picture-card',
    fileList,
    multiple: true,
    beforeUpload: async (file) => {
      const isLt60M = file.size / 1024 / 1024 < 60;
      if (!isLt60M) {
        message.error('File phải nhỏ hơn 60MB!');
        return;
      }
      const fileListWillUpload = fileList;
      const timeStamp = Date.now().toString();
      const fileName = `${timeStamp}-${file.name}`;
      fileListWillUpload.push({
        uid: timeStamp,
        status: 'uploading',
        name: fileName,
        url: '',
      });

      setFileList(fileListWillUpload);
      const {
        data: { experience },
      } = await createResource({
        variables: {
          file: file,
        },
      });
      const index = fileListWillUpload.findIndex(({ name }) =>
        name.includes(timeStamp.toString()),
      );

      if (index === -1) {
      } else {
        fileListWillUpload[index] = {
          uid: timeStamp,
          name: experience.fileName,
          status: 'done',
          url: experience.url,
        };
      }
      setFileList(fileListWillUpload);
      return false;
    },

    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
    onPreview: handlePreview,
    onChange: ({ fileList }) => {
      const newFileList = fileList.filter(({ status }) => status !== undefined);
      setFileList(newFileList);
      form.setFieldsValue({
        images: newFileList,
      });
      console.log('handleChange', newFileList);
    },
    onClose: () => {
      setFileList([]);
    },
  };
  console.log('render ', previewImage);
  return (
    <Modal
      destroyOnClose={true}
      forceRender
      title={
        selectedExperience.childExperienceId
          ? 'Edit Experience'
          : 'Add New Experience'
      }
      visible={showModalExperience}
      bodyStyle={{
        overflow: 'auto',
      }}
      onOk={closeModal}
      onCancel={closeModal}
      footer={null}
    >
      <Form
        preserve={false}
        name="basic"
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 24 }}
        initialValues={initForm}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item
          label="Description"
          name="description"
          rules={[{ required: true, message: 'Mô tả trải nghiệm của con' }]}
        >
          <TextArea
            showCount
            maxLength={100}
            style={{
              height: 120,
            }}
            onChange={onChangeDescribeExperience}
          />
        </Form.Item>
        <Form.Item
          label="Upload image/video"
          name="images"
          rules={[
            {
              required: true,
              message: 'Please input upload images or videos!',
            },
          ]}
        >
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">{'Support file < 60MB'}</p>
          </Dragger>
          <Modal
            visible={previewVisible}
            title={'Preview image/video'}
            footer={null}
            onCancel={handleCancel}
          >
            <div className="flex justify-center">
              {previewImage.toLowerCase().includes('.jpg') ||
              previewImage.toLowerCase().includes('.png') ? (
                <img
                  alt="example"
                  style={{
                    width: '100%',
                  }}
                  src={previewImage}
                />
              ) : (
                <video width="320" height="240" controls key={previewImage}>
                  <source
                    src={previewImage + `?t=${new Date()}`}
                    type="video/mp4"
                  />
                </video>
              )}
            </div>
          </Modal>
        </Form.Item>
        <Form.Item name="handle" wrapperCol={{ offset: 20, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

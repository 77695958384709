import React, { useState } from 'react';

import { Button, Form, Input, message, Modal, Select, Upload } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useMutation } from '@apollo/client';
import { UploadOutlined } from '@ant-design/icons';
import { LIST_MATERIALS, UPLOAD_MATERIALS } from '../../../graphql/class';

const AddMultipleMaterial = ({
  isShowAddMultipleMaterial,
  setIsShowAddMultipleMaterial,
  classId
}) => {
  const [form] = useForm();
  const [fileList, setFileList] = useState([]);
  const [files, setFiles] = useState([]);
  const [createMaterial] = useMutation(UPLOAD_MATERIALS, {
    refetchQueries: [{ query: LIST_MATERIALS, variables: { classId } }],
    onCompleted: () => {
      message.success('Success');
      form.setFieldsValue({
        category: '',
        fileName: ''
      });
    }
  });

  const onFinishAddScores = (values) => {
    setIsShowAddMultipleMaterial(false);
    files.forEach(file => {
      createMaterial({
        variables: {
          category: values.category,
          classId,
          file: file,
          fileName: file.name.split(".")[0]
        }
      });
    }) 

  }

  console.log("fileList ", fileList)

  const uploadFile = (file) => {
    setFiles(oldfiles =>[...oldfiles, file]);
    return true;
  }

  return <>
    <div>
      <Modal
        title="Add multiple materials"
        visible={isShowAddMultipleMaterial}
        onOk={() => {
          form.submit();
        }}
        onCancel={() => {
          setIsShowAddMultipleMaterial(false);
        }}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 24 }}
          layout={'vertical'}
          onFinish={onFinishAddScores}
          autoComplete="off"
        >
          <Form.Item
            label="Category"
            name="category"
            rules={[{ required: true, message: 'Missing category' }]}
          >
            <Select >
              <Select.Option value='reading'>Reading</Select.Option>
              <Select.Option value='speaking'>Speaking</Select.Option>
              <Select.Option value='listening'>Listening</Select.Option>
              <Select.Option value='writing'>Writing</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="File"
            name="file"
            rules={[{ required: true, message: 'Missing category' }]}
          >
            <Upload
              multiple={true}
              fileList={fileList}
              action={uploadFile} 
              onChange={(dt) => {
                dt.fileList.forEach(fl => fl.status = 'done');

                setFileList(dt.fileList);
              }}
            >
              <Button icon={<UploadOutlined />}>Click to Uploads</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  </>
};

AddMultipleMaterial.propTypes = {};

export default AddMultipleMaterial;

import { useLazyQuery, useQuery } from "@apollo/client";
import { DatePicker, Space, Table } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { COMMENT_OF_TEACHER_STUDENT_REPORT, DAILY_REPORT_STUDENT_REPORT } from "../../graphql/student-report";
import * as moment from 'moment';
import { GET_CLASS_NAME_LIST } from "../../graphql/class";

export const StudentReportTableType = {
    DailyReport: 'DailyReport',
    CommentOfTeacher: 'CommentOfTeacher'
};

const QueryName = {
    [StudentReportTableType.DailyReport]: DAILY_REPORT_STUDENT_REPORT,
    [StudentReportTableType.CommentOfTeacher]: COMMENT_OF_TEACHER_STUDENT_REPORT
};

const pageSizeOptions = [10, 20, 30, 40, 50];

const StudentReportTable = ({ tableType }) => {
    const defaulDate = moment().toDate();
    const { data: classList } = useQuery(GET_CLASS_NAME_LIST);
    const [loadStudentReport, { data, loading }] = useLazyQuery(QueryName[tableType], {
        fetchPolicy: 'no-cache',
    });
    const [filters, setFilters] = useState({
        filters: {
            created_at: defaulDate
        },
        paging: {
            page: 1,
            pageSize: pageSizeOptions[0]
        },
        sorters: null,
        countToForceLoadData: 0
    });

    // const reloadData = () => {
    //     setFilters(filters => ({
    //         ...filters,
    //         countToForceLoadData: filters?.countToForceLoadData + 1
    //     }));
    // };

    const columns = [
        {
            title: 'No',
            key: 'index',
            render: (value, data, index) => index + (filters?.paging?.page - 1 < 0 ? 0 : filters?.paging?.page - 1) * filters?.paging?.pageSize + 1
        },
        {
            title: 'Class',
            dataIndex: 'className',
            key: 'className',
            filter: true,
            filters: classList?.classes?.map(className => ({ value: className, text: className })),
        },
        {
            title: 'Student',
            dataIndex: 'studentName',
            key: 'studentName',
            render: (value, data, index) => <Link target="_blank" to={`/student/${data?.studentId}`}>{value}</Link>
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            filter: true,
            filters: [{ value: 'Evaluated', text: 'Evaluated' },{ value: 'UnEvaluated', text: 'UnEvaluated' }],
        },
    ];

    useEffect(() => {
        loadStudentReport({
            variables: {
                filters: {
                    ...filters,
                    countToForceLoadData: undefined
                }
            }
        });

        // eslint-disable-next-line
    }, [filters]);

    return (
        <>
            <Space className='add-new'>
                <DatePicker
                    allowClear={false}
                    showNow={true}
                    defaultValue={moment(defaulDate)}
                    onChange={(value) => {
                        setFilters(filters => ({
                            ...filters,
                            filters: {
                                ...filters?.filters,
                                created_at: value.toDate()
                            }
                        }));
                    }} />
            </Space>
            <div className='table'>
                <Table
                    loading={loading}
                    columns={columns}
                    pagination={{
                        current: filters?.paging?.page,
                        pageSize: filters?.paging?.pageSize,
                        pageSizeOptions: pageSizeOptions,
                        showSizeChanger: true,
                        total: data?.studentReports?.total
                    }}
                    onChange={(pagination, paramFilters, sorter) => {
                        setFilters(filters => ({
                            ...filters,
                            filters: {
                                ...filters.filters,
                                ...paramFilters
                            },
                            paging: {
                                page: pagination.current,
                                pageSize: pagination.pageSize,
                            },
                            sorters: sorter?.field ?
                                {
                                    [sorter?.field]: sorter?.order === 'ascend' ? 'asc'
                                        : sorter?.order === 'descend' ? 'desc' : null
                                } : null,
                            countToForceLoadData: filters.countToForceLoadData + 1
                        }));
                    }}
                    dataSource={data?.studentReports?.data || []}
                    rowKey={'id'}
                />
            </div>
        </>
    );
};

export default StudentReportTable;
import { ArrowLeftOutlined, DeleteOutlined, EditOutlined, EyeInvisibleOutlined, EyeOutlined, ReloadOutlined } from "@ant-design/icons";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Button, message, Modal, Space, Table } from "antd";
import moment from 'moment';
import { useEffect, useState } from "react";
import { LOAD_EXAMS, REMOVE_EXAM, UPDATE_EXAM_STATUS } from "../../../../graphql/exam";
import { ExamStatus, LoadContentType, Mode } from "./const";
import ExamDetail from "./ExamDetail";

const Exam = ({ classId }) => {
    const [loadContentType, setLoadContentType] = useState(LoadContentType.default);
    const [loadContent, setLoadContent] = useState({
        examId: null,
        mode: null,
    });
    const [loadExam, { data, loading }] = useLazyQuery(LOAD_EXAMS, {
        variables: {
            filter: {
                classId,
            }
        },
        fetchPolicy: 'no-cache',
    });
    const [updateExamStatus, { loading: updatingExamStatus }] = useMutation(UPDATE_EXAM_STATUS, {
        onCompleted: (res) => {
            message.success('Updated exam status successfully!');
        },
        onError: (err) => {
            message.success('Updated exam status failed!');
        },
    });
    const [removeExam, { loading: removingExam }] = useMutation(REMOVE_EXAM, {
        onCompleted: (res) => {
            message.success('Removed exam successfully!');
        },
        onError: (err) => {
            message.success('Removed exam failed!');
        },
    });
    const columns = [
        {
            title: 'No',
            key: 'index',
            render: (value, item, index) => {
                return (
                    <>
                        {index + 1}
                    </>
                );
            },
            width: '10%'
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (value, item, index) => {
                return (
                    <>
                        <Button
                            type='link'
                            title='Click to view.'
                            onClick={() => viewExam(item?.examId)}
                        >{value}</Button>
                    </>
                );
            },
        },
        {
            title: 'Brief.Desc',
            dataIndex: 'shortDescription',
            key: 'shortDescription',
        },
        {
            title: 'Question',
            dataIndex: 'totalQuestion',
            key: 'totalQuestion',
        },
        {
            title: 'Duration(minutes)',
            dataIndex: 'limitedTime',
            key: 'limitedTime',
        },
        // {
        //     title: 'Start Date',
        //     dataIndex: 'startDate',
        //     key: 'startDate',
        //     render: (value, item, index) => {
        //         return (
        //             <>
        //                 {value && moment(value).format(DateFormat)}
        //             </>
        //         );
        //     },
        // },
        // {
        //     title: 'End Date',
        //     dataIndex: 'endDate',
        //     key: 'endDate',
        //     render: (value, item, index) => {
        //         return (
        //             <>
        //                 {value && moment(value).format(DateFormat)}
        //             </>
        //         );
        //     },
        // },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: '10%',
            render: (value, item) => {
                return (
                    <>
                        {item?.status === ExamStatus.Active ?
                            <EyeOutlined onClick={() => switchExamStatus(item?.examId, ExamStatus.InActive)} /> :
                            <EyeInvisibleOutlined onClick={() => switchExamStatus(item?.examId, ExamStatus.Active)} />}
                        <EditOutlined className='ml10' onClick={() => editExam(item?.examId)} />
                        {!item?.lockedQuestion && <DeleteOutlined className='ml10' onClick={() => deleteExam(item?.examId)} />}
                    </>
                );
            },
        },
    ];

    const switchExamStatus = (examId, status) => {
        if (examId) {
            const action = status === ExamStatus.Active ? 'active' : 'inactive';
            const modalTitle = 'Confirmation';
            const modalContent = `Are you sure you want to ${action} this exam?`;

            Modal.confirm({
                title: modalTitle,
                content: modalContent,
                okText: 'Yes',
                cancelText: 'No',
                onOk: () => {
                    updateExamStatus({
                        variables: {
                            updateExamStatusInput: {
                                examId,
                                status,
                            }
                        },
                        onCompleted: (res) => {
                            loadExam();
                        }
                    });
                }
            });
        }
    };

    const deleteExam = (examId) => {
        if (examId) {
            const action = 'delete';
            const modalTitle = 'Confirmation';
            const modalContent = `Are you sure you want to ${action} this exam?`;

            Modal.confirm({
                title: modalTitle,
                content: modalContent,
                okText: 'Yes',
                cancelText: 'No',
                onOk: () => {
                    removeExam({
                        variables: {
                            id: examId,
                        },
                        onCompleted: (res) => {
                            loadExam();
                        }
                    });
                }
            });
        }
    }

    const viewExam = (examId) => {
        setLoadContentType(LoadContentType.detail);
        setLoadContent({
            ...loadContent,
            examId: examId,
            mode: Mode.View
        });
    };

    const addExam = () => {
        setLoadContentType(LoadContentType.detail);
        setLoadContent({
            ...loadContent,
            examId: null,
            mode: Mode.Add,
            returnFunc: () => {
                setLoadContentType(LoadContentType.default);
            }
        });
    };

    const editExam = (examId) => {
        setLoadContentType(LoadContentType.detail);
        setLoadContent({
            ...loadContent,
            examId: examId,
            mode: Mode.Edit,
            returnFunc: () => {
                setLoadContentType(LoadContentType.default);
            }
        });
    };

    useEffect(() => {
        if (loadContentType === LoadContentType.default) {
            loadExam();
        }
    }, [loadContentType]);

    return <>
        <div className='exam'>
            <Space className="exam-btn-actions">
                {
                    loadContentType !== LoadContentType.default &&
                    <>
                        <Button
                            type='primary'
                            onClick={() => {
                                setLoadContentType(LoadContentType.default);
                            }}
                        >
                            <ArrowLeftOutlined />
                        </Button>
                    </>
                }
                {
                    loadContentType === LoadContentType.default &&
                    <>
                        <Button
                            type='primary'
                            onClick={addExam}
                        >
                            Add exam
                        </Button>
                        <Button
                            type='primary'
                            onClick={() => {
                                loadExam();
                            }}
                        >
                            <ReloadOutlined />
                        </Button>
                    </>
                }
            </Space>
            <div className="table">
                {
                    loadContentType === LoadContentType.default &&
                    <>
                        <Table
                            loading={{
                                spinning: loading || updatingExamStatus || removingExam,
                                tip: (<>Loading</>)
                            }}
                            dataSource={data?.exams || [
                                {
                                    examId: 'dddd',
                                    title: 'Test',
                                    startDate: moment(),
                                    endDate: moment().add('day', 3),
                                }
                            ]} columns={columns} />
                    </>
                }
                {
                    loadContentType === LoadContentType.detail &&
                    <>
                        <ExamDetail classId={classId} {...loadContent} />
                    </>
                }
            </div>
        </div>
    </>;
};

export default Exam;
import { CheckOutlined, CloseOutlined, DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Button, message, Modal, Space, Spin, Table, Typography, Upload } from "antd";
import { useState } from "react";
import { IMPORT_DATA } from "../../graphql/dataImport";

export const ImportType = {
    StudentImport: 'StudentImport',
    StudyProgramImport: 'StudyProgramImport',
};

const CloseTimeOut = 3000; //unit milliseconds

const ImportTemplate = {
    [ImportType.StudentImport]: `${(process.env.REACT_APP_API_HOST ?? "http://localhost:3000/")}templates/Import_Student_Template_20230222.xlsx`,
    [ImportType.StudyProgramImport]: `${(process.env.REACT_APP_API_HOST ?? "http://localhost:3000/")}templates/Import_StudyProgram_Template_20230222.xlsx`,
};

const ImportResultColumns = {
    [ImportType.StudentImport]: [
        {
            title: 'ID',
            dataIndex: 'index',
            key: 'index',
            render: (_, item, index) => {
                return <>{index + 1}</>;
            }
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: 'Fullname',
            dataIndex: 'fullname',
            key: 'fullname'
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone'
        },
        {
            title: 'Parent Name',
            dataIndex: 'parentName',
            key: 'parentName'
        },
        {
            title: 'Parent Phone',
            dataIndex: 'parentPhone',
            key: 'parentPhone'
        },
        {
            title: 'Classes',
            dataIndex: 'classes',
            key: 'classes'
        },
        {
            title: 'Agency ID',
            dataIndex: 'agencyId',
            key: 'agencyId'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (value, item, index) => {
                if (value) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                }
                else {
                    return <CloseOutlined style={{ color: 'red' }} />
                }
            }
        },
        {
            title: 'Error',
            dataIndex: 'error',
            key: 'error'
        },
    ],
};

const ImportModal = (props) => {
    const [fileList, setFileList] = useState([]);
    const [resultData, setResultData] = useState([]);
    const [importData, { loading, data }] = useMutation(IMPORT_DATA);
    const defaultColumns = [
        {
            title: 'ID',
            dataIndex: 'index',
            key: 'index',
            render: (_, item, index) => {
                return <>{index + 1}</>;
            }
        },
        {
            title: 'Contect',
            dataIndex: 'content',
            key: 'content'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (value, item, index) => {
                if (value) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                }
                else {
                    return <CloseOutlined style={{ color: 'red' }} />
                }
            }
        },
    ];

    const handleImportData = (file) => {
        Modal.confirm({
            title: 'Import Confirmation',
            content: 'Are you sure you want to import data from this file?',
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => {
                importData({
                    variables: {
                        createDataImportInput: {
                            dataImportType: props?.importType
                        },
                        file: file
                    },
                    onCompleted: (res) => {
                        let modalTitle = 'Import Information';
                        let modalContent = `Imported successfully!(Auto close after ${CloseTimeOut / 1000}s)`;
                        if (!res?.importData?.status) {
                            modalContent = 'Imported not completed on all data!';
                        }
                        const informModal = Modal.info({
                            title: modalTitle,
                            width: 600,
                            content:
                                (<>
                                    <Space direction='vertical'>
                                        <Typography.Text>{modalContent}</Typography.Text>
                                        <Typography.Text>For details, refering below file:</Typography.Text>
                                        <Button style={{ whiteSpace: 'normal' }} type='link' href={res?.importData?.fileResultUrl}>{res?.importData?.fileResultName}</Button>
                                    </Space>
                                </>),
                            onOk: () => {
                                typeof props?.callback === 'function' && props?.callback();
                            }
                        })
                        setFileList([]);
                        setResultData(res?.importData);
                        if (res?.importData?.status) {
                            setTimeout(() => {
                                informModal.destroy();
                                typeof props?.callback === 'function' && props?.callback();
                            }, CloseTimeOut);
                        }
                    },
                    onError: (err) => {
                        message.error('Imported failed!');
                    }
                });
            }
        });
    };

    return <>
        <Modal
            {...props}
            onCancel={() => {
                setFileList([]);
                setResultData([]);
                typeof props?.onCancel === 'function' && props?.onCancel();
            }}
            cancelText='Close'
            okButtonProps={{
                hidden: true
            }}
        >
            <Spin
                spinning={loading}
                tip='Importing...'
            >
                <Space style={{ width: '100%' }} align='center' direction='vertical'>
                    <Button
                        href={ImportTemplate[props?.importType]}
                        icon={<DownloadOutlined />}>Download Template</Button>
                    <Upload
                        fileList={fileList}
                        action={handleImportData}
                    >
                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>
                    {/* <Table
                        loading={{
                            spinning: loading,
                            tip: 'Importing...'
                        }}
                        title={() => <>{`Import Result`}</>}
                        columns={ImportResultColumns[props?.importType] || defaultColumns}
                        dataSource={resultData}
                    /> */}
                </Space>
            </Spin>
        </Modal>
    </>;
};

export default ImportModal;
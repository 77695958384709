import React, { useState, useContext, useEffect } from 'react';
import { Layout, Menu, Avatar } from 'antd';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { UserOutlined } from '@ant-design/icons';

import logo from '../images/logo.png';
import { AuthContext } from '../contexts/auth';
import { StorageHelper } from '../utils/storage.helper';
import { PagingType } from '../constants/paging';

const { Content, Sider } = Layout;
const DEFAULT_MENU = 'student';
const items = [
  {
    label: 'Teachers',
    key: 'teachers',
  },
  {
    label: 'Student',
    key: 'student',
  },
  {
    label: 'Class',
    key: 'class',
  },
  {
    label: 'Experience',
    key: 'class-experience',
  },
  {
    label: 'Study program',
    key: 'study-program',
  },
  {
    label: 'Smart question',
    key: 'smart-questions',
  },
  {
    label: 'Agencies',
    key: 'agencies',
  },
  {
    label: 'News',
    key: 'news',
  },
  {
    label: 'Feedbacks',
    key: 'feedbacks',
  },
  {
    label: 'Statistics',
    key: 'statistics',
  },
  {
    label: 'Student Report',
    key: 'student-report',
  },
  {
    label: 'Logout',
    key: 'logout',
  },
];

const itemTeachers = [
  {
    label: 'Student',
    key: 'student',
  },
  {
    label: 'Class',
    key: 'class',
  },
  {
    label: 'Experience',
    key: 'class-experience',
  },
  {
    label: 'Student Report',
    key: 'student-report',
  },
  {
    label: 'Logout',
    key: 'logout',
  },
];

const itemAgencyAdmins = [
  {
    label: 'Student',
    key: 'student',
  },
  {
    label: 'Teacher',
    key: 'teachers',
  },
  {
    label: 'Class',
    key: 'class',
  },
  {
    label: 'Experience',
    key: 'class-experience',
  },
  {
    label: 'Study program',
    key: 'study-program',
  },
  {
    label: 'Smart question',
    key: 'smart-questions',
  },
  {
    label: 'Agencies',
    key: 'agencies',
  },
  {
    label: 'News',
    key: 'news',
  },
  {
    label: 'Feedbacks',
    key: 'feedbacks',
  },
  {
    label: 'Statistics',
    key: 'statistics',
  },
  {
    label: 'Student Report',
    key: 'student-report',
  },
  {
    label: 'Logout',
    key: 'logout',
  },
];

const menu = {
  admin: items,
  teacher: itemTeachers,
  agency_admin: itemAgencyAdmins,
};
const LayoutPage = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const { user, authDispatch } = useContext(AuthContext);
  const [current, setCurrent] = useState(DEFAULT_MENU);
  const navigate = useNavigate();
  useEffect(() => {
    const currentUrl = window.location.href;
    const urlParts = currentUrl.split('/');
    const menuKeySelected = urlParts.pop();
    if (!!menuKeySelected && current !== menuKeySelected) {
      setCurrent(menuKeySelected);
    }
    // const menuKeySelected = urlParts.pop(); //.split('?')[0];
    // console.log('menuKeySelected', menuKeySelected);

    // if (!!menuKeySelected && current !== menuKeySelected) {
    //   if (menuKeySelected === 'class-detail') {
    //     setCurrent('class');
    //     return;
    //   }
    //   setCurrent(menuKeySelected);
    // }
  }, [window.location.href]);
  const onCollapse = (isCollapsed) => {
    setCollapsed(isCollapsed);
  };

  const handleClick = (e) => {
    if (e.key === 'logout') {
      authDispatch({
        type: 'logout',
      });
      navigate(`/login`);
      return;
    }
    if (e.key === 'class') {
      StorageHelper.removePaging(PagingType.class);
    }
    window.location.replace(`/${e.key}`);
  };

  const handleClickProfile = () => {
    navigate('/profile');
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        className={'sider'}
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
      >
        <div>
          <div className="sider__logo">
            <img src={logo} width={150} alt="logo"></img>
          </div>
          <Menu
            className={'sider__menu'}
            items={menu[user.role]}
            defaultSelectedKeys={[DEFAULT_MENU]}
            selectedKeys={[current]}
            mode="inline"
            theme="dark"
            onClick={handleClick}
          ></Menu>
        </div>
      </Sider>

      <Layout>
        <Content className="site-layout">
          <div>
            <div className="sider__user">
              <div
                className="sider__user__content"
                onClick={handleClickProfile}
              >
                {user?.avatar ? (
                  <Avatar size={'medium'} src={user.avatar} />
                ) : (
                  <Avatar size={'medium'} icon={<UserOutlined />} />
                )}
                <div className="sider__user__content__info">
                  <span>{user?.fullname}</span>
                </div>
              </div>
            </div>
          </div>
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

LayoutPage.propTypes = {
  children: PropTypes.node,
  selectedKey: PropTypes.string,
};

export default LayoutPage;

import React, { useState } from 'react';

import { Button, Modal, Checkbox, message } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from '@ant-design/icons';

import { useMutation } from '@apollo/client';
import {
  ADD_DAILY_REPORT,
  REMOVE_DAILY_REPORT,
  STUDENT_BY_ID,
  UPDATE_DAILY_REPORT,
  UPDATE_IS_SHOW_DAILY_REPORT,
} from '../graphql/student';
import { useForm } from 'antd/lib/form/Form';
import LoveIcon from '../images/loveIcon.png';
import SoSadIcon from '../images/soSadIcon.png';
import NormalIcon from '../images/normalIcon.png';
import HappyIcon from '../images/happyIcon.png';
import * as moment from 'moment';
import { UpdateDailyReportStudentModal } from './modals/UpdateDailyReportStudentModal';

const DailyReport = ({ studentId, dailyReports, classes, setSpinning }) => {
  const [isShowAddDailyReport, setIsShowAddDailyReport] = useState(false);
  const [updateDailyReport, setUpdateDailyReport] = useState();
  const [form] = useForm();

  const [addDailyReport] = useMutation(ADD_DAILY_REPORT, {
    refetchQueries: [{ query: STUDENT_BY_ID, variables: { id: studentId } }],
    onCompleted: () => {
      message.success('Success');
      resetForm();
      setSpinning(false);
    },
  });

  const [updateReport] = useMutation(UPDATE_DAILY_REPORT, {
    refetchQueries: [{ query: STUDENT_BY_ID, variables: { id: studentId } }],
    onCompleted: () => {
      message.success('Success');
      resetForm();
      setUpdateDailyReport();
      setSpinning(false);
    },
  });

  const [deleteReport] = useMutation(REMOVE_DAILY_REPORT, {
    refetchQueries: [{ query: STUDENT_BY_ID, variables: { id: studentId } }],
    onCompleted: () => {
      message.success('Success');
      resetForm();
      setSpinning(false);
    },
  });

  const [updateIsShow] = useMutation(UPDATE_IS_SHOW_DAILY_REPORT, {
    refetchQueries: [{ query: STUDENT_BY_ID, variables: { id: studentId } }],
    onCompleted: () => {
      message.success('Success');
      resetForm();
      setSpinning(false);
    },
  });

  const resetForm = () => {
    form.setFieldsValue({
      lessonContent: '',
      reports: [],
      classId: '',
    });
  };

  const onFinishAddDailyReport = (values) => {
    setIsShowAddDailyReport(false);
    setSpinning(true);
    if (updateDailyReport) {
      updateReport({
        variables: {
          updateDailyReportInput: {
            dailyReportId: updateDailyReport,
            lessonContent: values.lessonContent,
            reports: values.reports,
            classId: values.classId,
          },
        },
      });

      setUpdateDailyReport('');
      form.setFieldsValue({
        lessonContent: '',
        reports: [],
        classId: '',
      });
      return;
    }
    addDailyReport({
      variables: {
        createDailyReportInput: {
          lessonContent: values.lessonContent,
          reports: values.reports,
          studentId,
          classId: values.classId,
        },
      },
    });
  };

  const handleUpdateIsShow = (dailyReportId) => {
    updateIsShow({
      variables: {
        dailyReportId: dailyReportId,
      },
    });
  };
  const handleAdd = () => {
    form.setFieldsValue({
      lessonContent: classes?.[0]?.name,
      reports: [
        {
          subject: classes?.[0]?.name,
          description: '',
          score: 4,
        },
      ],
      classId: classes?.[0]?.classId,
    });
    setUpdateDailyReport();
    setIsShowAddDailyReport(true);
  };
  const handleUpdate = (dailyReport) => {
    form.setFieldsValue({
      lessonContent: dailyReport.lessonContent,
      reports: dailyReport.reports,
      classId: dailyReport.classId,
    });
    setUpdateDailyReport(dailyReport.dailyReportId);
    setIsShowAddDailyReport(true);
  };
  const handleRemove = (dailyReport) => {
    Modal.confirm({
      title: 'Delete student?',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure delete this report?',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: () => {
        deleteReport({
          variables: {
            dailyReportId: dailyReport.dailyReportId,
          },
        });
      },
    });
  };
  return (
    <div>
      <div className="score-container">
        <h3>Daily Report</h3>
        <Button type="primary" onClick={() => handleAdd()}>
          Add report
        </Button>
        <UpdateDailyReportStudentModal
          form={form}
          setIsShowAddDailyReport={setIsShowAddDailyReport}
          onFinishAddDailyReport={onFinishAddDailyReport}
          classes={classes}
          isShowAddDailyReport={isShowAddDailyReport}
          updateDailyReport={updateDailyReport}
        />
      </div>
      <div>
        {dailyReports?.map((dailyReport, index) => {
          return (
            <div>
              <div
                key={dailyReport.dailyReportId}
                style={{ font: 'bold', fontSize: 20, marginTop: 20 }}
              >
                Class:{' '}
                {
                  classes.find(({ classId }) => classId === dailyReport.classId)
                    ?.name
                }
              </div>
              <div key={`dailyreport${index}`} className="mt-10">
                <div>
                  <span className="font-bold">Created at:</span>{' '}
                  {moment(dailyReport.created_at).format('DD/MM/YYYY hh:mm:ss')}
                </div>
                <div className={`flex`}>
                  <div className="dailyreport-detail">
                    <div className="lesson">{dailyReport.lessonContent}</div>
                    {dailyReport.reports.map((report) => (
                      <div className="report">
                        <div className="report-head">
                          <div className="report-subject">{report.subject}</div>
                          {/*<div className="report-short">*/}
                          {/*  {report.shortDescription}*/}
                          {/*</div>*/}
                          <div className="report-desc">
                            {report.description}
                          </div>
                        </div>
                        <div>
                          <div className="report-score">
                            <Checkbox
                              checked={report.score === 4 ? true : false}
                            />
                            <img
                              src={LoveIcon}
                              width={20}
                              height={20}
                              alt="logo"
                            ></img>
                          </div>
                          <div className="report-score">
                            <Checkbox
                              checked={report.score === 3 ? true : false}
                            />
                            <img
                              src={HappyIcon}
                              width={20}
                              height={20}
                              alt="logo"
                            ></img>
                          </div>
                          <div className="report-score">
                            <Checkbox
                              checked={report.score === 2 ? true : false}
                            />
                            <img
                              src={NormalIcon}
                              width={20}
                              height={20}
                              alt="logo"
                            ></img>
                          </div>
                          <div className="report-score">
                            <Checkbox
                              checked={report.score === 1 ? true : false}
                            />
                            <img
                              src={SoSadIcon}
                              width={20}
                              height={20}
                              alt="logo"
                            ></img>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="dailyreport-action">
                    {dailyReport.isShow ? (
                      <EyeOutlined
                        onClick={() =>
                          handleUpdateIsShow(dailyReport.dailyReportId)
                        }
                        className="mr10"
                      />
                    ) : (
                      <EyeInvisibleOutlined
                        onClick={() =>
                          handleUpdateIsShow(dailyReport.dailyReportId)
                        }
                        className="mr10"
                      />
                    )}
                    <EditOutlined
                      onClick={() => handleUpdate(dailyReport)}
                      className="mr10"
                    />
                    <DeleteOutlined onClick={() => handleRemove(dailyReport)} />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

DailyReport.propTypes = {};

export default DailyReport;

import gql from 'graphql-tag';

export const TEACHERS = gql`
  query teachers($take: Float, $skip: Float, $search: String) {
    teachers: teachers_findAll(take: $take, skip: $skip, search: $search) {
      userId
      fullname
      avatar
      phone
      email
      country
      language
      agencyId
      agencies {
        agencyId
      }
      createdById
      created_at
      createdBy {
        userId
        email
        fullname
      }
    }
  }
`;

export const CREATE_TEACHER = gql`
  mutation createTeacher($createTeacherInput: CreateTeacherInput!) {
    createTeacher(createTeacherInput: $createTeacherInput) {
      userId
      fullname
      avatar
      phone
      email
      country
      language
      createdById
      created_at
      createdBy {
        userId
        email
        fullname
      }
    }
  }
`;

export const UPDATE_TEACHER = gql`
  mutation updateTeacher($updateTeacherInput: UpdateTeacherInput!) {
    updateTeacher(updateTeacherInput: $updateTeacherInput) {
      userId
      fullname
      avatar
      phone
      email
      country
      language
      createdById
      created_at
      createdBy {
        userId
        email
        fullname
      }
    }
  }
`;

export const REMOVE_TEACHER = gql`
  mutation removeTeacher($userId: String!) {
    removeTeacher(userId: $userId) {
      userId
    }
  }
`;

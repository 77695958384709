import { CheckOutlined } from "@ant-design/icons";
import { useLazyQuery } from "@apollo/client";
import { Card, Divider, Form, Input, Row, Space, Typography } from "antd";
import { useEffect } from "react";
import { LOAD_STUDENT_EXAM } from "../../../../graphql/exam";
import { Mode } from "./const";

const StudentExamDetail = ({ studentId, examId, mode }) => {
  const [form] = Form.useForm();
  const [loadStudentExam, { data, loading: serverLoadingData }] = useLazyQuery(LOAD_STUDENT_EXAM, {
    variables: {
      studentId,
      examId,
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if ((mode === Mode.Edit || mode === Mode.View) && examId && studentId) {
      loadStudentExam({
        onCompleted: (res) => {
          console.log('res?.studentExam ', res?.studentExam)
          form.setFieldsValue(res?.studentExam ?? {});
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, examId, studentId]);

  return <>
    <Card
      loading={serverLoadingData}
      title='Exam Result'
      actions={<></>}
    >
      <Space direction='vertical'>
        <Space direction='vertical'>
          <Typography.Title level={3}>{data?.studentExam?.title}</Typography.Title>
        </Space>
        <Space direction='vertical'>
          <Typography.Text type='secondary'>Description</Typography.Text>
          <span dangerouslySetInnerHTML={{ __html: data?.studentExam?.description }} />
        </Space>
        <Space direction='vertical'>
          <Typography.Text type='secondary'>Result</Typography.Text>
          <Typography>{data?.studentExam?.correctQuestion ?? 0}/{data?.studentExam?.totalQuestion ?? 0} Questions</Typography>
        </Space>
      </Space>
      <Form
        className={mode === Mode.View ? 'student-exam-detail-readonly' : ''}
        form={form}
        layout='horizontal'
        disabled={mode === Mode.View}
        onFinish={() => { }}
      >
        <Form.Item name='studentExamId' hidden><Input /></Form.Item>
        <Form.List name='questions'>
          {(fields) =>
            <>
              {fields.map((field, index) => {
                const question = form.getFieldValue(['questions', field.name]);
                console.log('question ', question)
                return <>
                  <Card className='mb-2' title={`Question ${index + 1}`}>
                    <Row align='top'>
                      <Typography.Text>{form.getFieldValue(['questions', field.name, 'title'])}</Typography.Text>
                    </Row>
                    <Divider className='my-2' />
                    <Row>
                      {
                        question.questionType === 'SingleChoice'
                          ?
                          <Form.List name={[field.name, 'selectedResponses']}>
                            {(selectedFields) =>
                              <>
                                <Space direction='vertical'>
                                  {selectedFields.map((selectedField, selectedFieldIndex) => {
                                    return <>
                                      <Row className={'flex item-center'}>
                                        <Typography.Text className="flex items-center">
                                          {
                                            `${selectedFieldIndex + 1}. `
                                          }
                                          <Divider type='vertical' />
                                          <div
                                            className={question.selectedResponseId === form.getFieldValue(['questions', field.name, 'selectedResponses', selectedField.name, 'selectedResponseId']) && 'font-bold'}
                                          >
                                            {form.getFieldValue(['questions', field.name, 'selectedResponses', selectedField.name, 'content'])}
                                          </div>
                                          <div
                                            className={`w-2`}
                                          />
                                          {
                                            form.getFieldValue(['questions', field.name, 'selectedResponses', selectedField.name, 'isCorrect'])
                                            &&
                                            <CheckOutlined className='student-exam-text-success' />
                                          }
                                        </Typography.Text>
                                      </Row>
                                    </>
                                  }
                                  )}
                                </Space>
                              </>
                            }
                          </Form.List>
                          :
                          <Row className={'item-center'}>
                            <Typography.Text className="font-medium">
                              <div>
                                {form.getFieldValue(['questions', field.name, 'studentExamConstructedResponse', 'content'])}
                              </div>
                              <div>
                                <a
                                  target="_blank"
                                  href={form.getFieldValue(['questions', field.name, 'studentExamConstructedResponse', 'attachment'])} rel="noreferrer"
                                >
                                  Attachment file
                                </a>
                              </div>
                            </Typography.Text>
                          </Row>
                      }
                    </Row>
                  </Card>
                </>
              }
              )}
            </>
          }
        </Form.List>
      </Form>
    </Card>
  </>;
};

export default StudentExamDetail;
import React, { useContext, useEffect, useState } from 'react';
import { Button, DatePicker, Form, Input, message, Modal, Table } from 'antd';

import { Link, useNavigate } from 'react-router-dom';
import {
  STUDENTS_ON_CLASS,
  UPDATE_JOIN_TIME_CLASS,
} from '../../../graphql/studentsOnClass';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_COMMENT_TEACHER } from '../../../graphql/class';
import * as moment from 'moment';
import { CommentOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { AuthContext } from '../../../contexts/auth';
import { STUDENT_CLASS_STATUS, UserRoleConstant } from '../../../constants';
import { AddReportOnClassComponent } from './AddReportOnClassComponent';
import { DailyReportForStudentModal } from '../../../components/modals/DailyReportForStudentModal';

const StudentInClass = ({ classId }) => {
  const auth = useContext(AuthContext);
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const navigate = useNavigate();

  const [showModalUpdateStartEnd, setShowModalUpdateStartEnd] = useState(false);
  const [showModalAddComment, setShowModalAddComment] = useState(false);
  const [studentList, setStudentList] = useState([]);
  const [isShowAddDailyReport, setIsShowAddDailyReport] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [initForm, setInitForm] = useState({});
  const [initFormJoinTime, setInitFormJoinTime] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useState({});

  const { data, refetch } = useQuery(STUDENTS_ON_CLASS, {
    variables: {
      classId,
    },
  });

  const [addCommentTeacher] = useMutation(ADD_COMMENT_TEACHER, {
    onCompleted: (response) => {
      message.success('Success');
      setShowModalAddComment(false);
      refetch();
    },
  });

  const [updateJoinTimeClass] = useMutation(UPDATE_JOIN_TIME_CLASS, {
    onCompleted: (response) => {
      message.success('Success');
      setShowModalUpdateStartEnd(false);
    },
  });
  function shouldDisableRow(record) {
    console.log(record);
    return record.status !== STUDENT_CLASS_STATUS.isActive;
  }
  useEffect(() => {
    if (data) {
      setStudentList(data?.students);
    }
  }, [data]);

  const handleUpdate = (item) => {
    setIsShowAddDailyReport(true);
    setUser(item);
  };

  const handleUpdateStartEnd = (item) => {
    setShowModalUpdateStartEnd(true);
    setUser(item);
    setInitFormJoinTime({
      start_time: item.start_time,
      end_time: item.end_time,
    });

    form2.setFieldsValue({
      start_time: moment(item.start_time),
      end_time: moment(item.end_time),
    });
  };

  const handleView = (item) => {
    navigate(`/student/${item.userId}`);
  };

  const columns = [
    {
      title: 'No',
      key: 'index',
      render: (_value, _item, index) => index + 1,
    },
    {
      title: 'Full name',
      dataIndex: 'fullname',
      key: 'fullname',
      render: (_value, item) => (
        <Link to={`/student/${item.userId}`}>{_value}</Link>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Student code',
      dataIndex: 'uid',
      key: 'uid',
    },
    ...(auth?.user?.role === UserRoleConstant.TEACHER
      ? [{}]
      : [
          {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
          },
        ]),
    {
      title: 'Start time',
      dataIndex: 'start_time',
      key: 'start_time',
      render: (start_time) => moment(start_time).format('DD/MM/YYYY'),
    },
    {
      title: 'End time',
      dataIndex: 'end_time',
      key: 'end_time',
      render: (end_time) => moment(end_time).format('DD/MM/YYYY'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => status,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_value, item) => {
        return (
          <>
            <CommentOutlined
              disabled={true}
              // disabled={item.status !== STUDENT_CLASS_STATUS.isActive}
              onClick={() => handleUpdate(item)}
              className="mr10"
            />
            <EyeOutlined
              disabled={item.status !== STUDENT_CLASS_STATUS.isActive}
              onClick={() => handleView(item)}
              className="mr10"
            />
            <EditOutlined
              disabled={item.status !== STUDENT_CLASS_STATUS.isActive}
              onClick={() => handleUpdateStartEnd(item)}
            />
          </>
        );
      },
    },
  ];

  const onFinish = (values) => {
    addCommentTeacher({
      variables: {
        createCommentOfTeacherInput: {
          classId,
          studentId: user.userId,
          subject: values.subject,
          targetSubject: values.targetSubject,
          targetContent: values.targetContent,
          content: values.content,
        },
      },
    });
  };

  const onFinishFailed = (values) => {};

  const onUpdateJoinTime = (values) => {
    updateJoinTimeClass({
      variables: {
        classId: classId,
        studentId: user.userId,
        start_time: values.start_time,
        end_time: values.end_time,
      },
      onCompleted: (res) => {
        refetch();
      },
    });
  };

  const disabledDate = (current) => {
    // if (!current) {
    //   return false;
    // }
    // return current < moment(user.classStartAt).startOf('day') || current > moment(user.classEndAt).endOf('day');
    return false;
  };
  return (
    <div>
      <div className="flex justify-end pb-5">
        <div className="w-40">
          <AddReportOnClassComponent classId={classId} />
        </div>
        <Input.Search
          className="w-56"
          onSearch={(value) => {
            setStudentList(
              data?.students.filter((o) => {
                console.log(o);
                return (
                  o.fullname
                    .toLocaleLowerCase()
                    .includes(value.toLocaleLowerCase()) ||
                  o.email
                    .toLocaleLowerCase()
                    .includes(value.toLocaleLowerCase()) ||
                  o.phone
                    .toLocaleLowerCase()
                    .includes(value.toLocaleLowerCase())
                );
              }),
            );
          }}
        ></Input.Search>
      </div>
      <Table
        dataSource={studentList}
        columns={columns}
        rowClassName={(record, index) => {
          if (shouldDisableRow(record)) return 'disabled-row';
          return '';
        }}
      />
      <DailyReportForStudentModal
        studentId={user.userId}
        setIsShowAddDailyReport={setIsShowAddDailyReport}
        isShowAddDailyReport={isShowAddDailyReport}
      />
      <Modal
        forceRender
        title="Update student join time"
        visible={showModalUpdateStartEnd}
        onOk={() => setShowModalUpdateStartEnd(false)}
        onCancel={() => setShowModalUpdateStartEnd(false)}
        bodyStyle={{
          height: 'auto',
          overflow: 'auto',
        }}
        footer={null}
      >
        <Form
          name="basic"
          form={form2}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 24 }}
          initialValues={initFormJoinTime}
          onFinish={onUpdateJoinTime}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          autoComplete="off"
        >
          <Form.Item
            label="Start time"
            name="start_time"
            type="object"
            rules={[
              {
                required: 'true',
                message: 'Please select time!',
              },
            ]}
          >
            <DatePicker disabledDate={disabledDate} />
          </Form.Item>
          <Form.Item
            label="End time"
            name="end_time"
            rules={[
              {
                required: 'true',
                message: 'Please select time!',
              },
            ]}
          >
            <DatePicker disabledDate={disabledDate} />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 20, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

StudentInClass.propTypes = {};

export default StudentInClass;

import React, { useState } from 'react';

import {
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
  Space,
} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  TableOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';

import { useMutation } from '@apollo/client';
import {
  CREATE_SCORE_BOARD,
  DELETE_SCORE_BOARD,
  STUDENT_BY_ID,
  UPDATE_SCORE_BOARD,
} from '../../graphql/student';
import { useForm } from 'antd/lib/form/Form';
import ScoresViewList from './scoresViewList';
import ScoresViewTable from './scoresViewTable';

const Scores = ({ studentId, scoreboards, classes, setSpinning }) => {
  const [isShowAddScore, setIsShowAddScore] = useState(false);
  const [form] = useForm();
  const [updateItem, setUpdateItem] = useState();
  const [viewType, setViewType] = useState('table');

  const [createScoreBoard] = useMutation(CREATE_SCORE_BOARD, {
    refetchQueries: [{ query: STUDENT_BY_ID, variables: { id: studentId } }],
    onCompleted: (response) => {
      message.success('Success');
      form.setFieldsValue({
        scores: [],
        classId: '',
      });
      setSpinning(false);
    },
  });

  const [deleteScoreBoard] = useMutation(DELETE_SCORE_BOARD, {
    refetchQueries: [{ query: STUDENT_BY_ID, variables: { id: studentId } }],
    onCompleted: (response) => {
      message.success('Success');
      form.setFieldsValue({
        scores: [],
        classId: '',
      });
      setSpinning(false);
    },
  });

  const [updateScoreBoard] = useMutation(UPDATE_SCORE_BOARD, {
    refetchQueries: [{ query: STUDENT_BY_ID, variables: { id: studentId } }],
    onCompleted: (response) => {
      message.success('Success');
      setSpinning(false);
      form.setFieldsValue({
        scores: [],
        classId: '',
      });
    },
  });

  const onFinishAddScores = (values) => {
    setSpinning(true);
    setIsShowAddScore(false);
    if (updateItem) {
      updateScoreBoard({
        variables: {
          updateScoreBoardInput: {
            scoreBoardId: updateItem.scoreBoardId,
            scores: values.scores,
            classId: values.classId,
          },
        },
      });
      setUpdateItem();
      return;
    }
    createScoreBoard({
      variables: {
        createScoreBoardInput: {
          scores: values.scores,
          userId: studentId,
          classId: values.classId,
        },
      },
    });
  };

  const handleUpdate = (scoreboard) => {
    form.setFieldsValue({
      ...scoreboard,
    });
    setUpdateItem(scoreboard);
    setIsShowAddScore(true);
  };

  const handleRemove = (scoreboard) => {
    Modal.confirm({
      title: 'Delete student?',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure delete this item?',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: () => {
        setSpinning(true);
        deleteScoreBoard({
          variables: {
            scoreBoardId: scoreboard.scoreBoardId,
          },
        });
      },
    });
  };

  const handleViewType = (type) => {
    setViewType(type);
  };
  const handleAddScore = () => {
    form.setFieldsValue({
      classId: classes?.[0]?.classId,
    });
    setIsShowAddScore(true);
    setUpdateItem();
  };
  return (
    <div>
      <div className="score-container">
        <h2>Scores</h2>
        <div className="score-btn-end">
          <Button type="primary" onClick={() => handleAddScore()}>
            Add Scores
          </Button>
          <div className="mt-10">
            <span className="mr10">View:</span>
            <UnorderedListOutlined
              className="mr10 pointer"
              onClick={() => handleViewType('list')}
            />
            <TableOutlined
              className="pointer"
              onClick={() => handleViewType('table')}
            />
          </div>
        </div>
        <Modal
          title={updateItem ? 'Update' : 'Add new'}
          visible={isShowAddScore}
          onOk={() => {
            form.submit();
          }}
          onCancel={() => {
            form.setFieldsValue({
              scores: [],
              classId: '',
            });
            setIsShowAddScore(false);
          }}
        >
          <Form
            form={form}
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 24 }}
            layout={'vertical'}
            onFinish={onFinishAddScores}
            autoComplete="off"
          >
            <Form.Item
              name="classId"
              label="Class:"
              rules={[{ required: true, message: 'Missing Lesson class' }]}
            >
              <Select>
                {classes?.map((classInfo) => (
                  <Select.Option
                    value={classInfo.classId}
                    key={classInfo.classId}
                  >
                    {classInfo.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.List name="scores">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space
                      key={key}
                      style={{ display: 'flex', marginBottom: 8 }}
                      align="baseline"
                    >
                      <Form.Item
                        {...restField}
                        name={[name, 'subject']}
                        rules={[{ required: true, message: 'Missing Subject' }]}
                      >
                        <Input placeholder="Subject" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'score']}
                        rules={[{ required: true, message: 'Missing Score' }]}
                      >
                        <InputNumber placeholder="Score" />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add field
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form>
        </Modal>
      </div>
      {viewType === 'list' && (
        <ScoresViewList
          scoreboards={scoreboards}
          classes={classes}
          handleUpdate={handleUpdate}
          handleRemove={handleRemove}
        />
      )}
      {viewType === 'table' && (
        <ScoresViewTable
          scoreboards={scoreboards}
          classes={classes}
          handleUpdate={handleUpdate}
          handleRemove={handleRemove}
        />
      )}
    </div>
  );
};

Scores.propTypes = {};

export default Scores;

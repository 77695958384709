import { useQuery } from '@apollo/client';
import * as moment from 'moment';
import { Table } from 'antd';
import React from 'react';
import { PROGRAM } from '../../../graphql/programs';

const ProgramDetailComponent = ({ programId }) => {
  // TODO:

  const { data } = useQuery(PROGRAM, {
    variables: {
      programId,
    },
  });
  const students = data?.students?.map((item) => ({
    ...item,
    key: item.userId,
  }));

  const columns = [
    {
      title: 'No',
      key: 'index',
      render: (_value, _item, index) => index + 1,
    },
    {
      title: 'Full name',
      dataIndex: 'fullname',
      key: 'fullname',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at) => moment(created_at).format('DD/MM/YYYY hh:mm:ss'),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_value, item) => {
        return (
          <>
            TODO: nhan xet
            {/* <EditOutlined onClick={() => handleUpdateUser(item)} className='mr10'/>
          <DeleteOutlined onClick={() => hanleDeleteUser(item.userId)}/> */}
          </>
        );
      },
    },
  ];

  return (
    <div>
      <Table dataSource={students || []} columns={columns} />
    </div>
  );
};
export default ProgramDetailComponent;

import React, { useContext, useEffect, useRef, useState } from 'react';

import * as moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { Avatar, Button, Form, Input, Modal, Table } from 'antd';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, UserOutlined } from '@ant-design/icons';
import { CREATE_RESOURCE } from '../../graphql/resourceMutations';
import { CREATE_ADMIN_AGENCY, LIST_ADMIN_AGENCIES, REMOVE_ADMIN_AGENCY, UPDATE_ADMIN_AGENCY } from '../../graphql/users';
import { useMutation, useQuery } from '@apollo/client';
import { AuthContext } from '../../contexts/auth';
import { UserRoleConstant } from '../../constants';


const AdminAgency = () => {
  const { user } = useContext(AuthContext);
  const [initForm, setInitForm] = useState({});
  const [form] = Form.useForm();
  const [isShowModalAddNew, setIsShowModalAddNew] = useState(false);
  const [fileAvatar, setFileAvatar] = useState();
  const [resetForm, setResetForm] = useState(0);
  const [pageLoading, setPageLoading] = useState(false);
  const avatarRef = useRef();
  const { agencyId } = useParams();

  const [createResource] = useMutation(CREATE_RESOURCE);
  const [createAgencyAdmin] = useMutation(CREATE_ADMIN_AGENCY, {
    refetchQueries: [{ query: LIST_ADMIN_AGENCIES, variables: { agencyId } }],
    onCompleted: (response) => {
      if (response && response.createAgencyAdmin) {
        setPageLoading(false);
        Modal.success({ title: 'Create success! ' });
        setInitForm({});
      }
    },
    onError: (_createError) => {
      setPageLoading(false)
      Modal.error({ content: 'Create agency admin fail' });
    }
  });
  const [updateAgencyAdmin] = useMutation(UPDATE_ADMIN_AGENCY, {
    refetchQueries: [{ query: LIST_ADMIN_AGENCIES, variables: { agencyId } }],
    onCompleted: (response) => {
      if (response) {
        setPageLoading(false);
        Modal.success({ title: 'Update agency admin success! ' });
        setInitForm({});
      }
    },
    onError: () => {
      setPageLoading(false)
      Modal.error({ content: 'Update agency admin fail' });
    }
  });
  const [removeAgencyAdmin] = useMutation(REMOVE_ADMIN_AGENCY, {
    refetchQueries: [{ query: LIST_ADMIN_AGENCIES, variables: { agencyId } }],
    onCompleted: (response) => {
      if (response) {
        setPageLoading(false);
        Modal.success({ title: 'Remove agency admin success! ' });
        setInitForm({});
      }
    },
    onError: () => {
      setPageLoading(false)
      Modal.error({ title: 'Lỗi', content: 'Remove agency admin fail' });
    }
  });

  const { data } = useQuery(LIST_ADMIN_AGENCIES, { variables: { agencyId } })

  useEffect(() => {
    form.setFieldsValue({
      fullname: initForm.fullname,
      email: initForm.email,
      phone: initForm.phone,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initForm, resetForm]);

  const columns = [
    {
      title: 'No',
      key: 'index',
      render: (value, item, index) => index + 1
    },
    {
      title: 'Full name',
      dataIndex: 'fullname',
      key: 'fullname',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (createdAt) => moment(createdAt).format('DD/MM/YYYY hh:mm:ss'),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (value, item) => {
        return <>
          <EditOutlined onClick={() => handleUpdate(item)} className='mr10' />
          {
            user?.role === UserRoleConstant.ADMIN &&
            <DeleteOutlined onClick={() => handleDelete(item.userId)} />
          }
        </>;
      },
    },
  ];

  const handleAddNew = () => {
    setInitForm({});
    setIsShowModalAddNew(true);
  };


  const handleUpdate = (agencyAdmin) => {
    setInitForm(agencyAdmin);
    setFileAvatar('');
    setIsShowModalAddNew(true);
  }
  const handleDelete = (userId) => {
    Modal.confirm({
      title: 'Delete agency admin?',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure delete this agency admin?',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: () => {
        removeAgencyAdmin({
          variables: {
            userId
          }
        })
      }
    });
  };
  const handleSaveUser = () => {
    setIsShowModalAddNew(false);
  };

  const renderAvatar = () => {
    if (fileAvatar) return URL.createObjectURL(fileAvatar);
    if (initForm.avatar) return initForm.avatar;
    return '';
  };

  const closeModal = () => {
    setIsShowModalAddNew(false);
    setResetForm(Date.now())
  };


  const onFinish = async (values) => {
    // Upload file
    let userAvatarUrl = initForm.avatar;
    if (fileAvatar) {
      const createResourceDt = await createResource({
        variables: {
          file: fileAvatar
        }
      });
      userAvatarUrl = createResourceDt?.data?.createResource?.url;
    }
    userAvatarUrl = userAvatarUrl ?? '';
    setPageLoading(true);
    setIsShowModalAddNew(false);
    if (initForm.userId) {
      updateAgencyAdmin({
        variables: {
          updateAgencyAdminInput: {
            ...values,
            avatar: userAvatarUrl,
            userId: initForm.userId,
            agencyId,
          }
        }
      });
    } else {
      createAgencyAdmin({
        variables: {
          createAgencyAdminInput: {
            ...values,
            avatar: userAvatarUrl,
            agencyId,
          },
        }
      });
    }
  };

  const onFinishFailed = () => {
    return;
  };

  const changeAvatar = () => {
    avatarRef.current.click();
  };

  const handleChangeImage = event => {
    if (event.target.files && event.target.files[0]) {
      setFileAvatar(event.target.files[0]);
    }
  };


  return (
    <div>
      <div className='flex justify-end'>
        <Button className='' onClick={handleAddNew}>Add new admin</Button>
      </div>
      <div className='mt-6'>
        <Table dataSource={data?.findAllAgencyAdmin || []} columns={columns} />
      </div>
      <Modal
        forceRender
        title={initForm.userId ? 'Update' : 'Add new'}
        visible={isShowModalAddNew}
        onOk={handleSaveUser}
        onCancel={closeModal}
        bodyStyle={{
          height: 500,
          overflow: 'auto'
        }}
        footer={null}
      >
        <div className='avatar_form'>
          <div className='avatar_form__item'>
            <Avatar
              src={renderAvatar()} icon={<UserOutlined />} size={100} />
            <div className='avatar_form__button'>
              <Button onClick={changeAvatar}>Change Avatar</Button>
              <input type="file"
                ref={avatarRef}
                id="cover"
                name="cover"
                accept="image/png, image/jpeg"
                style={{ display: 'none' }}
                onChange={handleChangeImage}></input>
            </div>
          </div>
        </div>
        <Form
          name="basic"
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 24 }}
          initialValues={initForm}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout='vertical'
          autoComplete="off"
        >
          <Form.Item
            label="Full name"
            name="fullname"
            rules={[{ required: true, message: 'Please input full name!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Please input email!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Phone"
            name="phone"
            rules={[{ required: true, message: 'Please input phone!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 20, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

AdminAgency.propTypes = {};

export default AdminAgency;

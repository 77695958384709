import { UserOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Avatar, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DailyReport from '../components/dailyReport';
import Scores from '../components/scores/scores';
import { STUDENT_BY_ID } from '../graphql/student';
import { Tabs } from 'antd';
import Comment from '../components/comment';
import StudentExam from './class/detail/exam/StudentExam';
import { AuthContext } from '../contexts/auth';
import { UserRoleConstant } from '../constants';

const StudentDetail = () => {
  const { user } = useContext(AuthContext);
  const [getStudent, { data: studentQuery, loading }] =
    useLazyQuery(STUDENT_BY_ID);
  const { studentId } = useParams();
  const [student, setStudent] = useState();
  const [spinning, setSpinning] = useState(false);
  const [classes, setClasses] = useState();

  useEffect(() => {
    if (studentId) {
      getStudent({
        variables: {
          id: studentId,
        },
      });
    }
  }, [getStudent, studentId]);

  useEffect(() => {
    if (studentQuery?.students) {
      setStudent(studentQuery.students);
    }
    if (studentQuery?.classes) {
      setClasses(studentQuery?.classes);
    }
  }, [studentQuery]);

  useEffect(() => {
    console.log('student ', student);
  }, [student]);

  return (
    <Spin spinning={spinning || loading}>
      <div className="profile">
        <div className="font-bold text-lg">{student?.fullname}</div>
        <div className="center_horizontal vertical content">
          <Tabs defaultActiveKey="1" style={{ marginLeft: 10 }}>
            <Tabs.TabPane tab="DailyReport" key="1">
              <DailyReport
                studentId={studentId}
                dailyReports={student?.dailyReports || []}
                classes={classes}
                setSpinning={setSpinning}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Comment" key="2">
              <Comment
                studentId={studentId}
                classes={classes}
                setSpinning={setSpinning}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Scores" key="3">
              <Scores
                studentId={studentId}
                scoreboards={(student?.scoreboards || []).filter((scoreboard) =>
                  (classes ?? []).some(
                    (classData) => classData?.classId === scoreboard?.classId,
                  ),
                )}
                classes={classes}
                setSpinning={setSpinning}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Exam" key="4">
              <StudentExam studentId={studentId} setSpinning={setSpinning} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Profile" key="5">
              <div className="change_avatar">
                {student?.avatar ? (
                  <Avatar
                    size={100}
                    className={'avatar'}
                    src={student?.avatar}
                  />
                ) : (
                  <Avatar
                    size={100}
                    className={'avatar'}
                    icon={<UserOutlined />}
                  />
                )}
              </div>
              <div className="info_line ml-20">
                <div className="title">Full name:</div>
                <div className="value">{student?.fullname}</div>
              </div>
              <div className="info_line ml-20">
                <div className="title">Email:</div>
                <div className="value">{student?.email}</div>
              </div>
              {user?.role !== UserRoleConstant.TEACHER && (
                <div className="info_line ml-20">
                  <div className="title">Phone:</div>
                  <div className="value">{student?.phone}</div>
                </div>
              )}
            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>
    </Spin>
  );
};

StudentDetail.propTypes = {};

export default StudentDetail;

import React, { useEffect, useState } from 'react';

import * as moment from 'moment';
import { Breadcrumb, Table, Button, Modal, Spin, Form, Input, Tabs } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import AdminAgency from './admin-agency';


const AgencyDetail = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();


  return (
    <Spin tip="Loading..." spinning={false}>
      <div className='post'>
        <div className='breadcrumb'>
          <Breadcrumb>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item 
              style={{ cursor: 'pointer' }}
              onClick={() => { navigate('/agencies') }}
            >
              Agency
            </Breadcrumb.Item>
            <Breadcrumb.Item>AgencyDetail</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className='body'>
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Admin" key="1">
            <AdminAgency />
          </Tabs.TabPane>
        </Tabs>
        </div>
      </div>
    </Spin>
  );
};

AgencyDetail.propTypes = {};

export default AgencyDetail;

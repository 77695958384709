export const Paging = {
  take: 'take',
  skip: 'skip',
  search: 'search',
};

export const PagingType = {
  class: 'class',
  student: 'student',
};

import React, { useContext } from 'react';

import * as moment from 'moment';
import { Breadcrumb, Table, Button, Modal, Spin } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import { NEWS, REMOVE_NEWS, UPDATE_NEW } from '../../graphql/news';
import { AuthContext } from '../../contexts/auth';
import { UserRoleConstant } from '../../constants';

const News = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  const [removeNews] = useMutation(REMOVE_NEWS, {
    refetchQueries: [{ query: NEWS, variables: { filter: {} } }],
    onCompleted: (response) => {
      if (response) {
        Modal.success({ title: 'Remove news success! ' });
      }
    },
    onError: () => {
      Modal.error({ message: 'Remove news fail' });
    }
  });

  const [updateNews] = useMutation(UPDATE_NEW, {
    refetchQueries: [{ query: NEWS, variables: { filter: {} } }],
    onCompleted: (response) => {
      if (response && response.updateNews) {
        Modal.success({ title: 'Update success! ' });
      }
    },
    onError: (_createError) => {
      Modal.error({ message: 'Update fail!' });
    }
  });

  const { loading, error, data } = useQuery(NEWS, { variables: { filter: {} } });

  const handleAddNew = () => {
    navigate('/news/create');
  };

  const handleUpdate = (_id) => {
    navigate(`/news/${_id}/update`);
  }

  const handleUpdateShowHide = (item) => {
    let status = 'ACTIVE';
    if (item.status === 'ACTIVE') {
      status = 'INACTIVE';
    }
    updateNews({
      variables: {
        updateNewsInput: {
          newsId: item.newsId,
          status,
        }
      }
    })
  }

  const hanleDelete = (_id) => {
    Modal.confirm({
      title: 'Delete news?',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure delete this news?',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: () => {
        removeNews({
          variables: {
            newsId: _id
          }
        })
      }
    });
  }

  if (error) {
    Modal.error({ message: 'Get news error' });
  }

  const columns = [
    {
      title: 'No',
      key: 'index',
      render: (value, item, index) => index + 1
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category'
    },
    {
      title: 'Short description',
      dataIndex: 'shortDescription',
      key: 'shortDescription'
    },
    {
      title: 'Created by',
      dataIndex: 'createdBy',
      key: 'createdBy',
      render: (obj) => obj.fullname
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (createdAt) => moment(createdAt).format('DD/MM/YYYY hh:mm:ss'),
    },
    ...(user?.role === UserRoleConstant.ADMIN ? [{
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (value, item) => {
        return <>
          {
            item?.status?.toLowerCase() === 'active'
              ?
              <EyeOutlined onClick={() => handleUpdateShowHide(item)} className='mr10' />
              :
              <EyeInvisibleOutlined onClick={() => handleUpdateShowHide(item)} className='mr10' />
          }
          <EditOutlined onClick={() => handleUpdate(item.newsId)} className='mr10' />
          <DeleteOutlined onClick={() => hanleDelete(item.newsId)} />
        </>;
      },
    }] : []),
  ];

  return (
    <Spin tip="Loading..." spinning={loading}>
      <div className='post'>
        <div className='breadcrumb'>
          <Breadcrumb>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>News</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className='body'>
          <div className='add-new'>
            {
              user?.role === UserRoleConstant.ADMIN &&
              <Button type="primary" icon={<PlusOutlined />} onClick={handleAddNew}>Add New</Button>
            }
          </div>
          <div className='table'>
            <Table dataSource={data?.news} columns={columns} />
          </div>
        </div>
      </div>
    </Spin>
  );
};

News.propTypes = {};

export default News;

import gql from 'graphql-tag';

export const CREATE_STUDY_PROGRAMS = gql`
	mutation createStudyProgram($createStudyProgramInput: CreateStudyProgramInput!) {
		createStudyProgram(createStudyProgramInput: $createStudyProgramInput)
	}
`;

export const FIND_ALL_STUDY_PROGRAMS = gql`
	query findAllStudyPrograms {
		studyPrograms: findAllStudyPrograms {
			studyProgramId
			name
			description
			programs
		}
	}
`;

export const DELETE_STUDY_PROGRAMS = gql`
	mutation deleteStudyProgram($id: String!) {
		deleteStudyProgram(id: $id)
	}
`;

export const UPDATE_STUDY_PROGRAMS = gql`
	mutation updateStudyProgram($updateStudyProgramInput: UpdateStudyProgramInput!) {
		updateStudyProgram(updateStudyProgramInput: $updateStudyProgramInput)
	}
`;

export const FIND_ONE_STUDY_PROGRAM = gql`
	query findOneStudyProgram($id: String!) {
		studyProgram: findOneStudyProgram(id: $id) {
			studyProgramId
			name
			description
			programs
		}
	}
`;